import { Pipe, PipeTransform } from "@angular/core";
import * as dayjs from "dayjs";

@Pipe({
  name: "datesToDaynames",
})
export class DatesToDaynamesPipe implements PipeTransform {
  transform(date: Date | undefined, ...args: (Date | undefined)[]): string {
    if (!date) return "";
    const dayjs1 = dayjs(date);
    const dayjs2 = args.length ? dayjs(args[0]) : null;
    if (!dayjs1 || !dayjs1.isValid()) return "Invalid Date";
    if (!dayjs2 || !dayjs2.isValid() || dayjs1.isSame(dayjs2, "day")) {
      return `${dayjs1.format("dddd")}`;
    }
    const date1 = dayjs1.format("dddd"); // TODO full day name
    const date2 = dayjs2.format("dddd"); // TODO full day name
    return `${date1} - ${date2}`;
  }
}
