import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { stdnum } from "stdnum";

import isTaxID from "validator/lib/isTaxID";
import isIBAN from "validator/lib/isIBAN";
import isBIC from "validator/lib/isBIC";
// import isPassportNumber from "validator/lib/isPassportNumber";
// import * as ibantools from 'ibantools'

// import {phone} from 'phone';

// import * as avris from 'avris-generator';

// export function verifySocialSecurityNumber(number: string, country: string) {}

// Validate Insurance-Number-Function

export function validateAHV(number: string) {
  const regex = /[7][5][6][.][\d]{4}[.][\d]{4}[.][\d]{2}$/;
  let isValid = false;

  // Last digit of the entered number
  const checknumber = parseInt(number[number.length - 1], 10);

  // Validate the general setup of the insurance-number using the regex defined above
  isValid = regex.test(number);
  if (isValid) {
    // Remove last character (not needed to calculate checksum)
    let tmp_number: string = number.substr(0, number.length - 1);

    // Remove dots from number and reverse it
    // (if you want to know why, look at the rules in the link given in the html-comment)
    tmp_number = tmp_number.split(".").join("").split("").reverse().join("");
    let sum = 0;
    for (let i = 0; i < tmp_number.length; i++) {
      const add =
        i % 2 == 0
          ? parseInt(tmp_number[i], 10) * 3
          : parseInt(tmp_number[i], 10) * 1;
      sum += add;
    }

    // Calculate correct checksum (again, see the documentation to undestand why)
    const checksum = Math.ceil(sum / 10) * 10 - sum;
    if (checksum !== checknumber) {
      isValid = false;
    }
  }

  return isValid;
}

export function isValidSSN(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const country = control.parent?.value.wohnland;
    const { value } = control;
    if (!value) {
      return null;
    }
    let isValid1 = true;
    const country_key: { [key: string]: string } = {
      CH: "ssn",
      DE: "svnr",
      AT: "vnr",
    };
    console.log(`stdnum`, stdnum);
    if (["DE", "AT"].includes(country)) {
      const { isValid } = stdnum[country][country_key[country]].validate(value);
      isValid1 = isValid;
    }
    if (country == "CH") {
      isValid1 = validateAHV(value);
    }
    return isValid1 ? null : { invalid: true };
  };
}

// export function isValidPassportNumber(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     const { value } = control;

//     if (!value) {
//       return null;
//     }

//     console.log(
//       `control.parent?.value['staatsangehoerigkeit']`,
//       control.parent?.value.staatsangehoerigkeit
//     );

//     const valid_countries = [
//       "AM",
//       "AR",
//       "AT",
//       "AU",
//       "BE",
//       "BG",
//       "BY",
//       "BR",
//       "CA",
//       "CH",
//       "CN",
//       "CY",
//       "CZ",
//       "DE",
//       "DK",
//       "DZ",
//       "EE",
//       "ES",
//       "FI",
//       "FR",
//       "GB",
//       "GR",
//       "HR",
//       "HU",
//       "IE",
//       "IN",
//       "IR",
//       "ID",
//       "IS",
//       "IT",
//       "JP",
//       "KR",
//       "LT",
//       "LU",
//       "LV",
//       "LY",
//       "MT",
//       "MX",
//       "MY",
//       "MZ",
//       "NL",
//       "PL",
//       "PT",
//       "RO",
//       "RU",
//       "SE",
//       "SL",
//       "SK",
//       "TH",
//       "TR",
//       "UA",
//       "US",
//     ];

//     if (
//       !control.parent?.value.staatsangehoerigkeit ||
//       !valid_countries.includes(control.parent?.value.staatsangehoerigkeit)
//     ) {
//       return null;
//     }

//     const isPassportNumberResult = isPassportNumber(
//       value,
//       control.parent?.value.staatsangehoerigkeit
//     );

//     console.log(`isPassportNumberResult`, isPassportNumberResult);

//     // https://github.com/validatorjs/validator.js
//     return !isPassportNumberResult ? { invalid: true } : null;
//   };
// }

export function isValidTIN(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;

    if (!value) {
      return null;
    }

    console.log(
      `control.parent?.value['wohnland']`,
      control.parent?.value.wohnland
    );

    if (control.parent?.value.wohnland == "CH") {
      const validateAHVResult = validateAHV(value);
      console.log(`validateAHVResult`, validateAHVResult);
      return validateAHVResult ? null : { invalid: true };
    }

    const mappings: any = {
      BG: "bg-BG",
      CZ: "cs-CZ",
      AT: "de-AT",
      DE: "de-DE",
      DK: "dk-DK",
      CY: "el-CY",
      GR: "el-GR",
      IE: "en-IE",
      US: "en-US",
      ES: "es-ES",
      EE: "et-EE",
      FI: "fi-FI",
      BE: "fr-BE",
      FR: "fr-FR",
      LU: "fr-LU",
      HR: "hr-HR",
      HU: "hu-HU",
      IT: "it-IT",
      LV: "lv-LV",
      MT: "mt-MT",
      NL: "nl-NL",
      PL: "pl-PL",
      BR: "pt-BR",
      PT: "pt-PT",
      RO: "ro-RO",
      SK: "sk-SK",
      SI: "sl-SI",
      SE: "sv-SE",
    };
    if (
      !control.parent?.value.wohnland ||
      !Object.keys(mappings).includes(control.parent?.value.wohnland)
    ) {
      return null;
    }

    const isTaxIdResult = isTaxID(
      value,
      mappings[control.parent?.value.wohnland]
    );

    console.log(`isTaxIdResult`, isTaxIdResult);

    // https://github.com/validatorjs/validator.js
    return !isTaxIdResult ? { invalid: true } : null;
  };
}

export function isValidIBAN(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    if (!value) {
      return null;
    }
    if (isIBAN(value)) {
      return null;
    }
    return { invalid: true };
  };
}

export function isValidBIC(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    if (!value) {
      return null;
    }
    if (isBIC(value)) {
      return null;
    }
    return { invalid: true };
  };
}

export function atLeastOneValidator(): ValidatorFn {
  return (controlGroup: any): ValidationErrors | null => {
    const { controls } = controlGroup as FormGroup;
    if (controls) {
      const theOne = Object.keys(controls).find(
        (key) => controls[key].value !== "" && controls[key].value
      );
      if (!theOne) {
        return {
          atLeastOneRequired: {
            text: "At least one should be selected",
          },
        };
      }
    }
    return null;
  };
}

export function sameYearValidator(): ValidatorFn {
  return (controlGroup: any): ValidationErrors | null => {
    const { controls } = controlGroup as FormGroup;
    if (controls) {
      // console.log(`controls`, controls);
      if (controls.zeitraum_von.value && controls.zeitraum_bis.value) {
        if (
          controls.zeitraum_von.value.split("-")[0] !=
          controls.zeitraum_bis.value.split("-")[0]
        ) {
          // console.log("not same year");
          return {
            sameYearRequired: {
              text: "Zeitraum von und bis müssen im selben Jahr liegen",
            },
          };
        }
      } else {
        return null;
      }
    }
    return null;
  };
}
