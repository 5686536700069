import { Component, EventEmitter, forwardRef, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-time-picker",
  templateUrl: "./timepicker.component.html",
  styleUrls: ["./timepicker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
  ],
})
export class TimepickerComponent implements ControlValueAccessor {
  @Output() timeSelected = new EventEmitter<string>();

  selectedHour?: string = "00";

  selectedMinute?: string = "00";

  hours = Array.from({ length: 24 }, (_, i) => i).map((hour) => {
    return this.padZero(hour);
  });

  minutes = Array.from({ length: 60 }, (_, i) => i)
    .filter((minute) => minute % 15 === 0)
    .map((minute) => {
      return this.padZero(minute);
    });

  private onChange: (time: string) => void = () => {};

  private onTouch: () => void = () => {};

  private disabled: boolean | undefined;

  writeValue(time: string): void {
    const parts = time?.split(":");
    if (parts && parts.length === 2) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedHour = parts[0];
      // eslint-disable-next-line prefer-destructuring
      this.selectedMinute = parts[1];
    } else {
      this.selectedHour = undefined;
      this.selectedMinute = undefined;
    }
  }

  registerOnChange(fn: (time: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onHourChange(): void {
    this.emitTime();
  }

  onMinuteChange(): void {
    this.emitTime();
  }

  emitTime(): void {
    if (!this.disabled) {
      if (!this.selectedHour) this.selectedHour = "00";
      if (!this.selectedMinute) this.selectedMinute = "00";
      const formattedHour = this.selectedHour;
      const formattedMinute = this.selectedMinute;
      const selectedTime = `${formattedHour}:${formattedMinute}`;
      this.onChange(selectedTime);
      this.onTouch();
      this.timeSelected.emit(selectedTime);
    }
  }

  private padZero(value: number): string {
    return value?.toString().padStart(2, "0");
  }
}
