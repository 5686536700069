import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { EmploymentContract, User } from "@ttc_types/types";
import { Notify } from "notiflix";
import { SignaturePad } from "angular2-signaturepad";
import { DataService } from "../../../../data.service";
import { AuthService } from "../../../../shared/auth.service";

@Component({
  selector: "app-vertrag",
  templateUrl: "./vertrag.component.html",
  styleUrls: ["./vertrag.component.scss"],
})
export class VertragComponent implements OnInit {
  constructor(private data: DataService, private auth: AuthService) {}

  employmentContract: EmploymentContract | undefined;

  showSignatureModal: boolean = false;

  canSignContract: boolean = false;

  // documents and signature
  signatureBase64: string = "";

  signaturePadOpts: Object | null = null;

  @ViewChild(SignaturePad) signaturePad!: SignaturePad;

  @Input()
  user!: User;

  @Output()
  refreshList: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Output()
  gegengezeichnet: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Output()
  kuendigen = new EventEmitter();

  async ngOnInit() {
    this.canSignContract = false;
    if (
      this.user &&
      this.user.applications &&
      this.user.applications[0] &&
      this.user.applications[0].id
    ) {
      this.data
        .getEmploymenContract(this.user.applications[0].id)
        .then((contract) => {
          this.employmentContract = contract;
        });
    }
    // TODO check all applicants
    if (this.data.currentProject && this.data.currentProject.id) {
      const tmpApplicants = await this.data.getApplicants(
        this.data.currentProject.id
      );

      const tmpAdminIds = tmpApplicants
        .filter((appl) => appl.applications![0].is_admin)
        .map((appl) => appl.id!);
      console.log("tmpAdminIds: ", tmpAdminIds);
      const currentUser = this.auth.currentUserFnc;
      if (
        currentUser &&
        ((currentUser.role && currentUser.role === "superadmin") ||
          (currentUser.id && tmpAdminIds.includes(currentUser.id)))
      ) {
        this.canSignContract = true;
      }
      console.log(`tmpApplicants`, tmpApplicants);
      console.log(`tmpAdminIds`, tmpAdminIds);
    }
    this.initSignaturePad();
  }

  async signContractWithSignature() {
    console.log("signContractWithSignature");
    if (
      this.employmentContract &&
      this.employmentContract.id // &&
      // this.signatureBase64
    ) {
      const employmentContractUpdateObject: Partial<EmploymentContract> = {
        application_id: this.employmentContract.application_id,
        time_employer_signature: new Date(),
        // employer_signature: this.signatureBase64,
      };
      this.employmentContract = await this.data.updateEmploymentContract(
        this.employmentContract.id,
        employmentContractUpdateObject
      );
      this.showSignatureModal = false;
      this.refreshList.emit();
      this.gegengezeichnet.emit();
    } else {
      Notify.failure("Es muss eine Unterschrift angegeben werden.");
    }
  }

  async signContractByAdminClicked() {
    console.log("signContractByAdminClicked");
    this.showSignatureModal = true;
  }

  initSignaturePad() {
    // init signaturepad
    const minWidth = 290;
    const maxWidth = 325;
    const heightRatio = 0.4138;
    const incomingWidth = Math.min(window.screen.width - 30, maxWidth);
    const width = Math.max(minWidth, incomingWidth);
    const height = Math.round(width * heightRatio);
    this.signaturePadOpts = {
      minWidth: 1,
      canvasWidth: width,
      canvasHeight: height,
      backgroundColor: "#fff",
      penColor: "#4287f5",
    };
  }

  signatureDrawStart() {
    console.log("signatureDrawStart");
  }

  signatureDrawFinish() {
    console.log("signatureDrawFinish");
    this.signatureBase64 = this.signaturePad.toDataURL();
  }

  signatureClear() {
    this.signaturePad.clear();
    this.signatureBase64 = "";
  }

  async getContractPDFClicked() {
    console.log("getContractPDFClicked");
    if (this.employmentContract && this.employmentContract.id) {
      console.log("this.employmentContract: ", this.employmentContract);
      const result = await this.data.getPDFOfEmploymentContract(
        this.employmentContract.id
      );
      console.log("getPDFTest answer");
      const formattedName = `${this.user.profile?.vorname || ""}_${
        this.user.profile?.nachname || ""
      }`;
      const tmpDate = new Date();
      let formattedDate = tmpDate.toISOString();
      formattedDate = formattedDate.substring(0, formattedDate.length - 5);
      const formattedFilename = `${formattedDate}_arbeitsvertrag_${formattedName}`;
      this.downloadPdf(result.pdf64, formattedFilename);
    }
  }

  async getDismissalPDFClicked() {
    console.log("getDismissalPDF");
    if (this.employmentContract && this.employmentContract.id) {
      console.log("this.employmentContract: ", this.employmentContract);
      const result = await this.data.getRequest(
        `/application/generateDismissalPDF/${this.employmentContract.id}`
      );
      console.log("getPDFTest answer", result);
      const tmpDate = new Date();
      let formattedDate = tmpDate.toISOString();
      formattedDate = formattedDate.substring(0, formattedDate.length - 5);
      const formattedFilename = `${formattedDate}_kuendigung`;
      this.downloadPdf(result.pdf64, formattedFilename);
    }
  }

  public async downloadPdf(base64String: string, fileName: string) {
    const link = document.createElement("a");
    const blob = await this.b64toBlob(base64String);
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.pdf`;
    link.target = "blank";
    link.click();
  }

  private async b64toBlob(base64: string, type = "application/octet-stream") {
    const res = await fetch(`data:${type};base64,${base64}`);
    return res.blob();
  }
}
