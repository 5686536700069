import { Component, HostBinding, Input } from "@angular/core";
import { SvgsTypes, TtcColors } from "@ttc_types/types";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input()
  btnText: string = "";

  @Input()
  color: TtcColors | "secondary-gradient" | "success-full" | "fail-full" =
    "secondary-gradient";

  // for all but for secondary-gradient
  @Input()
  hoverColor: TtcColors = "secondary";

  // only for secondary-gradient
  @Input()
  background: string = "primary";

  @Input()
  iconRight: SvgsTypes | undefined = undefined;

  @Input()
  lessHeight: boolean = false;

  @HostBinding("class.pointer-events-none")
  @Input()
  disabled: boolean = false;

  @Input()
  type: "submit" | "button" = "button";

  @Input()
  isLoading: boolean = false;

  @Input()
  format: "block" | "normal" = "block";

  @Input()
  fullWidthOnMobile: boolean = false;

  @Input()
  onlyIcon: boolean = false;

  @Input()
  smallerIcon: boolean = false;

  classes: string[] = [];

  ngOnInit() {
    if (this.color == "secondary" && this.hoverColor == "secondary") {
      this.hoverColor = "primary80";
    }
    const classes = [];

    classes.push(this.color);
    classes.push(this.format);
    classes.push(`${this.background}-bg`);
    classes.push(`${this.hoverColor}-hover`);
    if (this.smallerIcon) classes.push("smaller-icon");
    if (this.lessHeight) classes.push("less-height");
    if (this.fullWidthOnMobile) classes.push("full-width-on-mobile");
    if (this.onlyIcon) classes.push("only-icon");
    this.classes = classes;
  }
}
