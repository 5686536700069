import { Notify, Confirm, IConfirmOptions } from "notiflix";
import { Validators, FormControl, FormGroup } from "@angular/forms";
/* eslint-disable no-param-reassign */
import {
  trigger,
  transition,
  query,
  style,
  animate,
  group,
} from "@angular/animations";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

import {
  // disableBodyScroll,
  clearAllBodyScrollLocks,
  disableBodyScroll,
} from "body-scroll-lock";

import * as dayjs from "dayjs";
import { DataService } from "src/app/data.service";
import { ActivatedRoute } from "@angular/router";
import { User } from "@ttc_types/types";
import { statusLabelRequired } from "@ttc_types/datasets";
// import { sortByStatusAndThenByName } from "@ttc_types/functions";
import { firstValueFrom, pairwise, startWith, Subscription } from "rxjs";
import { sortByStatusAndThenByName } from "src/app/shared/functions";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

const left = [
  // query(":enter, :leave", style({ width: "100%" }), {
  //   optional: true,
  // }),
  group([
    query(
      ":enter",
      [
        style({ transform: "translateX(-210vw)" }),
        animate(".3s ease-out", style({ transform: "translateX(0%)" })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ":leave",
      [
        // style({ transform: "translateX(0%)" }),
        // animate(".3s ease-out", style({ transform: "translateX(210vw)" })),
        style({ opacity: "1" }),
        animate(".3s ease-out", style({ opacity: "0" })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

const right = [
  // query(":enter, :leave", style({ width: "100%" }), {
  //   optional: true,
  // }),
  group([
    query(
      ":enter",
      [
        style({ transform: "translateX(210vw)" }),
        animate(".3s ease-out", style({ transform: "translateX(0%)" })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ":leave",
      [
        // style({ transform: "translateX(0%)" }),
        // animate(".3s ease-out", style({ transform: "translateX(-210vw)" })),
        style({ opacity: "1" }),
        animate(".3s ease-out", style({ opacity: "0" })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

export interface Tabs {
  neue_bewerbungen: User[];
  dateneingabe: User[];
  projekt_pool: User[];
  auswahl_auftraggeber: User[];
  final: User[];
  abgesagt: User[];
}

export type Tab = {
  label: string;
  key: keyof Tabs;
  data: User[];
  collapsed: boolean;
  allSelected: boolean;
  sortDirection: {
    field: string | null;
    order: number;
  };
};

@Component({
  selector: "app-personal",
  templateUrl: "./personal.component.html",
  styleUrls: ["./personal.component.scss"],
  animations: [
    trigger("animImageSlider", [
      transition(":increment", right),
      transition(":decrement", left),
    ]),
    trigger("fade", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class PersonalComponent implements OnInit, Tabs {
  @ViewChild("scrollTarget") scrollTarget?: ElementRef;

  @ViewChild("scrollTargetSwipe") scrollTargetSwipe?: ElementRef;

  public clearAllBodyScrollLocks = clearAllBodyScrollLocks;

  isLoading: boolean = false;

  pagination = {
    page: 1,
    perPage: 25,
    pagesTotal: 2,
    total: 0,
  };

  project_id: number = 0;

  allApplicants: User[] = [];

  neueProjekteCollapsed: boolean = false;

  neue_bewerbungen: User[] = [];

  dateneingabe: User[] = [];

  projekt_pool: User[] = [];

  auswahl_auftraggeber: User[] = [];

  final: User[] = [];

  abgesagt: User[] = [];

  getApplicantsToCheckIndex: number = -1;

  allApplicantsIndex: number = -1;

  jobRolesModalOpen: boolean = false;

  mailModal: boolean = false;

  searchString: string = "";

  aktionenDropdown: number = -1;

  copyPersonalModal: boolean = false;

  showMasterDataExportModal: boolean = false;

  applicantsForExport: User[] = [];

  filterTab(index: number, data: User[], search: string = "") {
    if (!search) this.tabs[index].data = data;
    this.tabs[index].data = data.filter(
      (user) =>
        user.profile?.vorname?.toLowerCase().includes(search) ||
        user.profile?.nachname?.toLowerCase().includes(search) ||
        user.personalnummer?.toString().toLowerCase().includes(search)
    );
  }

  get erledigtVerbindlichCount() {
    return this.final.filter(
      (user) => user.applications?.[0].status == "erledigt_verbindlich"
    ).length;
  }

  get percentPersonal() {
    const percent = Math.min(
      Math.round(
        (this.erledigtVerbindlichCount /
          (this.data?.currentProject?.personalbedarf || 1)) *
          100
      ),
      100
    );
    return percent;
  }

  applicantsToCheck: User[] = [];

  setApplicantsToCheck(tab: Tab) {
    this.applicantsToCheck = tab.data.filter(
      (el) =>
        (!this.data.currentProject?.auswahl_durch_kunden_zulassen &&
          [
            "auswahl_auftraggeber_visible",
            "auswahl_auftraggeber_invisible",
            "auftraggeber_akzeptiert",
            "auftraggeber_abgesagt",
          ].includes(el.applications![0].status!)) ||
        this.status[el.applications![0].status!].action_required
    );
  }

  tabs: Tab[] = [
    {
      label: "Neue Bewerbungen",
      key: "neue_bewerbungen",
      data: this.neue_bewerbungen,
      collapsed: false,
      allSelected: false,
      sortDirection: {
        field: null,
        order: 0,
      },
    },
    {
      label: "Dateneingabe",
      key: "dateneingabe",
      data: this.dateneingabe,
      collapsed: false,
      allSelected: false,
      sortDirection: {
        field: null,
        order: 0,
      },
    },
    {
      label: "Projekt Pool",
      key: "projekt_pool",
      data: this.projekt_pool,
      collapsed: false,
      allSelected: false,
      sortDirection: {
        field: null,
        order: 0,
      },
    },
    {
      label: "Auswahl Auftraggeber",
      key: "auswahl_auftraggeber",
      data: this.auswahl_auftraggeber,
      collapsed: false,
      allSelected: false,
      sortDirection: {
        field: null,
        order: 0,
      },
    },
    {
      label: "Final",
      key: "final",
      data: this.final,
      collapsed: false,
      allSelected: false,
      sortDirection: {
        field: null,
        order: 0,
      },
    },
    {
      label: "Abgesagt",
      key: "abgesagt",
      data: this.abgesagt,
      collapsed: false,
      allSelected: false,
      sortDirection: {
        field: null,
        order: 0,
      },
    },
  ];

  public tableHeaders: {
    label: string;
    sortDirectionKey: string;
  }[] = [
    { label: "Name", sortDirectionKey: "profile.vorname" },
    { label: "Status", sortDirectionKey: "applications.0.status" },
    { label: "Erfahrungen", sortDirectionKey: "erfahrungen_count" },
    { label: "Wohnort", sortDirectionKey: "profile.plz" },
    { label: "Topteamer", sortDirectionKey: "is_topteamer" },
  ];

  status = statusLabelRequired;

  public dayjs = dayjs;

  selectAll(tab: Tab) {
    if (tab.allSelected) {
      tab.data.forEach((el) => {
        el.selected = true;
      });
    } else {
      tab.data.forEach((el) => {
        el.selected = false;
      });
    }
  }

  checkSelectAll(tab: Tab) {
    let allSelected = true;
    tab.data.forEach((el) => {
      allSelected = allSelected && (el.selected || false);
    });
    tab.allSelected = allSelected;
  }

  closeMailModal() {
    this.mailModal = false;
    this.deselectAll();
  }

  deselectAll() {
    this.tabs.forEach((tab) => {
      tab.allSelected = false;
      tab.data.forEach((a) => {
        a.selected = false;
      });
    });
  }

  get selectedMails() {
    return this.tabs.reduce((acc: string[], curr: Tab) => {
      return [
        ...acc,
        ...curr.data.filter((a) => a.selected).map((a) => a.email),
      ];
    }, []);
  }

  get selectedUsers() {
    return this.tabs.reduce((acc: User[], curr: Tab) => {
      return [...acc, ...curr.data.filter((a) => a.selected)];
    }, []);
  }

  get copyAbleUserIds(): number[] {
    return (
      this.selectedUsers
        // .filter((el) => el.applications?.[0].status == "erledigt_verbindlich")
        .map((el) => el.id!)
    );
  }

  linkAlreadySentOnce: boolean = false;

  baseUrl = `${window.location.protocol}//${window.location.host}/auswahl/${this.data.currentProject?.project_uuid}`;

  auswahlAnKundenForm: FormGroup = new FormGroup({
    kunden_email: new FormControl("", [Validators.email, Validators.required]),
    auswahl_durch_kunden_zulassen: new FormControl(false, []),
    mitteilung: new FormControl("", []),
  });

  constructor(
    public data: DataService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.auswahlAnKundenForm.disable();
  }

  openEditForm(user: User, tab: Tab) {
    this.aktionenDropdown = -1;
    // if (!this.applicantsToCheck.length) return;

    this.setApplicantsToCheck(tab);

    this.getApplicantsToCheckIndex = this.applicantsToCheck.findIndex(
      (userF) => userF.id == user?.id
    );

    if (this.getApplicantsToCheckIndex > -1) {
      setTimeout(() => {
        console.log(`this.scrollTargetSwipe`, this.scrollTargetSwipe);
        disableBodyScroll(this.scrollTargetSwipe?.nativeElement);
      }, 200);
      return;
    }

    this.allApplicantsIndex = this.allApplicants.findIndex(
      (userF) => userF.id == user?.id
    );
    setTimeout(() => {
      console.log(`this.scrollTarget`, this.scrollTarget);
      disableBodyScroll(this.scrollTarget?.nativeElement);
    }, 200);
    // this.userToSwipe = user;
  }

  closeEditForm() {
    if (
      this.applicantsToCheck[this.getApplicantsToCheckIndex]!.applications![0]
        .status != "neu"
    ) {
      Confirm.show(
        "Bist du sicher?",
        "Nicht gespeicherte Änderungen gehen verloren?",
        "Nein",
        "Ja",
        () => {
          // nein
        },
        async () => {
          // ja
          this.getApplicantsToCheckIndex = -1;
        }
      );
    } else {
      this.getApplicantsToCheckIndex = -1;
    }
  }

  closeMasterDataExportModal() {
    this.showMasterDataExportModal = false;
  }

  onMasterDataDownload(payLoad: any) {
    console.log("onMasterDataDownload payLoad: ", payLoad);

    const dateTimeString = dayjs().format("YYYY-MM-DD_HH-mm");
    const projectNameString = this.data.currentProjectInternalTitle;
    const fileName = `stammdaten_export_${projectNameString}_${dateTimeString}.xlsx`;

    this.data
      .downloadSelectedStammdatenOfUsers(
        payLoad.applicants,
        payLoad.selectedMasterDataFields,
        fileName
      )
      .then(() => {
        this.closeMasterDataExportModal();
      })
      .catch((onMasterDataDownload) => {
        console.error("onMasterDataDownload", onMasterDataDownload);
      });
  }

  navBack() {
    // sicher abfrage wenn nicht gespeichert?
    this.getApplicantsToCheckIndex -= 1;
  }

  async navNext(afterAction: boolean = false) {
    console.log(`afterAction`, afterAction);
    if (afterAction) {
      await this.refreshData();
      if (this.getApplicantsToCheckIndex >= this.applicantsToCheck.length - 1) {
        this.getApplicantsToCheckIndex = -1;
      } else {
        this.getApplicantsToCheckIndex += 1;
      }
    } else if (this.allApplicantsIndex == -1) {
      this.getApplicantsToCheckIndex += 1;
    }
    if (this.allApplicantsIndex > -1) {
      this.allApplicantsIndex = -1;
    }
  }

  auswahlAnKundenFormSubscription: Subscription | undefined;

  eventBusSubscription: Subscription | undefined;

  async ngOnInit() {
    // get users for project

    this.eventBusSubscription = this.data.eventBus.subscribe((event) => {
      if (event == "open_auswahl_kontaktieren_personal") {
        if (this.selectedMails.length) {
          this.mailModal = true;
        } else {
          Notify.warning("Bitte wähle mindestens einen Bewerber aus");
        }
      }
      if (event == "move_auswahl_personal_to_other_project") {
        this.copyPersonalModal = true;
      }
      if (event === "open_master_data_export_configurator") {
        this.showMasterDataExportModal = true;
      }
    });

    // link an kunde senden
    try {
      if (this.data.currentProject?.link_already_sent_once) {
        this.linkAlreadySentOnce = true;
      }
      if (this.data.currentProject) {
        this.auswahlAnKundenForm.patchValue(
          {
            kunden_email: this.data.currentProject.kunden_email,
            mitteilung: this.data.currentProject.mitteilung,
            auswahl_durch_kunden_zulassen:
              this.data.currentProject.auswahl_durch_kunden_zulassen,
          },
          { emitEvent: false }
        );
      }
    } catch (error) {
      console.log(`error`, error);
    }

    if (!this.auswahlAnKundenFormSubscription) {
      this.auswahlAnKundenFormSubscription =
        this.auswahlAnKundenForm.valueChanges
          .pipe(startWith(undefined), pairwise())
          .subscribe(([prev, curr]) => {
            console.log(`[prev, curr]`, [prev, curr]);
            if (!prev || !curr) {
              return;
            }
            if (
              prev?.auswahl_durch_kunden_zulassen !=
              curr?.auswahl_durch_kunden_zulassen
            ) {
              // update
              this.data.spinner(true);
              try {
                this.data
                  .updateAuswahlAnKundenSenden(
                    this.data.currentProject!.id!,
                    curr.auswahl_durch_kunden_zulassen
                  )
                  .then(() => {
                    this.refreshData();
                    Notify.success(
                      "Die Einstellung wurde erfolgreich gespeichert."
                    );
                  });
                // this.auswahlAnKundenForm.disable({ emitEvent: false });
              } catch (error) {
                console.log(`updateAuswahlAnKundenSenden error`, error);
                Notify.failure("Fehler beim Speichern der Einstellung.");
              }
              this.data.spinner(false);
            }
          });
    }
    // link an kunde senden end

    await this.refreshData();

    if (this.data.debug && this.final.length > 2 && false) {
      for (let i = 0; i < 3; i++) {
        this.final[i].selected = true;
      }
      this.copyPersonalModal = true;
    }
    // eslint-disable-next-line no-constant-condition
    if (this.data.debug && false) {
      // this.openEditForm(
      //   this.neue_bewerbungen.filter(
      //     (el) => el.is_topteamer && el.applications![0].status == "neu"
      //   )[0]
      // );
      console.log(`this.auswahl_auftraggeber`, this.auswahl_auftraggeber);
      this.openEditForm(
        this.final.filter(
          (el) => el.applications?.[0]?.status === "erledigt_verbindlich"
        )[0],
        this.tabs[4]
      );
    }
    if (this.data.debug && false) {
      this.jobRolesModalOpen = true;
    }

    // filter allApplicants to prefill applicantsForExport
    this.refreshApplicantsForExport();
  }

  ngOnDestroy() {
    if (this.auswahlAnKundenFormSubscription) {
      this.auswahlAnKundenFormSubscription.unsubscribe();
    }

    if (this.eventBusSubscription) {
      this.eventBusSubscription.unsubscribe();
    }
    clearAllBodyScrollLocks();
  }

  /**
   * refreshApplicantsForExport goes through the allApplicants array and filters
   * only the Users which application status is on of the following:
   * warten_auf_daten, daten_pruefen
   * auswahl_auftraggeber_invisible, auswahl_auftraggeber_visible, auftraggeber_akzeptiert, auftraggeber_abgesagt
   * warten_auf_vertrag, vertrag_gegenzeichnen, erledigt_verbindlich
   */
  refreshApplicantsForExport() {
    this.applicantsForExport = this.allApplicants.filter((user) =>
      [
        "warten_auf_daten",
        "daten_pruefen",
        "auswahl_auftraggeber_invisible",
        "auswahl_auftraggeber_visible",
        "auftraggeber_akzeptiert",
        "auftraggeber_abgesagt",
        "warten_auf_vertrag",
        "vertrag_gegenzeichnen",
        "erledigt_verbindlich",
      ].includes(user.applications![0].status ?? "")
    );
  }

  public submitAttempt: boolean = false;

  async auswahlAnKundenSenden() {
    if (this.auswahlAnKundenForm.invalid) {
      this.submitAttempt = true;
      Notify.failure("Es fehlt noch eine gültige E-Mail-Adresse");
      return;
    }
    const dataToSend = Object.assign(this.auswahlAnKundenForm.value, {
      url: this.baseUrl,
      project_id: this.data.currentProject!.id,
    });
    this.isLoading = true;
    try {
      this.data.spinner(true);
      await this.data.auswahlAnKundenSenden(dataToSend);
      this.data.spinner(false);
      Notify.success("Die Auswahl wurde erfolgreich per E-Mail versendet");
      this.linkAlreadySentOnce = true;
      this.auswahlAnKundenForm.disable();
      this.refreshData();
    } catch (error) {
      this.data.spinner(false);
      Notify.failure("Etwas ist schief gelaufen.");
      console.error(`auswahlAnKundenSenden error`, error);
      this.auswahlAnKundenForm.disable();
    }
    this.isLoading = false;
  }

  async refreshData() {
    console.log(`this.route`, this.route);
    this.project_id = Number.parseInt(
      this.route.snapshot.paramMap.get("id") || "0",
      10
    );
    this.data.getProjectById(this.project_id, true).then((project) => {
      this.data.currentProject = project;
    });
    console.log(`this.project_id`, this.project_id);
    if (!this.project_id) {
      throw new Error("No project_id");
    }

    this.allApplicants = await this.data.getApplicants(this.project_id, false, [
      "regular",
    ]);
    console.log(`this.allApplicants`, this.allApplicants);

    this.neue_bewerbungen.splice(0);
    this.neue_bewerbungen.push(
      ...this.allApplicants
        .filter((app) => app.applications![0].status == "neu")
        .sort(sortByStatusAndThenByName)
    );
    this.neue_bewerbungen.forEach((app) => {
      app.personal_category = "Neue Bewerbungen";
      return null;
    });

    this.dateneingabe.splice(0);
    this.dateneingabe.push(
      ...this.allApplicants
        .filter((app) =>
          ["warten_auf_daten", "daten_pruefen"].includes(
            app.applications![0].status || ""
          )
        )
        .sort(sortByStatusAndThenByName)
    );
    this.dateneingabe.forEach((app) => {
      app.personal_category = "Dateneingabe";
      return null;
    });

    this.projekt_pool.splice(0);
    this.projekt_pool.push(
      ...this.allApplicants
        .filter((app) => app.applications![0].status == "projekt_pool")
        .sort(sortByStatusAndThenByName)
    );
    this.projekt_pool.forEach((app) => {
      app.personal_category = "Projekt Pool";
      return null;
    });

    this.auswahl_auftraggeber.splice(0);
    this.auswahl_auftraggeber.push(
      ...this.allApplicants
        .filter((app) =>
          [
            "auswahl_auftraggeber_invisible",
            "auswahl_auftraggeber_visible",
            "auftraggeber_akzeptiert",
            "auftraggeber_abgesagt",
          ].includes(app.applications![0].status!)
        )
        .sort(sortByStatusAndThenByName)
    );
    this.auswahl_auftraggeber.forEach((app) => {
      app.personal_category = "Auswahl Auftraggeber";
      return null;
    });

    this.final.splice(0);
    this.final.push(
      ...this.allApplicants
        .filter((app) =>
          [
            "warten_auf_vertrag",
            "vertrag_gegenzeichnen",
            "erledigt_verbindlich",
          ].includes(app.applications![0].status || "")
        )
        .sort(sortByStatusAndThenByName)
    );
    this.final.forEach((app) => {
      app.personal_category = "Final";
      return null;
    });

    this.abgesagt.splice(0);
    this.abgesagt.push(
      ...this.allApplicants
        .filter((app) =>
          ["abgesagt", "gekuendigt"].includes(app.applications![0].status || "")
        )
        .sort(sortByStatusAndThenByName)
    );
    this.abgesagt.forEach((app) => {
      app.personal_category = "Abgesagt";
      return null;
    });

    this.filterTab(4, this.final, this.searchString);
    this.sortAllTabs();

    this.tabs.forEach((tab) => {
      // eslint-disable-next-line no-unused-expressions
      tab.data.length ? (tab.collapsed = false) : (tab.collapsed = true);
    });
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.baseUrl);
  }

  async auswahlHinzufuegen() {
    this.isLoading = true;
    this.data.spinner(true);
    try {
      await this.data.auswahlHinzufuegen(this.project_id);
      await this.refreshData();
      Notify.success("Die Auswahl wurde erfolgreich hinzugefügt");
    } catch (error) {
      console.log(`auswahlHinzufuegen error`, error);
      Notify.failure("Etwas ist schief gelaufen. Bitte versuche es erneut.");
    }
    this.isLoading = false;
    this.data.spinner(false);
  }

  sortAllTabs() {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].sortDirection.field) {
        console.log(`this.tabs[i]`, this.tabs[i]);
        this.toggleSortDirection(null, this.tabs[i]);
      }
    }
  }

  toggleSortDirection(key: string | null, tab: Tab) {
    const key_internal = key || tab.sortDirection.field || "";
    if (!key_internal) return;
    const arrayKeysSplit = key_internal.split(".");
    if (key) {
      if (tab.sortDirection.field == key) {
        tab.sortDirection.order = (tab.sortDirection.order + 1) % 3;
      } else {
        tab.sortDirection.field = key;
        tab.sortDirection.order = 1;
      }
    }
    // 0 = no sort, 1 = asc, 2 = desc
    if (tab.sortDirection.order == 0) {
      tab.data.sort(sortByStatusAndThenByName);
    } else {
      tab.data.sort((a, b) => {
        let aVal: any = a;
        let bVal: any = b;
        arrayKeysSplit.forEach((k) => {
          aVal = aVal[k];
          bVal = bVal[k];
        });
        if (
          aVal == bVal ||
          (aVal == null && bVal == null) ||
          (aVal == undefined && bVal == undefined)
        ) {
          return 0;
        }
        if (typeof aVal == "string" || typeof bVal == "string") {
          aVal = (aVal || "zzzzz").toLowerCase();
          bVal = (bVal || "zzzzz").toLowerCase();
          if (tab.sortDirection.order == 1) {
            return aVal.localeCompare(bVal);
          }
          return bVal.localeCompare(aVal);
        }
        if (typeof aVal == "number" || typeof bVal == "number") {
          if (tab.sortDirection.order == 1) {
            return (aVal || 0) - (bVal || 0);
          }
          return (bVal || 0) - (aVal || 0);
        }
        if (typeof aVal == "boolean" || typeof bVal == "boolean") {
          if (tab.sortDirection.order == 1) {
            return aVal || false ? 1 : -1;
          }
          return bVal || false ? 1 : -1;
        }
        return 0;
      });

      if (tab.key == "auswahl_auftraggeber") {
        tab.data.sort(sortByStatusAndThenByName);
      }
    }
  }

  async cancelApplicationsBecauseProjectIsFull(tabIndex: number) {
    try {
      // get all applicants from the tab
      const applicants = this.tabs[tabIndex].data;
      let ids = [];
      if (this.tabs[tabIndex].allSelected) {
        ids = applicants.map((a) => a.id);
      } else {
        ids = applicants.filter((a) => a.selected).map((a) => a.id);
      }

      if (ids.length == 0) {
        Notify.warning("Bitte wähle mindestens eine Bewerbung aus");
        return;
      }

      const confirm = await new Promise((resolve) => {
        let message =
          "Bist du sicher, dass du die Bewerbungen abbrechen möchtest?";
        if (tabIndex == 4) {
          message +=
            " Bereits bestätigte Topteamer im Status 'Erledigt' werden nicht abgebrochen. Diese müssen manuell gekündigt werden.";
        }
        Confirm.show(
          "Bewerbungen abbrechen",
          message,
          "Abbrechen",
          "OK",
          () => {
            resolve(false);
          },
          () => {
            resolve(true);
          },
          {
            messageMaxLength: 1000000,
            plainText: false,
          } as IConfirmOptions
        );
      });

      if (confirm) {
        this.isLoading = true;

        const body = {
          project_id: this.project_id,
          user_ids: ids,
        };
        const result = await this.data.postRequest(
          "/project/cancelApplicationsBecauseProjectIsFull",
          body
        );
        if (
          !result.reduce(
            (acc: boolean, curr: any) => acc && curr.status == "fulfilled",
            true
          )
        ) {
          Notify.warning("Einige E-Mails konnten nicht versendet werden");
        }
        Notify.success("Die Bewerbungen wurden erfolgreich abgelehnt");
        await this.refreshData();
      }
    } catch (error) {
      console.error(error);
      Notify.failure("Etwas ist schief gelaufen. Bitte versuche es erneut.");
    }
    this.isLoading = false;
  }

  /**
   * Get the first name of a full name, e.g. "Max Otto" -> "Max".
   * Also removes any leading or trailing whitespaces and handles special cases like " - " -> "-"
   *
   * @param firstNames all first names
   * @returns
   */
  public toFirstNameOnly(firstNames?: string): string {
    if (!firstNames) {
      return "";
    }

    return firstNames.trim().replace(" - ", "-").split(" ")[0];
  }

  async downloadAllZipFiles(tabIndex: number) {
    const applicants = this.tabs[tabIndex].data;
    const users = applicants.filter((a) => a.selected);

    if (users.length === 0) {
      Notify.warning("Bitte wähle mindestens eine Bewerbung aus");
      return;
    }

    const request = await firstValueFrom(
      this.http.post(
        `${environment.apiUrl}/project/postDownloadAllDocuments`,
        {
          user_ids: users.map((u) => u.id),
          project_id: this.project_id,
        },
        {
          responseType: "blob",
          observe: "response",
        }
      )
    );

    const blob = request.body;
    if (!request.ok) {
      Notify.failure("Fehler beim Download der Dateien");
      return;
    }

    if (!blob || request.status === 204) {
      Notify.info("Es sind keine Dateien zum Download verfügbar");
      return;
    }

    const sizeOfEmptyZip = 22;
    if (blob.size == sizeOfEmptyZip) {
      Notify.warning("Es sind keine Dateien zum Download verfügbar");
      return;
    }

    const downloadEl = document.createElement("a");
    const url = URL.createObjectURL(blob);
    document.body.appendChild(downloadEl);
    downloadEl.href = url;
    downloadEl.download = `Dokumente.zip`;
    downloadEl.click();
    downloadEl.remove();
    window.URL.revokeObjectURL(url);
  }
}
