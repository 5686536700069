// import { sortByStatus } from "@ttc_types/functions";
import {
  Projekt,
  ShowArbeitskraefte,
  User,
  Geschlecht,
} from "@ttc_types/types";
import { Notify } from "notiflix";
import { DataService } from "src/app/data.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as dayjs from "dayjs";
import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { sortByStatus } from "src/app/shared/functions";
import * as XLSX from "xlsx";

const left = [
  // query(":enter, :leave", style({ width: "100%" }), {
  //   optional: true,
  // }),
  group([
    query(
      ":enter",
      [
        style({ transform: "translateX(-210vw)" }),
        animate(".3s ease-out", style({ transform: "translateX(0%)" })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ":leave",
      [
        // style({ transform: "translateX(0%)" }),
        // animate(".3s ease-out", style({ transform: "translateX(210vw)" })),
        style({ opacity: "1" }),
        animate(".3s ease-out", style({ opacity: "0" })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

const right = [
  // query(":enter, :leave", style({ width: "100%" }), {
  //   optional: true,
  // }),
  group([
    query(
      ":enter",
      [
        style({ transform: "translateX(210vw)" }),
        animate(".3s ease-out", style({ transform: "translateX(0%)" })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ":leave",
      [
        // style({ transform: "translateX(0%)" }),
        // animate(".3s ease-out", style({ transform: "translateX(-210vw)" })),
        style({ opacity: "1" }),
        animate(".3s ease-out", style({ opacity: "0" })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

@Component({
  selector: "app-auswahl",
  templateUrl: "./auswahl.component.html",
  styleUrls: ["./auswahl.component.scss"],
  host: { class: "host-full-width" },
  animations: [
    trigger("animImageSlider", [
      transition(":increment", right),
      transition(":decrement", left),
    ]),
    trigger("fade", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AuswahlComponent implements OnInit {
  constructor(private data: DataService, private route: ActivatedRoute) {}

  Geschlecht = Geschlecht;

  project: Projekt | undefined;

  applicants: ShowArbeitskraefte[] | undefined;

  ansprechperson: User | undefined;

  uuid: string | undefined | null;

  preview: string | undefined | null;

  isLoading: boolean = false;

  getApplicantsToCheckIndex: number = -1;

  notValidAnymore: boolean = false;

  navBack() {
    // sicher abfrage wenn nicht gespeichert?
    this.getApplicantsToCheckIndex -= 1;
  }

  openEditForm(user: User) {
    if (!this.applicants || !this.applicants.length) return;
    this.getApplicantsToCheckIndex = this.applicants.findIndex(
      (userF) => userF.id == user.id
    );
    // this.userToSwipe = user;
  }

  async navNext(afterAction: boolean = false) {
    console.log("navNext");
    if (afterAction) {
      // await this.refreshData();
      if (
        this.getApplicantsToCheckIndex >=
        (this.applicants ? this.applicants.length : 0) - 1
      ) {
        this.getApplicantsToCheckIndex = -1;
      } else {
        this.getApplicantsToCheckIndex += 1;
      }
    } else {
      this.getApplicantsToCheckIndex += 1;
    }
  }

  get zugesagtCount() {
    return this.applicants?.filter((a) =>
      [
        "auftraggeber_akzeptiert",
        "warten_auf_vertrag",
        "vertrag_gegenzeichnen",
        "erledigt_verbindlich",
      ].includes(a.applications![0].status!)
    ).length;
  }

  get abgesagtCount() {
    return this.applicants?.filter((a) =>
      ["auftraggeber_abgesagt"].includes(a.applications![0].status!)
    ).length;
  }

  async ngOnInit() {
    await this.init();
  }

  async init() {
    const routeParams = this.route.snapshot.paramMap;
    try {
      this.data.spinner(true);
      this.uuid = routeParams.get("uuid");
      this.preview = this.route.snapshot.queryParamMap.get("preview");

      console.log(`this.preview`, this.preview);
      if (!this.uuid) return;
      const { project, applicants, ansprechperson } =
        await this.data.getProjectForAuswahl(this.uuid, this.preview == "1");
      this.project = project;
      this.ansprechperson = ansprechperson;
      let biggest_in_auswahl_seit = 0;
      this.applicants = applicants.map((user) => {
        // eslint-disable-next-line no-param-reassign
        user.in_auswahl_seit = parseInt(
          user.applications?.[0].in_auswahl_seit || "0",
          10
        );
        if (user.in_auswahl_seit > biggest_in_auswahl_seit) {
          biggest_in_auswahl_seit = user.in_auswahl_seit;
        }
        return user;
      });
      this.applicants.forEach((user) => {
        if (user.in_auswahl_seit == biggest_in_auswahl_seit) {
          // eslint-disable-next-line no-param-reassign
          user.has_golden_dot = true;
        }
      });

      this.applicants.sort(sortByStatus);

      //sort by in_auswahl_seit
      this.applicants.sort(
        (a, b) => (b.in_auswahl_seit || 0) - (a.in_auswahl_seit || 0)
      );

      if (ansprechperson.applications?.[0]?.job_role) {
        this.applicants.push(ansprechperson);
      }
      //sort by job_role
      if (project?.job_roles?.length) {
        this.applicants.sort(
          (a, b) =>
            project.job_roles!.findIndex(
              (r) => r == a.applications?.[0].job_role || ""
            ) -
            project.job_roles!.findIndex(
              (r) => r == b.applications?.[0].job_role || ""
            )
        );
      }
      this.applicants.forEach((user) => {
        if (user.profile && user.profile.geburtsdatum) {
          // eslint-disable-next-line no-param-reassign
          user.alter = dayjs()
            .diff(dayjs(user.profile.geburtsdatum, "DD.MM.YYYY"), "years")
            .toFixed();
          // tmp.alter = ;
        }
      });
      console.log(`project`, project);
      console.log(`applicants`, applicants);
      console.log(`ansprechperson`, ansprechperson);
    } catch (error) {
      this.notValidAnymore = true;
      // Notify.failure("Etwas ist schief gegangen.");
      console.error(`error`, error);
      this.data.spinner(false);
    }
    this.data.spinner(false);
  }

  csvExportKundenansicht() {
    //workbook
    const wb = XLSX.utils.book_new();

    const header = ["Rolle", "Vorname", "Nachname", "Geschlecht", "Alter"];
    const csvData = [];
    for (let i = 0; i < this.applicants!.length; i++) {
      // if (
      //   (i == 0 && this.applicants?.[0]?.applications?.[0]?.job_role) ||
      //   (i > 0 &&
      //     this.applicants?.[i]?.applications?.[0]?.job_role !=
      //       this.applicants?.[i - 1]?.applications?.[0]?.job_role)
      // ) {
      //   csvData.push({
      //     Name: this.applicants?.[i]?.applications?.[0]?.job_role,
      //     Geschlecht: "",
      //     Alter: "",
      //   });
      // }
      csvData.push({
        Rolle: this.applicants?.[i]?.applications?.[0]?.job_role || "",
        Vorname: `${this.applicants?.[i].profile?.vorname}`,
        Nachname: `${this.applicants?.[i].profile?.nachname}`,
        Geschlecht: this.applicants?.[i].profile?.geschlecht
          ? // eslint-disable-next-line no-undef
            Geschlecht[this.applicants?.[i]?.profile?.geschlecht || "m"]
          : "",
        Alter: this.applicants?.[i].alter,
        // Entscheidung: [
        //   "auftraggeber_akzeptiert",
        //   "auftraggeber_abgesagt",
        // ].includes(user.applications?.[0]?.status || ""),
      });
    }

    //worksheet
    const ws = XLSX.utils.json_to_sheet(csvData, { header });
    ws["!cols"] = header.map(() => ({ width: 30 }));
    //add worksheet to workbook

    XLSX.utils.book_append_sheet(wb, ws, "Kundenansicht");

    const time_string = dayjs().format("YYYY-MM-DD_HH-mm");
    let projektname = (this.project?.titel || "Projekt").replace(/ /g, "_");
    //replace , . / \ : * ? " < > |
    projektname = projektname.replace(/[,./\\:*?"<>|]/g, "");

    const fileName = `${time_string}_${projektname}_Personalauswahl.xlsx`;
    //save workbook
    XLSX.writeFile(wb, fileName);
  }

  async entscheidungUebermitteln() {
    if (!this.uuid || !this.applicants || !this.applicants.length) return;
    try {
      this.isLoading = true;
      await this.data.entscheidungUebermitteln(this.uuid, this.applicants);
      Notify.success("Ihre Entscheidung wurde erfolgreich übermittelt");
      // await this.init();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      Notify.failure("Etwas ist schief gelaufen.");
    }
  }
}
