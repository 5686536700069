import { BeschaeftigungLaufendesJahr, Projekt, User } from "@ttc_types/types";
import * as dayjs from "dayjs";

export const sortByStatus = (a: User, b: User) => {
  const statusOrder = [
    "neu",
    "daten_pruefen",
    "warten_auf_daten",
    "projekt_pool", // unentschlossen
    "auswahl_auftraggeber_invisible", // in auswahl für auftraggeber -> sichtbar in link
    "auswahl_auftraggeber_visible", // in auswahl für auftraggeber -> unsichtbar in link
    "auftraggeber_akzeptiert",
    "auftraggeber_abgesagt",
    "vertrag_gegenzeichnen", // admin/ pm muss gegenzeichnen
    "warten_auf_vertrag", // arbeitskraft muss unterzeichnen
    "erledigt_verbindlich", // final - alle haben unterzeichnet;
    // was bei kündigung machen?
    "abgesagt",
    "gekuendigt",
  ];
  return (
    statusOrder.indexOf(a.applications![0].status!) -
    statusOrder.indexOf(b.applications![0].status!)
  );
};

export const sortByStatusAndThenByName = (a: User, b: User) => {
  const statusOrder = [
    "neu",
    "daten_pruefen",
    "warten_auf_daten",
    "projekt_pool", // unentschlossen
    "auswahl_auftraggeber_invisible", // in auswahl für auftraggeber -> sichtbar in link
    "auswahl_auftraggeber_visible", // in auswahl für auftraggeber -> unsichtbar in link
    "auftraggeber_akzeptiert",
    "auftraggeber_abgesagt",
    "vertrag_gegenzeichnen", // admin/ pm muss gegenzeichnen
    "warten_auf_vertrag", // arbeitskraft muss unterzeichnen
    "erledigt_verbindlich", // final - alle haben unterzeichnet;
    // was bei kündigung machen?
    "abgesagt",
    "gekuendigt",
  ];
  const statusA = statusOrder.indexOf(a.applications![0].status!);
  const statusB = statusOrder.indexOf(b.applications![0].status!);
  if (statusA === statusB) {
    const nameComp = (a.profile?.vorname || "").localeCompare(
      b.profile?.vorname || ""
    );
    if (nameComp === 0) {
      return (a.profile?.nachname || "").localeCompare(
        b.profile?.nachname || ""
      );
    }
    return nameComp;
  }
  return statusA - statusB;
};

// TODO: restrict laufendes jahr always to current year of the project.
// TODO: status bei arbeitsbeginn rein?
export type LaufendesJahrReturn = {
  year: string;
  tage_beschaeftigt: number;
  valid: boolean;
  errors: string[];
};

export const getLaufendeTage = (
  laufendesJahr: { [key: string]: BeschaeftigungLaufendesJahr[] },
  yearsToCheck: string[] = [dayjs().year().toString()]
): LaufendesJahrReturn[] => {
  return yearsToCheck.map((year: string) => {
    const res = {
      year,
      tage_beschaeftigt: 0,
      valid: true,
      errors: [],
    } as LaufendesJahrReturn;

    for (const key of Object.keys(laufendesJahr)) {
      if (key == "KurzfristigeBeschaeftigung" || key == "Praktikum") {
        if (Array.isArray(laufendesJahr[key])) {
          for (const lj of laufendesJahr[key]) {
            const start = dayjs(lj.zeitraum_von);
            const end = dayjs(lj.zeitraum_bis);
            if (start.year().toString() === year) {
              if (key == "Praktikum" && lj.praktikum_art == "Freiwillig") {
                // console.log(
                //   `start.diff(end, 'months')`,
                //   start.diff(end, "months")
                // );
                if (Math.abs(start.diff(end, "months")) < 3) {
                  res.tage_beschaeftigt += lj.tage_beschaeftigt || 0;
                } else {
                  res.errors.push(
                    `Der Bewerber hat im Jahr ${res.year} ein freiwilliges Praktikum, welches über 3 Monate dauert.`
                  );
                  res.valid = false;
                }
              }
              if (key == "KurzfristigeBeschaeftigung") {
                res.tage_beschaeftigt += lj.tage_beschaeftigt || 0;
              }
            }
          }
        }
      }
    }

    // TODO: hier evt noch projektlänge reinrechnen / schätzung tage?

    if (res.tage_beschaeftigt > 70) {
      res.errors.push(
        `Der Bewerber ist im Jahr ${res.year} ${res.tage_beschaeftigt} beschäftigt.`
      );
      res.valid = false;
    }

    return res;
  });
};

export const getProjectYears = (project: Projekt) => {
  const years = [
    dayjs(project!.zeitraum_von).year(),
    dayjs(project!.zeitraum_bis).year(),
  ].sort((a, b) => a - b);
  if (years[1] - years[0] > 1) {
    for (let year = years[0]; year < years[1]; year++) {
      years.push(year);
    }
    years.sort((a, b) => a - b);
  }
  return [...new Set(years)].map((el) => el.toString());
};
