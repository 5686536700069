import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Confirm, IConfirmOptions, Notify, Report } from "notiflix";
import {
  Application,
  CheckInQrCodeResult,
  Document,
  Projekt,
  SelectableDay,
  TeamTimeSlotDay,
  TimetrackerSlot,
} from "@ttc_types/types";
import { SignaturePad } from "angular2-signaturepad";
import { DataService } from "src/app/data.service";
import { AuthService } from "src/app/shared/auth.service";
import { animate, style, transition, trigger } from "@angular/animations";
import * as dayjs from "dayjs";

@Component({
  selector: "app-timetracker",
  templateUrl: "./timetracker.component.html",
  styleUrls: ["./timetracker.component.scss"],
  animations: [
    trigger("fade", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class TimetrackerComponent implements OnInit {
  @Input() inReview: boolean = false;

  @Input() extraClasses: string = "";

  applicationId: number | undefined;

  @Input() application: Application | undefined;

  @Input() projectId!: number | undefined;

  @Input() managerView: boolean = false;

  @Input() eventName: string = "";

  @HostBinding("class.in-review") isInReview: boolean = false;

  @ViewChild(SignaturePad) signaturePad!: SignaturePad;

  project?: Projekt;

  addTimeModalVisible: boolean = false;

  addTimeModalEditTime: boolean = false;

  addTimeModalEditBySupervisor: boolean = false;

  overMidnightHintModalVisible: boolean = false;

  customerSignModalVisible: boolean = false;

  customerSignModalEmail: string = "";

  myTimeSlots: TimetrackerSlot[] = [];

  myTimeOverall: number = 0;

  teamTimeSlots: TimetrackerSlot[] = [];

  currentVisibleTeamTimeSlots: TimetrackerSlot[] = [];

  teamTimeSlotDays: TeamTimeSlotDay[] = [];

  currentActiveTeamTimeSlotDayIndex: number = 0;

  teamTimeOverall: number = 0;

  formattedTeamTimeOverall: string = "";

  projectTimeOverall: number = 0;

  formattedProjectTimeOverall: string = "";

  possibleDaysFromTimestamp: number = dayjs()
    .tz("Europe/Berlin")
    .startOf("day")
    .valueOf();

  possibleDaysToTimestamp: number = dayjs()
    .tz("Europe/Berlin")
    .startOf("day")
    .valueOf();

  possibleDaysAmount: number = 0;

  selectableDays: SelectableDay[] = [];

  isExportAllVisible: boolean = false;

  // real time values:
  addTimeValues: TimetrackerSlot = {
    time_from: 0,
    time_to: 0,
    time_break: 0,
  };

  timeFromInputValue: any = "";

  timeToInputValue: any = "";

  overMidnightString: string = "";

  breakHoursInputValue: number = 0;

  breakMinutesInputValue: number = 0;

  formattedTimeDuration: string = "";

  minBreakHoursValue: number = 0;

  minBreakMinutesValue: number = 0;

  // fake time values
  fakeTimeFromInputValue: any = "";

  fakeTimeToInputValue: any = "";

  fakeOverMidnightString: string = "";

  fakeBreakHoursInputValue: number = 0;

  fakeBreakMinutesInputValue: number = 0;

  fakeFormattedTimeDuration: string = "";

  extraTimesList: TimetrackerSlot[] = [];

  // documents and signature
  signatureBase64: string = "";

  signaturePadOpts: Object | null = null;

  documents: Document[] = [];

  isUploading: boolean = false;

  endOfFestgeschriebenMonthTimestamp: number = 0;

  liveCheckinQrCodeString: string | undefined;

  showQrScannerModal: boolean = false;

  showAddTimeForTopteamerOverlay: boolean = false;

  selectedApplicantUserIdFromQrCode: number | undefined;

  selectedApplicantForCheckin: Application | undefined;

  hasPendingCheckIn: boolean = false;

  checkInCheckDate: Date = new Date();

  showCheckInModal: boolean = false;

  showCheckOutModal: boolean = false;

  isForCheckIn: boolean = true;

  saveTimeSlotInProgress: boolean = false;

  finalizedApplicationUsers: Application[] | undefined;

  isTodayASelectableDay: boolean = false;

  currentUserHasAdminPrivileges: boolean = false;

  constructor(public data: DataService, public auth: AuthService) {}

  ngOnInit(): void {
    if (this.application?.id!) {
      this.applicationId = this.application.id;
    }

    this.isInReview = this.inReview;

    console.log("this.applicationId: ", this.applicationId);

    this.initSignaturePad();
    this.initializeData();
  }

  async initializeData() {
    if (this.application?.id!) {
      this.applicationId = this.application.id;
    }
    await this.loadProject();
    this.currentUserHasAdminPrivileges = await this.data.hasAdminPriviliges(
      this.applicationId,
      this.projectId
    );
    await this.loadTimeSlots();
    await this.loadDocuments();
    this.updateIfTodayIsSelectableDay();
    // if teamTimeSlots are not set, then the user is either missing permission or there are none
    if (this.teamTimeSlots.length > 0) {
      // generate teamTimeSlotDays from timeSlots
      this.generateTeamTimeSlotDays();
      this.refreshCurrentVisibleTeamTimeSlots(0);
      this.loadSelectableDays();
      this.selectableDayClicked(0);
    }
    this.refreshOverallTimes();
    this.setQrCodeString();
  }

  /**
   * fills the qrCodeString with current projectId and the current user's id, the structure will be a json format.
   * The String format will look like:
   * ttConnectQrCheckinQrCode:{projectId:<projectId>,userId:<userId>}
   */
  setQrCodeString() {
    this.liveCheckinQrCodeString = `{"ttConnectQrCheckinQrCode":{"projectId":"${this.projectId}","userId":"${this.auth.currentUser?.id}"}}`;
  }

  /**
   * newQrCodeScanned gets triggered when the qrScanner detects a new qrCode, checks if it is for
   * checkIn or checkOut and opens the appropriate manual checkIn or checkOut modal, with a preselected user.
   * @param qrCodeData CheckInQrCodeResult
   */
  async newQrCodeScanned(qrCodeData: CheckInQrCodeResult) {
    console.log("newQrCodeScanned qrCodeData: ", qrCodeData);
    this.selectedApplicantUserIdFromQrCode = Number(
      qrCodeData.ttConnectQrCheckinQrCode.userId
    );
    if (this.isForCheckIn) {
      this.openCheckInModal();
    } else {
      this.openCheckOutModal();
    }
    this.showQrScannerModal = false;
  }

  async loadProject() {
    console.log("this.projectId: ", this.projectId);
    this.project = await this.data.getProjectById(
      this.projectId!,
      this.managerView
    );
    if (this.project) {
      let tmpDate = new Date(this.project?.zeitraum_von);
      tmpDate.setHours(0, 0, 0, 0);
      this.possibleDaysFromTimestamp = tmpDate.getTime();
      tmpDate = new Date(this.project?.zeitraum_bis);
      tmpDate.setHours(23, 59, 59, 999);
      this.possibleDaysToTimestamp = tmpDate.getTime();
      const diffTime =
        this.possibleDaysToTimestamp - this.possibleDaysFromTimestamp;
      this.possibleDaysAmount = Math.floor(diffTime / 1000 / 60 / 60 / 24 + 1);
    }
  }

  initSignaturePad() {
    // init signaturepad
    const minWidth = 290;
    const maxWidth = 325;
    const heightRatio = 0.4138;
    const incomingWidth = Math.min(window.screen.width - 30, maxWidth);
    const width = Math.max(minWidth, incomingWidth);
    const height = Math.round(width * heightRatio);
    this.signaturePadOpts = {
      minWidth: 1,
      canvasWidth: width,
      canvasHeight: height,
      backgroundColor: "#fff",
      penColor: "#4287f5",
    };
  }

  signatureDrawStart() {
    console.log("signatureDrawStart");
  }

  signatureDrawFinish() {
    console.log("signatureDrawFinish");
    this.signatureBase64 = this.signaturePad.toDataURL();
  }

  signatureClear() {
    this.signaturePad.clear();
    this.signatureBase64 = "";
  }

  /**
   * Checks if either current user does not have the role "regular"
   * or if the application role is "teamleiter_vor_ort" or "additional_teamleads"
   * @returns boolean if current user is teamlead
   */
  isCurrentUserTeamlead() {
    return (
      this.auth.currentUser?.role !== "regular" ||
      this.application?.role === "teamleiter_vor_ort" ||
      this.application?.role === "additional_teamleads"
    );
  }

  /**
   * openCheckInModal opens the popover for checkin or checkout depending on the given parameter.
   */
  async openCheckInModal() {
    console.log("openCheckInModal");
    this.loadSelectableDays(
      dayjs().tz("Europe/Berlin").startOf("day").valueOf()
    );
    // load all applications of project to fill the selectable data
    if (!this.finalizedApplicationUsers) {
      await this.loadAllFinalizedApplications();
    }
    // select the user from qrCodeData
    this.updateSelectApplicantForCheckin();

    this.hasPendingCheckIn = this.doesCheckinExistForSelectedApplicant();
    if (this.hasPendingCheckIn) {
      this.updateCheckInCheckDate();
      this.initCheckoutModalValues(this.checkInCheckDate);
    }

    // set checkDate to the next quarter of an hour or the last quarter of an hour depending which one is closer
    this.updateCheckInCheckDate();
    // reset timeToInputValue
    this.timeToInputValue = "";
    const newTimeSlot: TimetrackerSlot = {
      time_from: this.checkInCheckDate.getTime(),
      time_break: 0,
    };
    this.addTimeValues = newTimeSlot;
    this.timeFromInputValue = this.transformDateTimeToInputStringValue(
      this.checkInCheckDate
    );
    this.showCheckInModal = true;
  }

  /**
   * openCheckOutModal
   * @param timeSlot
   */
  async openCheckOutModal(timeSlot?: TimetrackerSlot) {
    console.log("openCheckOutModal");
    // load all applications of project to fill the selectable data
    if (!this.finalizedApplicationUsers) {
      await this.loadAllFinalizedApplications();
    }
    // select the user from qrCodeData
    this.updateSelectApplicantForCheckin(timeSlot);

    this.hasPendingCheckIn = this.doesCheckinExistForSelectedApplicant();
    if (this.hasPendingCheckIn) {
      this.updateCheckInCheckDate();
      this.initCheckoutModalValues(this.checkInCheckDate);
    }

    // set checkDate to the next quarter of an hour or the last quarter of an hour depending which one is closer
    this.updateCheckInCheckDate();
    if (this.selectedApplicantForCheckin) {
      this.initCheckoutModalValues(this.checkInCheckDate);
    }
    this.showCheckOutModal = true;
  }

  /**
   * selfCheckIn is a wrapper function to preselect the current user for checkin and then open the checkin modal.
   */
  async selfCheckIn() {
    this.selectedApplicantUserIdFromQrCode = this.auth.currentUser?.id;
    await this.openCheckInModal();
  }

  /**
   * selfCheckOut is a wrapper function to preselect the current user for checkout and then open the checkout modal.
   */
  async selfCheckOut() {
    this.selectedApplicantUserIdFromQrCode = this.auth.currentUser?.id;
    await this.openCheckOutModal();
  }

  updateSelectApplicantForCheckin(timeSlot?: TimetrackerSlot) {
    if (this.selectedApplicantUserIdFromQrCode) {
      this.selectedApplicantForCheckin = this.finalizedApplicationUsers?.find(
        (application) => {
          return application.user_id === this.selectedApplicantUserIdFromQrCode;
        }
      );
    } else if (timeSlot?.application_id) {
      this.selectedApplicantForCheckin = this.finalizedApplicationUsers?.find(
        (application) => {
          return application.id === timeSlot?.application_id;
        }
      );
    }
  }

  /**
   * initCheckoutModalValues will initialize the values of the checkout modal.
   */
  initCheckoutModalValues(checkOutDateTime: Date) {
    // check if applicant is selected
    if (this.selectedApplicantForCheckin) {
      // get latest timeslot of applicant
      this.teamTimeSlots.forEach((tTSlot) => {
        if (
          tTSlot.live_checkin &&
          !tTSlot.time_to &&
          tTSlot.application_id === this.selectedApplicantForCheckin?.id
        ) {
          console.log("found Open timeslot: ", tTSlot);
          // init addTimeValues
          this.addTimeValues = { ...tTSlot };
          this.addTimeValues.time_break = 0;
          this.addTimeValues.time_to = checkOutDateTime.getTime();
          // set time input values
          this.timeFromInputValue = this.transformDateTimeToInputStringValue(
            new Date(this.addTimeValues.time_from)
          );
          this.timeToInputValue =
            this.transformDateTimeToInputStringValue(checkOutDateTime);
          // determine break times
          this.breakHoursInputValue = 0;
          this.breakMinutesInputValue = 0;
          this.updateMinBreakValue();
        }
      });
    }
  }

  /**
   * transformDateTimeToInputStringValue will transform a given date to a string value for the time input fields.
   */
  transformDateTimeToInputStringValue(date: Date) {
    const tmpHours = date.getHours().toString().padStart(2, "0");
    const tmpMinutes = this.correctMinutesToNearestQuarter(date.getMinutes())
      .toString()
      .padStart(2, "0");
    return `${tmpHours}:${tmpMinutes}`;
  }

  /**
   * correctMinutesToNearestQuarter takes an integer values for minutes and corrects
   * the value to the nearest quarter of an hour.
   * @param minutes int value of minues 0-60
   * @returns 0, 15, 30 or 45 whatever is closest to given minute value
   */
  correctMinutesToNearestQuarter(minutes: number) {
    if (minutes < 8) {
      return 0;
    }
    if (minutes < 23) {
      return 15;
    }
    if (minutes < 38) {
      return 30;
    }
    if (minutes < 53) {
      return 45;
    }
    return 0;
  }

  /**
   * updateCheckInCheckDate will set the checkInCheckDate to the next quarter
   * of an hour or the last quarter of an hour depending which one is closer.
   */
  updateCheckInCheckDate() {
    this.checkInCheckDate = dayjs().tz("Europe/Berlin").toDate(); // "2024/09/01T00:30" / "2024/08/30T23:00"
    const correctedMinutes = this.correctMinutesToNearestQuarter(
      this.checkInCheckDate.getMinutes()
    );
    this.checkInCheckDate.setMinutes(correctedMinutes);
  }

  /**
   * selectedApplicantForCheckOutChanged will fetch the timeslot for the new selected applicant
   * and updates the form values of the modal, also it will update the time_to value to current time
   * and the time_break value depending on how much time has passed since the checkin.
   * @param selectedApplicant
   */
  selectedApplicantForCheckOutChanged(selectedApplicant: Application) {
    console.log(
      "selectedApplicantForCheckOutChanged triggered with selectedApplicant: ",
      selectedApplicant
    );

    this.hasPendingCheckIn = this.doesCheckinExistForSelectedApplicant();
    if (this.hasPendingCheckIn) {
      this.updateCheckInCheckDate();
      this.initCheckoutModalValues(this.checkInCheckDate);
    }
  }

  doesCheckinExistForSelectedApplicant() {
    // get latest timeslot of applicant
    let foundTimeLost = false;
    this.teamTimeSlots.forEach((tTSlot) => {
      if (
        tTSlot.live_checkin &&
        !tTSlot.time_to &&
        tTSlot.application_id === this.selectedApplicantForCheckin?.id
      ) {
        console.log("found Open timeslot: ", tTSlot);
        foundTimeLost = true;
      }
    });
    return foundTimeLost;
  }

  /**
   * selectedApplicantForCheckInChanged will check if an open checkin timeslost already exists
   * for the selected applicant and if so, it will set the variable hasPendingCheckIn to true.
   * @param selectedApplicant
   */
  selectedApplicantForCheckInChanged(selectedApplicant: Application) {
    console.log(
      "selectedApplicantForCheckInChanged selectedApplicant: ",
      selectedApplicant
    );
    // get latest timeslot of applicant
    // check if applicant already has an pending checkin
    let foundTimeSlot = false;
    this.teamTimeSlots.forEach((tTSlot) => {
      if (
        tTSlot.live_checkin &&
        !tTSlot.time_to &&
        tTSlot.application_id === this.selectedApplicantForCheckin?.id
      ) {
        console.log("found Open timeslot: ", tTSlot);
        foundTimeSlot = true;
      }
    });

    this.hasPendingCheckIn = foundTimeSlot;
  }

  checkInStartTimeChanged(changeEvent: any) {
    console.log("changeEvent: ", changeEvent);
    this.refreshAddTimeValues();
  }

  /**
   * closeCheckinModal and resets the scanned userId and the selected applicant.
   */
  closeCheckInModal() {
    this.showCheckInModal = false;
    this.addTimeValues.time_to = undefined;
    this.addTimeValues.time_break = undefined;
    this.selectedApplicantUserIdFromQrCode = undefined;
    this.selectedApplicantForCheckin = undefined;
    this.hasPendingCheckIn = false;
  }

  /**
   * closeCheckOutModal and resets the scanned userId and the selected applicant.
   */
  closeCheckOutModal() {
    this.showCheckOutModal = false;
    this.addTimeValues.time_to = undefined;
    this.addTimeValues.time_break = undefined;
    this.selectedApplicantUserIdFromQrCode = undefined;
    this.selectedApplicantForCheckin = undefined;
    this.hasPendingCheckIn = false;
  }

  /**
   * saveCheckIn will create a new timeSlot for the selected user and the selected time.
   * It will create an entry with then new field isCheckIn so it is possible to filter the timeSlots.
   * Because we only save a checkin, we will set specific values for time_to and time_break.
   */
  async saveCheckIn() {
    if (!this.saveTimeSlotInProgress && this.selectedApplicantForCheckin) {
      this.saveTimeSlotInProgress = true;
      const newTimeSlot: TimetrackerSlot = {
        time_from: this.addTimeValues.time_from,
        time_break: 0,
        application_id: this.selectedApplicantForCheckin.id,
        live_checkin: true,
      };
      this.applicationId = this.selectedApplicantForCheckin.id;
      await this.addTimeSlot(newTimeSlot, this.selectedApplicantForCheckin);
      this.closeCheckInModal();
      this.saveTimeSlotInProgress = false;
    }
  }

  /**
   * saveCheckOut will update the existing timeSlot and finalize it's dataset by setting
   * time_to and time_break values.
   */
  async saveCheckOut() {
    console.log("saveCheckOut triggered");
    if (!this.saveTimeSlotInProgress) {
      this.saveTimeSlotInProgress = true;
      await this.modalUpdateTimeClicked(null);
      this.saveTimeSlotInProgress = false;
      this.closeCheckOutModal();
    }
  }

  /**
   * openAddTimeForTopteamerOverlay opens the overlay for adding time for a topteamer.
   */
  async openAddTimeForTopteamerOverlay() {
    // initialize finalizedApplicationUsers
    await this.loadAllFinalizedApplications();
    this.addTimeModalEditBySupervisor = true;
    this.showAddTimeForTopteamerOverlay = true;
  }

  /**
   * selectedApplicantForAddTimeChanged needs to reload the selectable days
   * and the timeSlots of the selected applicant.
   * @param $event
   */
  async selectedApplicantForAddTimeChanged(selectedApplicant: Application) {
    console.log(
      "selectedApplicantForAddTimeChanged selectedApplicant: ",
      selectedApplicant
    );
    this.isForCheckIn = false;
    this.saveTimeSlotInProgress = true;
    this.addTimeValues.application_id = selectedApplicant.id;
    this.applicationId = selectedApplicant.id;
    await this.loadTimeSlots();
    this.refreshAddTimeValues();

    const tmpDate = dayjs().tz("Europe/Berlin").startOf("day").toDate();

    // reset selectableDays
    this.loadSelectableDays(tmpDate.getTime());
    this.saveTimeSlotInProgress = false;
    this.scrollToNearestTimeSlot();
  }

  /**
   * saveNewTimeSlotForTopteamer validates if all values for new timeSlot
   * are set and an applicant got selected in the dropdown.
   * Then it will create a new timeSlot for the selected user and the selected time.
   */
  async saveNewTimeSlotForTopteamer() {
    // validate addTimeValues
    if (
      this.saveTimeSlotInProgress ||
      !this.addTimeValues.time_from ||
      !this.addTimeValues.time_to
    ) {
      return;
    }
    if (this.selectedApplicantForCheckin) {
      this.saveTimeSlotInProgress = true;
      this.applicationId = this.selectedApplicantForCheckin.id;
      this.addTimeValues.edited_by_admin = true;
      await this.addTimeSlot(this.addTimeValues);
      this.saveTimeSlotInProgress = false;
      this.closeAddTimeForTopteamerOverlay();
    }
  }

  /**
   * Closes the overlay for adding time for a topteamer.
   */
  closeAddTimeForTopteamerOverlay() {
    this.selectedApplicantForCheckin = undefined;
    this.addTimeModalEditBySupervisor = false;
    this.showAddTimeForTopteamerOverlay = false;
    this.timeToInputValue = "";
    this.timeFromInputValue = "";
    this.fakeTimeFromInputValue = "";
    this.fakeTimeToInputValue = "";
    this.addTimeValues = {
      time_from: 0,
      time_to: 0,
      time_break: 0,
    };
  }

  /**
   * Opens the QR Scanner overlay and determines if it is for checkin or checkout depending on the given parameter.
   * @param forCheckIn
   */
  async openQrScannerOverlay(forCheckIn: boolean = true) {
    console.log("openQrScannerOverlay forCheckIn: ", forCheckIn);
    this.isForCheckIn = forCheckIn;
    this.showQrScannerModal = true;
  }

  async loadDocuments() {
    this.documents = await this.data.getDocumentsOfProject(this.projectId!);
    console.log("this.documents: ", this.documents);
  }

  modalAddTimeClicked(e: any) {
    console.log("modalAddTimeClicked e: ", e);
    if (this.selectableDays.filter((el) => el.selected).length == 0) {
      Notify.failure("Es muss ein Tag ausgewählt werden.");
      return;
    }
    if (
      this.addTimeValues.time_from &&
      (this.addTimeValues.time_to || this.addTimeValues.live_checkin)
    ) {
      this.addTimeSlot(this.addTimeValues);
      this.closeModalClicked();
    } else {
      Notify.failure("Es muss die vollständige Einsatzzeit angegeben werden.");
    }
  }

  modalTimeFakeCheckboxChange(e: any) {
    console.log("modalTimeFakeCheckboxChange", e.target.checked);
    if (e.target.checked) {
      this.addTimeValues.fake_time_from = this.addTimeValues.time_from;
      this.addTimeValues.fake_time_to = this.addTimeValues.time_to;
      this.addTimeValues.fake_time_break = this.addTimeValues.time_break;
    } else {
      this.addTimeValues.fake_time_from = null;
      this.addTimeValues.fake_time_to = null;
      this.addTimeValues.fake_time_break = null;
    }
    this.initAddTimeValues(false);
  }

  loadTimesOfApplicationId(applicationId: number, excludeTimeSlotId?: number) {
    this.extraTimesList = [];
    this.teamTimeSlots.forEach((tTSlot) => {
      if (tTSlot.application_id && tTSlot.application_id === applicationId) {
        if (!excludeTimeSlotId || excludeTimeSlotId !== tTSlot.id) {
          this.extraTimesList.push(tTSlot);
        }
      }
    });
    this.extraTimesList.sort((a, b) => {
      if (a.time_from < b.time_from) return 1;
      return a.time_to == b.time_from ? 0 : -1;
    });
    console.log("this.extraTimesList: ", this.extraTimesList);
  }

  async modalUpdateTimeClicked(e: any) {
    console.log("modalUpdateTimeClicked e: ", e);
    console.log(
      "modalUpdateTimeClicked, this.addTimeValues: ",
      this.addTimeValues
    );
    // update time
    if (this.addTimeValues.id) {
      // check if timeslot has already been exported in the meantime
      const timeSlotObject = await this.data.getTimeSlot(this.addTimeValues.id);
      console.log("modalUpdateTimeClicked, timeSlotObject: ", timeSlotObject);
      if (this.isTimeslotEditable(timeSlotObject)) {
        try {
          const updatedTimeSlot = await this.data.updateTimeSlot(
            this.addTimeValues.id,
            this.addTimeValues,
            this.projectId!
          );
          console.log("updatedTimeSlot", updatedTimeSlot);
          this.updateTimeslotLocally(updatedTimeSlot);
          await this.initializeData();
          this.closeModalClicked();
        } catch (updateTimeSlotError) {
          Notify.failure("Update error");
          console.error("updateTimeSlotError: ", updateTimeSlotError);
        }
      } else {
        Notify.failure("Zeiten können nicht mehr geändert werden");
        await this.initializeData();
        this.closeModalClicked();
      }
    } else {
      this.closeModalClicked();
    }
  }

  modalDeleteTimeClicked(e: any) {
    console.log("modalDeleteTimeClicked e: ", e);

    Confirm.show(
      "Zeiteintrag wirklich löschen?",
      "Dieser Vorgang kann nicht rückgängig gemacht werden und wird im Protokoll festgehalten.",
      "Nein",
      "Ja",
      () => {},
      () => {
        // delete time
        if (this.addTimeValues.id && this.addTimeValues.application_id)
          this.data
            .deleteTimeSlot(
              this.addTimeValues.id,
              this.addTimeValues.application_id
            )
            .then(() => {
              this.removeDeletedTimeSlotsLocally(this.addTimeValues.id!);
              this.closeModalClicked();
            })
            .catch((err) => {
              Notify.failure("Some error happened");
              console.error("deleteTimeSlot error: ", err);
            });
      },
      {
        messageMaxLength: 1000000,
        plainText: false,
      } as IConfirmOptions
    );
  }

  /**
   * Shows popover with warning, that times can not be changed after sending the signature
   * @returns boolean true if user wants to send signature and times, false if not
   */
  showConfirmSendSignature(): Promise<boolean> {
    return new Promise((resolve: any) => {
      Confirm.show(
        "Achtung",
        "Sobald du die Zeiten abgeschickt hast, können keine weiteren Zeiten für diesen Tag mehr erfasst werden.",
        "Abbrechen",
        "Zeiten abschicken",
        () => {
          // no
          resolve(false);
        },
        () => {
          // yes
          resolve(true);
        },
        {}
      );
    });
  }

  async modalSignatureSendButtonClicked(submitEvent: any) {
    console.log("modalSignatureSendButtonClicked event:", submitEvent);
    // validate inputs:
    if (!this.customerSignModalEmail || !this.signatureBase64) {
      Notify.failure(
        "Es muss eine E-Mail und eine Unterschrift angegeben werden."
      );
      return;
    }
    // show popover with warning, that times can not be changed after sending the signature
    let sendSignatureAndTimes: boolean = true;
    // check if current user is admin, if so, skip the warning
    if (this.auth.currentUser?.role === "regular") {
      sendSignatureAndTimes = await this.showConfirmSendSignature();
    }
    if (sendSignatureAndTimes) {
      // save in db
      this.isUploading = true;
      try {
        const newDocument: Document = {
          base64_signature: this.signatureBase64,
          contact_email: this.customerSignModalEmail,
          timestamp:
            this.teamTimeSlotDays[this.currentActiveTeamTimeSlotDayIndex]
              .startTimestamp,
          project_id: this.projectId!,
        };

        const body = {
          ...newDocument,
          ...(this.applicationId ? { application_id: this.applicationId } : {}),
          project_id: this.projectId,
        };

        // create the document
        const response = await this.data.postRequest(
          "/project/documents",
          body
        );

        console.log("createDocument response: ", response);
        this.teamTimeSlotDays[
          this.currentActiveTeamTimeSlotDayIndex
        ].document_ids.push(response.id!);
        this.isExportAllVisible = true;
        console.log(
          "timeslot: ",
          this.teamTimeSlotDays[this.currentActiveTeamTimeSlotDayIndex]
        );
        // send time overview
        await this.sendTimeOverview(
          this.teamTimeSlotDays[this.currentActiveTeamTimeSlotDayIndex]
        );
        await this.initializeData();
        this.customerSignModalVisible = false;
      } catch (error) {
        Notify.failure("Etwas ist schief gelaufen.s");
        console.error("createDocument error: ", error);
      }
      this.isUploading = false;
    }
  }

  updateTimeslotLocally(updatedTimeslot: TimetrackerSlot) {
    // check myTimeSlots
    this.myTimeSlots.forEach((timeSlot, index) => {
      if (timeSlot.id === updatedTimeslot.id) {
        Object.assign(this.myTimeSlots[index], updatedTimeslot);
      }
    });
    // check teamTimeSlots
    this.teamTimeSlots.forEach((timeSlot, index) => {
      if (timeSlot.id === updatedTimeslot.id) {
        this.teamTimeSlots[index] = Object.assign(
          this.teamTimeSlots[index],
          updatedTimeslot
        );
      }
    });
    // check currentVisibleTeamTimeSlots
    this.currentVisibleTeamTimeSlots.forEach((timeSlot, index) => {
      if (timeSlot.id === updatedTimeslot.id) {
        this.currentVisibleTeamTimeSlots[index] = Object.assign(
          this.currentVisibleTeamTimeSlots[index],
          updatedTimeslot
        );
      }
    });
    this.generateTeamTimeSlotDays();
    this.refreshCurrentVisibleTeamTimeSlots(
      this.currentActiveTeamTimeSlotDayIndex
    );
  }

  removeDeletedTimeSlotsLocally(timeSlotId: number) {
    let deleted = false;
    this.myTimeSlots.forEach((tSlot, i) => {
      if (!deleted && tSlot.id === timeSlotId) {
        this.myTimeSlots.splice(i, 1);
        deleted = true;
      }
    });
    deleted = false;
    this.teamTimeSlots.forEach((tSlot, i) => {
      if (!deleted && tSlot.id === timeSlotId) {
        this.teamTimeSlots.splice(i, 1);
        deleted = true;
      }
    });
    deleted = false;
    this.currentVisibleTeamTimeSlots.forEach((tSlot, i) => {
      if (!deleted && tSlot.id === timeSlotId) {
        this.currentVisibleTeamTimeSlots.splice(i, 1);
        deleted = true;
      }
    });
    this.generateTeamTimeSlotDays();
    this.refreshCurrentVisibleTeamTimeSlots(
      this.currentActiveTeamTimeSlotDayIndex
    );
  }

  async addTimeSlot(
    timeSlot: TimetrackerSlot,
    liveCheckinApplicant?: Application
  ) {
    if (this.applicationId) {
      console.log("addTimeSlot this.applicationId: ", this.applicationId);
      // add timeslot to db
      if (liveCheckinApplicant) {
        try {
          const response = await this.data.createCheckInTimeSlot(
            timeSlot,
            liveCheckinApplicant.id!,
            this.projectId!
          );

          console.log("createCheckInTimeSlot response: ", response);
          await this.initializeData();
        } catch (createCheckInTimeSlotError) {
          Notify.failure("createCheckInTimeSlotError");
          console.error(
            "createCheckInTimeSlotError: ",
            createCheckInTimeSlotError
          );
        }
      } else {
        try {
          const response = await this.data.createTimeSlot(
            timeSlot,
            this.applicationId
          );
          console.log("createTimeSlot response: ", response);
          await this.initializeData();
        } catch (createTimeSlotError) {
          Notify.failure("createTimeSlotError");
          console.error("createTimeSlotError: ", createTimeSlotError);
        }
      }
      this.refreshOverallTimes();
    }
  }

  async loadTimeSlots() {
    this.endOfFestgeschriebenMonthTimestamp = await this.data.getRequest(
      "/datev-export/getEndOfFestgeschriebenMonthTimestamp"
    );
    if (this.applicationId) {
      // personal timeslots
      const response = await this.data.getTimeSlotsByApplication(
        this.applicationId
      );
      this.myTimeSlots = response;

      if (this.endOfFestgeschriebenMonthTimestamp) {
        this.myTimeSlots.forEach((timeSlot, index) => {
          if (timeSlot.time_from < this.endOfFestgeschriebenMonthTimestamp) {
            this.myTimeSlots[index].is_already_established_in_datev_export =
              true;
          }
        });
      }
      console.log("this.myTimeSlots: ", this.myTimeSlots);
      this.myTimeSlots.sort((a, b) => {
        if (a.time_from < b.time_from) return 1;
        return a.time_to == b.time_from ? 0 : -1;
      });
      console.log(`this.myTimeSlots`, this.myTimeSlots);
    }

    // team timeslots:
    this.teamTimeSlots = [];
    if (
      this.projectId &&
      (this.auth.currentUser?.role != "regular" ||
        this.application?.role == "teamleiter_vor_ort" ||
        this.application?.role == "additional_teamleads" ||
        this.project?.jeder_mitarbeiter_darf_unterschrift_von_kunden_einholen)
    ) {
      try {
        const applicationsWithTimes: Application[] =
          await this.data.postRequest(
            `/project/applications/${this.projectId}`,
            {
              includeTimeslots: true,
            }
          );
        console.log("applicationsWithTimes: ", applicationsWithTimes);
        if (applicationsWithTimes) {
          applicationsWithTimes.forEach((appWTimes) => {
            if (appWTimes.timeslots) {
              appWTimes.timeslots.forEach((timeSlot) => {
                const tmpTimeslot = { ...timeSlot };

                if (this.endOfFestgeschriebenMonthTimestamp) {
                  if (
                    tmpTimeslot.time_from <
                    this.endOfFestgeschriebenMonthTimestamp
                  ) {
                    tmpTimeslot.is_already_established_in_datev_export = true;
                  }
                }

                if (appWTimes.user?.profile) {
                  tmpTimeslot.title = `${appWTimes.user.profile.vorname} ${appWTimes.user.profile.nachname}`;
                  tmpTimeslot.vorname = appWTimes.user.profile.vorname;
                  tmpTimeslot.nachname = appWTimes.user.profile.nachname;
                  this.teamTimeSlots.push(tmpTimeslot);
                }
              });
            }
          });
        }
        console.log("this.teamTimeSlots: ", this.teamTimeSlots);
      } catch (err) {
        console.log("getApplicationsOfProject error: ", err);
      }
    }
  }

  sortTeamTimeSlots = (a: TimetrackerSlot, b: TimetrackerSlot) => {
    const nameComp = (a.title || "").localeCompare(b.title || "");
    if (nameComp === 0) {
      if (a.time_from < b.time_from) return 1;
      return a.time_to == b.time_from ? 0 : -1;
    }
    return nameComp;
  };

  async loadAllFinalizedApplications() {
    this.finalizedApplicationUsers = await this.data.getAllAcceptedApplicants(
      this.applicationId!,
      this.projectId!
    );
  }

  generateTeamTimeSlotDays() {
    this.teamTimeSlotDays = [];
    console.log(`teamTimeSlots`, this.teamTimeSlots);
    this.teamTimeSlots.forEach((tTSlot) => {
      const tmpDate = dayjs(tTSlot.time_from).tz("Europe/Berlin");
      const startTimestamp = tmpDate.startOf("day").valueOf();
      const endTimestamp = tmpDate.endOf("day").valueOf();
      const tmpDay: TeamTimeSlotDay = {
        weekday: tmpDate.toDate().toLocaleDateString("de", { weekday: "long" }),

        dateShort: tmpDate.toDate().toLocaleDateString("de", {
          month: "2-digit",
          day: "2-digit",
        }),

        dateLong: tmpDate.toDate().toLocaleDateString("de", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),

        startTimestamp,

        endTimestamp,

        overallTime: 0,

        collapsed: false,

        timeSlots: [tTSlot],

        document_ids: [],

        completeTimeslots: 0,

        openCheckouts: 0,
      };
      if (this.documents) {
        this.documents.forEach((document) => {
          if (document.timestamp === tmpDay.startTimestamp) {
            tmpDay.document_ids.push(document.id!);
            // tmpDay.collapsed = true;
            this.isExportAllVisible = true;
          }
        });
        // make unique
        tmpDay.document_ids = tmpDay.document_ids.filter(
          (value, index, self) => {
            return self.indexOf(value) === index;
          }
        );
      }
      const index = this.teamTimeSlotDays.findIndex((ttsd) => {
        return ttsd.startTimestamp == startTimestamp;
      });
      if (index > -1) {
        this.teamTimeSlotDays[index].timeSlots?.push(tTSlot);
      } else {
        this.teamTimeSlotDays.push(tmpDay);
      }
    });
    this.teamTimeSlotDays.forEach((teamTimeSlotDay, index) => {
      const overViewCounts =
        this.countCheckinsOfTeamTimeSlotDay(teamTimeSlotDay);
      this.teamTimeSlotDays[index].completeTimeslots =
        overViewCounts.completeTimeslots;
      this.teamTimeSlotDays[index].openCheckouts = overViewCounts.openCheckouts;
      this.teamTimeSlotDays[index].timeSlots?.sort(this.sortTeamTimeSlots);
    });
    this.teamTimeSlotDays.sort((a, b) => {
      return a.startTimestamp > b.startTimestamp ? -1 : 1;
    });
    console.log("this.teamTimeSlotDays: ", this.teamTimeSlotDays);
  }

  /**
   * countCheckinsOfTeamTimeSlotDay checks all timeSlots of a teamTimeSlotDay and counts the complete timeslots
   * and the open checkouts.
   * @param teamTimeSlotDay
   */
  countCheckinsOfTeamTimeSlotDay(teamTimeSlotDay: TeamTimeSlotDay) {
    const result = {
      completeTimeslots: 0,
      openCheckouts: 0,
    };
    teamTimeSlotDay.timeSlots?.forEach((timeSlot) => {
      if (timeSlot.time_to) {
        result.completeTimeslots += 1;
      } else {
        result.openCheckouts += 1;
      }
    });
    return result;
  }

  debugGenerateTeamTimeslots() {
    this.teamTimeSlots = [];
    this.teamTimeSlots.push({
      id: 0,
      vorname: "Jerome",
      nachname: "Bell",
      title: "Jerome Bell",
      time_from: 1659934800000,
      time_to: 1659982500000,
      time_break: 3600000,
    });
    this.teamTimeSlots.push({
      id: 2,
      title: "Pascal Power",
      vorname: "Pascal",
      nachname: "Power",
      time_from: 1659934800000,
      time_to: 1659982500000,
      time_break: 0,
    });
    this.teamTimeSlots.push({
      id: 3,
      title: "Pascal Power",
      vorname: "Pascal",
      nachname: "Power",
      time_from: 1660035600000,
      time_to: 1660064400000,
      time_break: 0,
    });
    this.teamTimeSlots.push({
      id: 4,
      vorname: "Stephan",
      nachname: "Joghurt",
      title: "Stephan Joghurt",
      time_from: 1660060800000,
      time_to: 1660089600000,
      time_break: 0,
    });
    this.teamTimeSlots.sort(this.sortTeamTimeSlots);
  }

  refreshCurrentVisibleTeamTimeSlots(teamTimeSlotDayIndex: number) {
    // refresh currentVisibleTeamTimeSlots
    this.currentVisibleTeamTimeSlots = [];
    this.teamTimeSlots.forEach((timeSlot) => {
      if (
        this.teamTimeSlotDays[teamTimeSlotDayIndex].startTimestamp <
          timeSlot.time_from &&
        timeSlot.time_from <
          this.teamTimeSlotDays[teamTimeSlotDayIndex].endTimestamp
      ) {
        this.currentVisibleTeamTimeSlots.push({ ...timeSlot });
      }
    });
    this.currentVisibleTeamTimeSlots.sort(this.sortTeamTimeSlots);
    this.currentActiveTeamTimeSlotDayIndex = teamTimeSlotDayIndex;
    this.refreshOverallTimes();
  }

  timeSlotDaySwitchClicked(next: boolean) {
    console.log("timeSlotDaySwitchClicked next", next);
    let tmpNewIndex = this.currentActiveTeamTimeSlotDayIndex + 1;
    if (!next) {
      tmpNewIndex = this.currentActiveTeamTimeSlotDayIndex - 1;
    }
    if (tmpNewIndex > this.teamTimeSlotDays.length - 1) {
      tmpNewIndex = 0;
    } else if (tmpNewIndex < 0) {
      tmpNewIndex = this.teamTimeSlotDays.length - 1;
    }
    this.refreshCurrentVisibleTeamTimeSlots(tmpNewIndex);
  }

  refreshOverallTimes() {
    console.log("refreshOverallTimes called");
    this.myTimeOverall = 0;
    this.myTimeSlots.forEach((timeSlot) => {
      if (
        timeSlot.time_to &&
        (timeSlot.time_break || timeSlot.time_break === 0)
      ) {
        this.myTimeOverall += Math.max(
          0,
          timeSlot.time_to - timeSlot.time_from - timeSlot.time_break
        );
      }
    });
    console.log("this.myTimeOverall: ", this.myTimeOverall);

    this.teamTimeOverall = 0;
    this.currentVisibleTeamTimeSlots.forEach((timeSlot) => {
      if (
        timeSlot.fake_time_from &&
        timeSlot.fake_time_to &&
        (timeSlot.fake_time_break || timeSlot.fake_time_break === 0)
      ) {
        this.teamTimeOverall += Math.max(
          0,
          timeSlot.fake_time_to - timeSlot.fake_time_from
        );
      } else if (
        timeSlot.time_to &&
        (timeSlot.time_break || timeSlot.time_break === 0)
      ) {
        this.teamTimeOverall += Math.max(
          0,
          timeSlot.time_to - timeSlot.time_from
        );
      }
    });

    this.formattedTeamTimeOverall = `${
      this.splitMilliseconds(this.teamTimeOverall).formatted
    } h`;

    this.teamTimeSlotDays.forEach((tTSDay, tTSDIndex) => {
      this.teamTimeSlotDays[tTSDIndex].overallTime = 0;
      if (tTSDay.timeSlots) {
        tTSDay.timeSlots.forEach((tTSlot) => {
          if (
            tTSlot.fake_time_from &&
            tTSlot.fake_time_to &&
            (tTSlot.fake_time_break || tTSlot.fake_time_break === 0)
          ) {
            this.teamTimeSlotDays[tTSDIndex].overallTime += Math.max(
              0,
              tTSlot.fake_time_to - tTSlot.fake_time_from
            );
          } else if (
            tTSlot.time_from &&
            tTSlot.time_to &&
            (tTSlot.time_break || tTSlot.time_break === 0)
          ) {
            this.teamTimeSlotDays[tTSDIndex].overallTime += Math.max(
              0,
              tTSlot.time_to - tTSlot.time_from
            );
          }
        });
      }
      // format Duration
      this.teamTimeSlotDays[tTSDIndex].overallTimeFormatted = `${
        this.splitMilliseconds(this.teamTimeSlotDays[tTSDIndex].overallTime)
          .formatted
      } h`;
    });

    // calculate project time overall:
    this.projectTimeOverall = 0;
    this.teamTimeSlots.forEach((timeSlot, tSIndex) => {
      if (
        timeSlot.time_to &&
        (timeSlot.time_break || timeSlot.time_break === 0)
      ) {
        let timeFrom = timeSlot.time_from;
        let timeTo = timeSlot.time_to;

        if (timeSlot.fake_time_from) timeFrom = timeSlot.fake_time_from;
        if (timeSlot.fake_time_to) timeTo = timeSlot.fake_time_to;

        const timeSlotDuration = Math.max(timeTo - timeFrom);

        this.projectTimeOverall += timeSlotDuration;
        if (this.managerView) {
          this.teamTimeSlots[tSIndex].formattedDuration =
            this.splitMilliseconds(timeSlotDuration).formatted;
          if (
            timeSlot.time_to &&
            (timeSlot.time_break || timeSlot.time_break === 0)
          ) {
            this.teamTimeSlots[tSIndex].formattedBreak = this.splitMilliseconds(
              timeSlot.fake_time_break || timeSlot.fake_time_break === 0
                ? timeSlot.fake_time_break
                : timeSlot.time_break
            ).formatted;
          }
        }
      }
    });

    this.formattedProjectTimeOverall = this.splitMilliseconds(
      this.projectTimeOverall
    ).formatted;
    console.log("this.projectTimeOverall: ", this.projectTimeOverall);
  }

  splitMilliseconds(milliseconds: number) {
    const tmpHours = Math.floor(milliseconds / 60 / 60 / 1000);
    const tmpMinutes = Math.floor(
      (milliseconds - tmpHours * 60 * 60 * 1000) / 60 / 1000
    );
    let minStr = tmpMinutes.toString();
    if (minStr.length === 1) {
      minStr = `0${minStr}`;
    }
    return {
      hours: tmpHours,
      minutes: tmpMinutes,
      formatted: `${tmpHours}:${minStr}`,
    };
  }

  loadSelectableDays(preSelectCorrectDayTime?: number) {
    this.selectableDays = [];
    // const tmpDate = new Date(this.possibleDaysFromTimestamp);
    // tmpDate in tz Europe/Berlin
    let tmpDayjs = dayjs(this.possibleDaysFromTimestamp)
      .tz("Europe/Berlin")
      .startOf("day");

    // tmpDate.setHours(0, 0, 0, 0);
    let weekdayShort = "";
    let dateShort = "";
    for (let i = 0; i < this.possibleDaysAmount; i++) {
      const tmpDate = tmpDayjs.tz("Europe/Berlin").startOf("day").toDate();
      weekdayShort = `${tmpDate.toLocaleDateString("de", {
        weekday: "short",
      })}.`;
      dateShort = tmpDate.toLocaleDateString("de", {
        month: "2-digit",
        day: "2-digit",
      });
      const newSelectableDay: SelectableDay = {
        time: tmpDate.getTime(),
        weekdayShort,
        dateShort,
        selected: false,
        selectable: true,
      };
      // determine if day is selectable or not
      this.myTimeSlots.forEach((mTimeSlot) => {
        const startOfTimeSlot = dayjs(mTimeSlot.time_from)
          .tz("Europe/Berlin")
          .startOf("day")
          .toDate();
        if (startOfTimeSlot.getTime() === newSelectableDay.time) {
          newSelectableDay.selectable = false;
        }
      });
      if (this.managerView || preSelectCorrectDayTime) {
        this.teamTimeSlots.forEach((tTimeSlot) => {
          const startOfTimeSlot = dayjs(tTimeSlot.time_from)
            .tz("Europe/Berlin")
            .startOf("day")
            .toDate();
          if (
            tTimeSlot.application_id === this.addTimeValues.application_id &&
            startOfTimeSlot.getTime() === newSelectableDay.time
          ) {
            newSelectableDay.selectable = false;
          }
        });
      }
      this.selectableDays.push(newSelectableDay);
      tmpDayjs = tmpDayjs.add(1, "day");
    }
    let dateAlreadySelected = false;
    this.selectableDays.forEach((sDay, index) => {
      if (!dateAlreadySelected) {
        if (!preSelectCorrectDayTime && sDay.selectable) {
          dateAlreadySelected = true;
          this.selectableDays[index].selected = true;
          this.selectableDays[index].selectable = true;
        } else if (preSelectCorrectDayTime) {
          if (sDay.time === preSelectCorrectDayTime) {
            dateAlreadySelected = true;
            this.updateDefaultTimesForSelectedDay(preSelectCorrectDayTime);
            this.selectableDays[index].selected = true;
            this.selectableDays[index].selectable = true;
            this.refreshAddTimeValues();
          }
        }
      }
    });

    this.selectableDays.forEach((sDay, index) => {
      const timeDateTS = dayjs(sDay.time).tz("Europe/Berlin");
      const festDateTS = dayjs(this.endOfFestgeschriebenMonthTimestamp).tz(
        "Europe/Berlin"
      );
      if (timeDateTS.isBefore(festDateTS, "day")) {
        this.selectableDays[index].selectable = false;
      }
    });
    console.log("this.selectableDays: ", this.selectableDays);
  }

  updateDefaultTimesForSelectedDay(selectedCorrectDayTime: number) {
    if (!this.addTimeValues.time_from && this.addTimeValues.time_from !== 0) {
      this.addTimeValues.time_from = dayjs(selectedCorrectDayTime)
        .tz("Europe/Berlin")
        .set("hour", 8)
        .valueOf();
    }
    this.timeFromInputValue = this.transformDateTimeToInputStringValue(
      new Date(this.addTimeValues.time_from)
    );
    if (!this.addTimeValues.time_to && this.addTimeValues.time_to !== 0) {
      this.addTimeValues.time_to = dayjs(selectedCorrectDayTime)
        .tz("Europe/Berlin")
        .set("hour", 16)
        .valueOf();
    }
    this.timeToInputValue = this.transformDateTimeToInputStringValue(
      new Date(this.addTimeValues.time_to)
    );
  }

  /**
   * checks if today is inside the project date range (zeitraum_von and zeitraum_bis)
   * and sets the isTodayASelectableDay flag
   */
  updateIfTodayIsSelectableDay() {
    // check if today is in range of selectable days
    this.isTodayASelectableDay = false;
    console.log("updateIfTodayIsSelectableDay, this.project: ", this.project);
    if (this.project?.zeitraum_von && this.project?.zeitraum_bis) {
      const timeDateFromTS = dayjs(this.project.zeitraum_von)
        .tz("Europe/Berlin")
        .valueOf();
      const timeDateToTS = dayjs(this.project.zeitraum_bis)
        .tz("Europe/Berlin")
        .valueOf();
      const now = dayjs().tz("Europe/Berlin").valueOf();
      if (timeDateFromTS <= now && now <= timeDateToTS) {
        this.isTodayASelectableDay = true;
      }
    }
  }

  getNextWeekday() {
    let foundIndex = 1;
    this.selectableDays.forEach((sDay, index) => {
      if (sDay.selected) {
        foundIndex = index + 1;
      }
    });
    if (foundIndex > this.selectableDays.length - 1) {
      foundIndex = 0;
    }
    return this.selectableDays[foundIndex].weekdayShort;
  }

  selectableDayClicked(index: number) {
    if (this.selectableDays[index].selectable) {
      this.selectableDays.forEach((sDay, sDayIndex) => {
        if (sDayIndex != index) {
          this.selectableDays[sDayIndex].selected = false;
        } else {
          this.selectableDays[sDayIndex].selected = true;
        }
      });
      this.refreshAddTimeValues();
    }
  }

  addTimeClicked() {
    console.log("addTimeClicked");
    this.loadSelectableDays();
    this.initAddTimeValues(true);
    this.updateMinBreakValue();
    this.addTimeModalVisible = true;
    this.scrollToNearestTimeSlot();
  }

  /**
   * updateMinBreakValue calculates and updates the current min break values
   */
  updateMinBreakValue() {
    // update min values
    if (this.addTimeValues.time_to) {
      const timeDurationInHours =
        Math.max(0, this.addTimeValues.time_to - this.addTimeValues.time_from) /
        3600 /
        1000;

      this.minBreakHoursValue = 0;
      this.minBreakMinutesValue = 0;

      console.log("timeDurationInHours: ", timeDurationInHours);

      // cutofs should be at 6h 7m 30s and 9h 7m 30s
      const one8thHour = 7.5 / 60;
      const cutof6Hours = 6 + one8thHour;
      const cutof9Hours = 9 + one8thHour;

      if (
        timeDurationInHours > cutof6Hours &&
        timeDurationInHours <= cutof9Hours
      ) {
        this.minBreakMinutesValue = 30;
        this.minBreakHoursValue = 0;
      }

      if (timeDurationInHours > cutof9Hours) {
        this.minBreakMinutesValue = 0;
        this.minBreakHoursValue = 1;
      }

      // check and update current values to meet minimum criteria
      if (this.breakMinutesInputValue < this.minBreakMinutesValue) {
        this.breakMinutesValueChanged(this.minBreakMinutesValue);
      }
      if (this.breakHoursInputValue < this.minBreakHoursValue) {
        this.breakHoursValueChanged(this.minBreakHoursValue);
      }
    }
  }

  scrollToNearestTimeSlot() {
    let index = 0;
    const date_now = new Date();
    for (let i = 0; i < this.selectableDays.length; i++) {
      if (this.selectableDays[i].time < date_now.getTime()) {
        index = i;
      }
    }
    setTimeout(() => {
      const element = document.getElementById(`day-pick-${index}`);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }, 500);
  }

  editTimeClicked(timeSlot: TimetrackerSlot) {
    console.log("editTimeClicked timeSlot: ", timeSlot);
    if (timeSlot.edited_by_admin) {
      return;
    }
    if (!this.isCurrentUserTeamlead() && timeSlot.live_checkin) {
      return;
    }
    if (this.isTimeslotEditable(timeSlot)) {
      this.addTimeValues = { ...timeSlot };
      const tmpDate = dayjs(timeSlot.time_from)
        .tz("Europe/Berlin")
        .startOf("day")
        .toDate();
      this.addTimeModalEditTime = true;
      this.initAddTimeValues();
      this.loadSelectableDays(tmpDate.getTime());
      this.addTimeModalVisible = true;
      this.scrollToNearestTimeSlot();
    }
  }

  editTimeBySupervisorClicked(timeSlot: TimetrackerSlot) {
    console.log("editTimeBySupervisorClicked timeSlot: ", timeSlot);
    if (timeSlot.edited_by_admin && !this.managerView) {
      console.log("locked by admin");
      return;
    }
    if (this.isTimeslotEditable(timeSlot)) {
      // if its a timeSlot with live_checkIn and not checkout has been done yet open checkout modal instead
      // if (timeSlot.live_checkin && !timeSlot.time_to) {
      //   this.openCheckOutModal(timeSlot);
      //   return;
      // }
      this.addTimeValues = { ...timeSlot };
      const tmpDate = dayjs(timeSlot.time_from)
        .tz("Europe/Berlin")
        .startOf("day")
        .toDate();
      if (this.managerView) {
        this.loadTimesOfApplicationId(timeSlot.application_id!, timeSlot.id);
      }
      this.addTimeModalEditBySupervisor = true;
      this.addTimeModalEditTime = true;
      this.initAddTimeValues();
      this.loadSelectableDays(tmpDate.getTime());
      this.addTimeModalVisible = true;
      this.scrollToNearestTimeSlot();
    } else {
      console.log("user cant edit this timeslot");
    }
  }

  openSignModalClicked(teamTimeSlotDay?: TeamTimeSlotDay) {
    console.log("openSignModalClicked");
    this.customerSignModalEmail = "";
    this.signatureBase64 = "";
    console.log("this.teamTimeSlotDays: ", this.teamTimeSlotDays);
    console.log(
      "this.teamTimeSlotDays[this.currentActiveTeamTimeSlotDayIndex]: ",
      this.teamTimeSlotDays[this.currentActiveTeamTimeSlotDayIndex]
    );
    console.log(
      "this.currentActiveTeamTimeSlotDayIndex: ",
      this.currentActiveTeamTimeSlotDayIndex
    );
    if (teamTimeSlotDay) {
      // determine this.currentActiveTeamTimeSlotDayIndex
      this.teamTimeSlotDays.forEach((tTSlot, index) => {
        if (tTSlot.startTimestamp === teamTimeSlotDay.startTimestamp) {
          this.currentActiveTeamTimeSlotDayIndex = index;
        }
      });
      console.log(
        "this.teamTimeSlotDays[this.currentActiveTeamTimeSlotDayIndex]: ",
        this.teamTimeSlotDays[this.currentActiveTeamTimeSlotDayIndex]
      );
    }
    this.customerSignModalVisible = true;
  }

  closeModalClicked(specificModal: string = "") {
    if (specificModal) {
      if (specificModal === "midnight") {
        this.overMidnightHintModalVisible = false;
      }
      if (specificModal === "addtime") {
        this.addTimeModalVisible = false;
        this.addTimeModalEditTime = false;
        this.addTimeModalEditBySupervisor = false;
        this.initAddTimeValues(true);
      }
      if (specificModal === "customersign") {
        this.customerSignModalVisible = false;
      }
    } else {
      this.addTimeModalVisible = false;
      this.addTimeModalEditTime = false;
      this.addTimeModalEditBySupervisor = false;
      this.customerSignModalVisible = false;
      this.overMidnightHintModalVisible = false;
      if (this.showAddTimeForTopteamerOverlay) {
        this.closeAddTimeForTopteamerOverlay();
      }
    }
    this.initAddTimeValues(true);
  }

  breakHoursValueChanged(newValue: any) {
    this.breakHoursInputValue = newValue;
    this.refreshAddTimeValues();
  }

  breakMinutesValueChanged(newValue: any) {
    this.breakMinutesInputValue = newValue;
    this.refreshAddTimeValues();
  }

  fakeBreakHoursValueChanged(newValue: any) {
    this.fakeBreakHoursInputValue = newValue;
    this.refreshAddTimeValues();
  }

  fakeBreakMinutesValueChanged(newValue: any) {
    this.fakeBreakMinutesInputValue = newValue;
    this.refreshAddTimeValues();
  }

  getNextDayStringIfOverMidnight(time_from: number, time_to: number) {
    const dayFrom = new Date(time_from).toLocaleDateString("de", {
      weekday: "short",
    });
    const dayTo = new Date(time_to).toLocaleDateString("de", {
      weekday: "short",
    });
    if (dayFrom !== dayTo) {
      return ` ${dayTo}. `;
    }
    return "";
  }

  async exportCSVClicked(teamTimeSlotDay?: TeamTimeSlotDay) {
    console.log("exportCSVClicked");
    await this.data.exportTimesCSV(
      teamTimeSlotDay,
      this.teamTimeSlotDays,
      this.eventName
    );
    this.ngOnInit();
  }

  resendTimeDocumentClicked(teamTimeSlotDay: TeamTimeSlotDay) {
    Confirm.show(
      "Zeiterfassung erneut versenden",
      "Soll die Zeiterfassung erneut versendet werden?",
      "Nein",
      "Ja",
      () => {},
      () => {
        this.sendTimeOverview(teamTimeSlotDay);
      }
    );

    console.log("resendTimeDocumentClicked teamTimeSlotDay: ", teamTimeSlotDay);
  }

  async sendTimeOverview(teamTimeSlotDay: TeamTimeSlotDay) {
    console.log("sendTimeOverview called with: ", teamTimeSlotDay);

    const tmpTeamTimeSlotDay = { ...teamTimeSlotDay };
    if (tmpTeamTimeSlotDay && tmpTeamTimeSlotDay.document_ids.length) {
      const documents = await this.data.postRequest(`/documents/getDocuments`, {
        document_ids: tmpTeamTimeSlotDay.document_ids,
      });

      if (documents && documents.length) {
        console.log("document: ", document);
        if (tmpTeamTimeSlotDay && tmpTeamTimeSlotDay.timeSlots) {
          // preprocess timeslots:
          tmpTeamTimeSlotDay.timeSlots.forEach((tSlot, index) => {
            if (tSlot.time_to && (tSlot.time_break || tSlot.time_break === 0)) {
              let timeFrom = tSlot.time_from;
              let timeTo = tSlot.time_to;
              let timeBreak = tSlot.time_break;
              if (tSlot.fake_time_from) timeFrom = tSlot.fake_time_from;
              if (tSlot.fake_time_to) timeTo = tSlot.fake_time_to;
              if (tSlot.fake_time_break || tSlot.fake_time_break === 0)
                timeBreak = tSlot.fake_time_break;

              // update formatted duration
              const timeDuration = Math.max(0, timeTo - timeFrom);
              const midnight = new Date(timeFrom);
              midnight.setHours(0, 0, 0, 0);
              tmpTeamTimeSlotDay.timeSlots![index].formattedDuration =
                this.splitMilliseconds(timeDuration).formatted;

              tmpTeamTimeSlotDay.timeSlots![index].formattedBreak =
                this.splitMilliseconds(timeBreak).formatted;
            }
          });
        }

        const emailData: any = {
          data: {
            weekday: tmpTeamTimeSlotDay.weekday,
            dateLong: tmpTeamTimeSlotDay.dateLong,
            overallTimeFormatted: tmpTeamTimeSlotDay.overallTimeFormatted
              ? tmpTeamTimeSlotDay.overallTimeFormatted
              : this.formattedTeamTimeOverall,
            timeSlots: tmpTeamTimeSlotDay.timeSlots,
          },
          document_ids: tmpTeamTimeSlotDay.document_ids,
          project_id: this.projectId!,
        };
        if (this.applicationId) {
          emailData.application_id = this.applicationId;
        }
        console.log("sendTimeOverview emailData: ", emailData);
        try {
          const result = await this.data.postRequest(
            `/application/time/sendTimeOverview`,
            emailData
          );
          if (result && result.errors && result.errors.length > 0) {
            const emails = result.errors
              .map((e: any) => `${e.email} mit Begründung: ${e.message}`)
              .join("<br><br> ");

            Report.failure(
              "Fehler",
              `Folgende E-Mails konnten nicht versendet werden:<br><br> ${emails}`,
              "Okay",
              {
                svgSize: "20px",
                messageMaxLength: 9999,
                plainText: false,
              }
            );
            return;
          }
          Notify.success("E-Mail erfolgreich verschickt");
        } catch (error) {
          console.error(error);
          Notify.failure("E-Mail konnte nicht verschickt werden");
        }
      }
    }
  }

  initAddTimeValues(fresh: boolean = false) {
    if (fresh) {
      this.timeFromInputValue = "";
      this.timeToInputValue = "";
      this.addTimeValues.time_from = 0;
      this.addTimeValues.time_to = 0;
      this.addTimeValues.time_break = 0;
      this.breakHoursInputValue = 0;
      this.breakMinutesInputValue = 0;
      this.overMidnightString = "";
      this.formattedTimeDuration = "";
      // fake values:
      this.fakeTimeFromInputValue = "";
      this.fakeTimeToInputValue = "";
      this.fakeOverMidnightString = "";
      this.fakeBreakHoursInputValue = 0;
      this.fakeBreakMinutesInputValue = 0;
      this.fakeFormattedTimeDuration = "";
    } else {
      // set init value for time_from
      let dateFrom = new Date(this.addTimeValues.time_from);
      this.timeFromInputValue =
        this.transformDateTimeToInputStringValue(dateFrom);
      const tmptDateTo = new Date();
      tmptDateTo.setMinutes(
        this.correctMinutesToNearestQuarter(tmptDateTo.getMinutes())
      );
      let tmpTimeTo = tmptDateTo.getTime();

      let tmpTimeBreak = 0;
      if (this.addTimeValues.time_to) {
        tmpTimeTo = this.addTimeValues.time_to;
      } else {
        this.addTimeValues.time_to = tmpTimeTo;
      }
      if (
        this.addTimeValues.time_break ||
        this.addTimeValues.time_break === 0
      ) {
        tmpTimeBreak = this.addTimeValues.time_break;
      } else {
        this.addTimeValues.time_break = tmpTimeBreak;
      }
      // set init value for time_to
      let dateTo = new Date(tmpTimeTo);
      this.timeToInputValue = this.transformDateTimeToInputStringValue(dateTo);
      console.log(
        "initAddTimeValues, this.timeToInputValue: ",
        this.timeToInputValue
      );
      // set init value for timeBreakHours
      let tmpTime = Math.floor(tmpTimeBreak / 60 / 60 / 1000);
      this.breakHoursInputValue = tmpTime;
      // set init value for timeBreakMinutes
      tmpTime = Math.floor(
        (tmpTimeBreak - tmpTime * 60 * 60 * 1000) / 60 / 1000
      );
      this.breakMinutesInputValue = tmpTime;
      // update overmidnight string
      this.overMidnightString = this.getNextDayStringIfOverMidnight(
        dateFrom.getTime(),
        dateTo.getTime()
      );

      // update formatted duration
      let timeDuration = Math.max(
        0,
        tmpTimeTo - this.addTimeValues.time_from - tmpTimeBreak
      );
      let midnight = new Date(this.addTimeValues.time_from);
      midnight.setHours(0, 0, 0, 0);
      let durationHourString = new Date(
        midnight.getTime() + timeDuration
      ).toLocaleTimeString("de", { hour: "numeric", minute: "2-digit" });
      this.formattedTimeDuration = `${durationHourString} h`;

      // init fakedata:
      // set init value for time_from
      if (
        this.addTimeValues.fake_time_from &&
        this.addTimeValues.fake_time_to &&
        (this.addTimeValues.fake_time_break ||
          this.addTimeValues.fake_time_break === 0)
      ) {
        dateFrom = new Date(this.addTimeValues.fake_time_from);
        this.fakeTimeFromInputValue =
          this.transformDateTimeToInputStringValue(dateFrom);
        // set init value for time_to
        dateTo = new Date(this.addTimeValues.fake_time_to);
        this.fakeTimeToInputValue =
          this.transformDateTimeToInputStringValue(dateTo);
        // set init value for timeBreakHours
        tmpTime = Math.floor(
          this.addTimeValues.fake_time_break / 60 / 60 / 1000
        );
        this.fakeBreakHoursInputValue = tmpTime;
        // set init value for timeBreakMinutes
        tmpTime = Math.floor(
          (this.addTimeValues.fake_time_break - tmpTime * 60 * 60 * 1000) /
            60 /
            1000
        );
        this.fakeBreakMinutesInputValue = tmpTime;
        this.fakeOverMidnightString = this.getNextDayStringIfOverMidnight(
          dateFrom.getTime(),
          dateTo.getTime()
        );
        // update overmidnight string
        // if (dateFrom.getDate() != dateTo.getDate()) {
        //   this.fakeOverMidnightString = ` ${this.getNextWeekday()}`;
        // } else {
        //   this.fakeOverMidnightString = "";
        // }
        // update formatted duration
        timeDuration = Math.max(
          0,
          this.addTimeValues.fake_time_to -
            this.addTimeValues.fake_time_from -
            this.addTimeValues.fake_time_break
        );
        midnight = new Date(this.addTimeValues.fake_time_from);
        midnight.setHours(0, 0, 0, 0);
        durationHourString = new Date(
          midnight.getTime() + timeDuration
        ).toLocaleTimeString("de", { hour: "numeric", minute: "2-digit" });
        this.fakeFormattedTimeDuration = `${durationHourString} h`;
      } else {
        // fresh fake values:
        this.fakeTimeFromInputValue = this.timeFromInputValue;
        this.fakeTimeToInputValue = this.timeToInputValue;
        this.fakeOverMidnightString = this.overMidnightString;
        this.fakeBreakHoursInputValue = 0;
        this.fakeBreakMinutesInputValue = 0;
        this.fakeFormattedTimeDuration = this.formattedTimeDuration;
      }
    }
  }

  /**
   * TODO needs fix for checkin only edits
   * @param updateMinBreak
   */
  refreshAddTimeValues(updateMinBreak: boolean = true) {
    let selectedDayTime = -1;
    if (this.timeFromInputValue) {
      const fromToken = this.timeFromInputValue.split(":");
      const fromHours = parseInt(fromToken[0], 10);
      const fromMinutes = parseInt(fromToken[1], 10);
      let toToken;
      let toHours;
      let toMinutes;
      if (this.timeToInputValue && this.timeToInputValue !== "") {
        toToken = this.timeToInputValue.split(":");
        toHours = parseInt(toToken[0], 10);
        toMinutes = parseInt(toToken[1], 10);

        if (!this.isForCheckIn) {
          // get selectedDay:
          this.selectableDays.forEach((sDay) => {
            if (sDay.selected) {
              selectedDayTime = sDay.time;
            }
          });
        } else {
          selectedDayTime = dayjs(this.addTimeValues.time_from)
            .startOf("day")
            .valueOf();
        }
      } else {
        // get selectedDay:
        let foundSelectedDay = false;
        this.selectableDays.forEach((sDay) => {
          if (!foundSelectedDay && sDay.selected) {
            selectedDayTime = sDay.time;
            foundSelectedDay = true;
          }
        });
        if (!foundSelectedDay) {
          // set selectedDayTime to the Date of from_time
          selectedDayTime = this.addTimeValues.time_from;
        }
      }

      // create dateobjects
      const fromDate = dayjs(selectedDayTime)
        .tz("Europe/Berlin")
        .set("seconds", 0)
        .set("milliseconds", 0)
        .toDate();

      let toDate;
      if (toHours !== undefined && toMinutes !== undefined) {
        toDate = dayjs(selectedDayTime)
          .tz("Europe/Berlin")
          .set("seconds", 0)
          .set("milliseconds", 0)
          .toDate();
        // set toDate to next day if necessary
        if (
          toHours < fromHours ||
          (toHours === fromHours && toMinutes < fromMinutes)
        ) {
          toDate.setDate(fromDate.getDate() + 1);
          // isOverMidnight = true;
        }
      }
      // fix times of dates
      fromDate.setHours(fromHours, fromMinutes, 0, 0);
      this.addTimeValues.time_from = fromDate.getTime();

      if (
        toDate !== undefined &&
        toHours !== undefined &&
        toMinutes !== undefined
      ) {
        toDate.setHours(toHours, toMinutes, 0, 0);
        this.addTimeValues.time_to = toDate.getTime();
        // update overmidnight string
        this.overMidnightString = this.getNextDayStringIfOverMidnight(
          fromDate.getTime(),
          toDate.getTime()
        );

        // calculate breaktime
        this.addTimeValues.time_break =
          this.breakHoursInputValue * 60 * 60 * 1000 +
          this.breakMinutesInputValue * 60 * 1000;
        // update formatted duration
        const timeDuration = Math.max(
          0,
          this.addTimeValues.time_to -
            this.addTimeValues.time_from -
            this.addTimeValues.time_break
        );
        const midnight = new Date(this.addTimeValues.time_from);
        midnight.setHours(0, 0, 0, 0);
        const durationHourString = new Date(
          midnight.getTime() + timeDuration
        ).toLocaleTimeString("de", { hour: "numeric", minute: "2-digit" });
        this.formattedTimeDuration = `${durationHourString} h`;
      } else {
        this.formattedTimeDuration = "";
      }
    }
    // for fake formular:
    if (
      this.fakeTimeFromInputValue &&
      this.fakeTimeFromInputValue !== "" &&
      this.fakeTimeToInputValue &&
      this.fakeTimeToInputValue !== ""
    ) {
      const fromToken = this.fakeTimeFromInputValue.split(":");
      const toToken = this.fakeTimeToInputValue.split(":");
      const fromHours = parseInt(fromToken[0], 10);
      const fromMinutes = parseInt(fromToken[1], 10);
      const toHours = parseInt(toToken[0], 10);
      const toMinutes = parseInt(toToken[1], 10);

      if (!this.isForCheckIn) {
        // get selectedDay:
        this.selectableDays.forEach((sDay) => {
          if (sDay.selected) {
            selectedDayTime = sDay.time;
          }
        });
      } else {
        selectedDayTime = dayjs(this.addTimeValues.time_from)
          .startOf("day")
          .toDate()
          .getTime();
      }

      // create dateobjects
      const fromDate = dayjs(selectedDayTime)
        .tz("Europe/Berlin")
        .set("seconds", 0)
        .set("milliseconds", 0)
        .toDate();
      const toDate = dayjs(selectedDayTime)
        .tz("Europe/Berlin")
        .set("seconds", 0)
        .set("milliseconds", 0)
        .toDate();
      // let isOverMidnight = false;
      // set toDate to next day if necessary)
      if (
        toHours < fromHours ||
        (toHours === fromHours && toMinutes < fromMinutes)
      ) {
        toDate.setDate(fromDate.getDate() + 1);
        // isOverMidnight = true;
      }
      // fix times of dates
      fromDate.setHours(fromHours, fromMinutes, 0, 0);
      toDate.setHours(toHours, toMinutes, 0, 0);
      this.addTimeValues.fake_time_from = fromDate.getTime();
      this.addTimeValues.fake_time_to = Math.max(
        toDate.getTime(),
        this.addTimeValues.time_to ?? 0
      );
      // update overmidnight string
      this.fakeOverMidnightString = this.getNextDayStringIfOverMidnight(
        fromDate.getTime(),
        toDate.getTime()
      );
      // if (isOverMidnight) {
      //   this.fakeOverMidnightString = ` ${this.getNextWeekday()}`;
      // } else {
      //   this.fakeOverMidnightString = "";
      // }
      // calculate breaktime
      this.addTimeValues.fake_time_break =
        this.fakeBreakHoursInputValue * 60 * 60 * 1000 +
        this.fakeBreakMinutesInputValue * 60 * 1000;
      // update formatted duration
      const timeDuration = Math.max(
        0,
        this.addTimeValues.fake_time_to -
          this.addTimeValues.fake_time_from -
          this.addTimeValues.fake_time_break
      );
      const midnight = new Date(this.addTimeValues.fake_time_from);
      midnight.setHours(0, 0, 0, 0);
      const durationHourString = new Date(
        midnight.getTime() + timeDuration
      ).toLocaleTimeString("de", { hour: "numeric", minute: "2-digit" });
      this.fakeFormattedTimeDuration = `${durationHourString} h`;
    }
    if (updateMinBreak) this.updateMinBreakValue();
    console.log("refreshed this.addTimeValues: ", this.addTimeValues);
  }

  overMidnightStringClicked() {
    console.log("overMidnightStringClicked");
    // TODO show hint modal for over midnight shift
    this.overMidnightHintModalVisible = true;
  }

  isTimeslotEditable(timeSlot: TimetrackerSlot) {
    return (
      (!timeSlot.document_id || this.currentUserHasAdminPrivileges) &&
      !timeSlot.is_already_established_in_datev_export
    );
  }

  hasTimeslotsWithoutDocumentId(teamTimeSlotDay: TeamTimeSlotDay) {
    return (
      (teamTimeSlotDay.timeSlots || []).filter((timeSlot) => {
        return !timeSlot.document_id;
      }).length > 0
    );
  }
}
