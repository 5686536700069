import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "../../../data.service";

export const passwordMatchingValidatior: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (control.get("new_password")?.disabled) return null;
  const password = control.get("new_password");
  const confirmPassword = control.get("new_password_repeat");
  return password?.value === confirmPassword?.value
    ? null
    : { notmatched: true };
};

@Component({
  selector: "page-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.scss"],
  host: { class: "host-full-width" },
})
export class PasswordResetComponent implements OnInit {
  pwResetForm: FormGroup = new FormGroup(
    {
      email: new FormControl("", [Validators.email, Validators.required]),
      new_password: new FormControl({ value: "", disabled: true }, [
        Validators.required,
        Validators.minLength(5),
      ]),
      new_password_repeat: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
    },
    {
      // validator: ,
      validators: passwordMatchingValidatior,
    }
  );

  isSubmitting: boolean = false;

  submitAttempt: boolean = false;

  mailSuccess: boolean = false;

  passwordResetSuccess: boolean = false;

  sent: boolean = false;

  tokenInvalidated: boolean = false;

  newPassword: boolean = true;

  token: string | undefined = undefined;

  pwResetFormSubscription: Subscription | undefined;

  constructor(private data: DataService, private route: ActivatedRoute) {}

  async ngOnInit() {
    console.log(`this.route.params`, this.route.snapshot.queryParams.email);
    console.log(`this.pwResetForm.errors`, this.pwResetForm.errors);
    console.log(
      `this.pwResetForm.errors`,
      this.pwResetForm.controls.new_password.errors
    );
    if (this.route.snapshot.queryParams.email) {
      this.pwResetForm.controls.email.setValue(
        this.route.snapshot.queryParams.email
      );
    }
    this.token = this.route.snapshot.queryParams.token;
    if (this.token) {
      try {
        this.data.spinner(true);
        const valid = await this.data.forgotPassword_checkToken(this.token);
        this.data.spinner(false);

        if (!valid) {
          this.tokenInvalidated = true;
          this.token = undefined;
          return;
        }
        this.newPassword = true;
        this.pwResetForm.controls.email.disable();
        this.pwResetForm.controls.new_password.enable();
        this.pwResetForm.controls.new_password_repeat.enable();
      } catch (error) {
        console.error(`ngOnInit error`, error);
        this.data.spinner(false);
      }
    }
    this.pwResetFormSubscription = this.pwResetForm.valueChanges.subscribe(
      (value) => {
        console.log("pwResetForm subscription value: ", value);
        this.sent = false;
        this.tokenInvalidated = false;
      }
    );
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.pwResetFormSubscription)
      this.pwResetFormSubscription.unsubscribe();
  }

  async resetPassword() {
    console.log(`this.pwResetForm.errors`, this.pwResetForm.errors);
    try {
      this.tokenInvalidated = false;
      this.submitAttempt = true;
      if (this.pwResetForm.invalid) {
        return;
      }
      this.isSubmitting = true;
      if (this.token && !this.tokenInvalidated) {
        this.passwordResetSuccess =
          await this.data.forgotPassword_changePassword(
            this.token,
            this.pwResetForm.value.new_password
          );
        if (this.passwordResetSuccess) {
          this.pwResetForm.controls.email.disable();
          this.pwResetForm.controls.new_password.disable();
          this.pwResetForm.controls.new_password_repeat.disable();
        }
      } else {
        this.mailSuccess = await this.data.forgotPassword_sendForgotLinkToMail(
          this.pwResetForm.value.email
        );
      }

      this.isSubmitting = false;
      this.sent = true;
      if (
        (this.token && !this.tokenInvalidated && !this.passwordResetSuccess) ||
        (!(this.token && !this.tokenInvalidated) && !this.mailSuccess)
      ) {
        return;
      }
    } catch (error) {
      this.isSubmitting = false;
    }
  }
}
