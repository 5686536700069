import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  Sentry.init({
    dsn: "https://34ec5e1f68184176a5da46fb22da43ce@o939555.ingest.sentry.io/6770469",
    integrations: [
      new BrowserTracing({
        tracingOrigins: ["localhost", "https://connect.topteam-gmbh.com/"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new CaptureConsoleIntegration({
        levels: ["error"],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
