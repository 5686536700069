import { Subscription } from "rxjs";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
// import { AbstractControl, FormControl } from "@angular/forms";

@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.scss"],
})
export class EditorComponent implements OnInit {
  constructor() {}

  @Input()
  html: string = "";

  @Output()
  htmlChange = new EventEmitter<string>();

  @Input()
  // formCntrl: FormControl | AbstractControl | undefined;
  update() {
    this.htmlChange.emit(this.html);
    // if (this.formCntrl) {
    //   this.formCntrl.setValue(this.html);
    // }
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.formContrlSubscription) {
      this.formContrlSubscription.unsubscribe();
    }
  }

  formContrlSubscription: Subscription | undefined;

  ngAfterViewInit() {
    document.querySelectorAll(".note-editable").forEach((el) => {
      el.addEventListener("paste", (e: any) => {
        e.preventDefault();
        const text = (e.originalEvent || e).clipboardData.getData("text/plain");
        document.execCommand("insertText", false, text);
      });
    });
  }

  ngOnInit(): void {
    // if (this.formCntrl) {
    //   this.formContrlSubscription = this.formCntrl.valueChanges.subscribe(
    //     (value) => {
    //       this.html = value;
    //     }
    //   );
    // }
  }

  public config = {
    placeholder: "",
    tabsize: 2,
    height: 500,
    uploadImagePath: "/api/upload",
    dialogsInBody: true,
    toolbar: [
      [
        "misc",
        [
          "codeview",
          // "undo", "redo"
        ],
      ],
      [
        "font",
        [
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "superscript",
          "subscript",
          "clear",
        ],
      ],
      ["fontsize", "color"],
      ["para", ["style", "ul", "ol", "paragraph", "height"]],
      [
        "insert",
        [
          "table",
          // "picture",
          "link",
          // "video",

          "hr",
        ],
      ],
    ],
    // fontNames: [
    //   "Helvetica",
    //   "Arial",
    //   "Arial Black",
    //   "Comic Sans MS",
    //   "Courier New",
    //   "Roboto",
    //   "Times",
    // ],
  };
}
