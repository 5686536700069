import { DataService } from "src/app/data.service";
import { Subscription } from "rxjs";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { User, Berufserfahrung, Profile, Geschlecht } from "@ttc_types/types";
import {
  haarfarben,
  jacke_sacko_groessen,
  hose_rock_groessen,
  jeansweite,
  jeanslaenge,
  schuhgroesse,
  shirtArr,
  sprachenKeysLabelArray,
  sprachNiveau,
} from "@ttc_types/datasets";
import * as dayjs from "dayjs";

@Component({
  selector: "app-sed",
  templateUrl: "./sed.component.html",
  styleUrls: ["./sed.component.scss"],
})
export class SedComponent implements OnInit {
  constructor(private data: DataService) {}

  @Input()
  submitAttempt: boolean = false;

  haarfarben = haarfarben;

  geschlecht = Geschlecht;

  jacke_sacko_groessen = jacke_sacko_groessen;

  hose_rock_groessen = hose_rock_groessen;

  jeansweite = jeansweite;

  jeanslaenge = jeanslaenge;

  schuhgroesse = schuhgroesse;

  shirtArr = shirtArr;

  sprachenKeysLabelArray = sprachenKeysLabelArray;

  weiterSprachenKeys: {
    key: string;
    label: string;
    level: string;
  }[] = [];

  sprachNiveau = sprachNiveau;

  @Input()
  basicsForPersonal: Partial<Profile> | undefined;

  @Input()
  user!: User;

  @Input()
  customerPreview: boolean = false;

  @Output()
  close: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Output()
  isUploading: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Input()
  originalSedForm!: FormGroup;

  @Input()
  sedForm!: FormGroup;

  @Input()
  arbeitskreafteEdit: boolean = false;

  berufserfahrungArr: FormArray = new FormArray([] as FormGroup[]);

  berufserfahrungOriginal: Berufserfahrung[] = [];

  berufserfahrung: FormGroup = new FormGroup({
    cntrls: this.berufserfahrungArr,
  });

  berufserfahrungChangedAtIndex: number[] = [];

  personStati: string[] = [];

  personProfession: string[] = [];

  get ageFromBirthday() {
    try {
      if (this.basicsForPersonal && this.basicsForPersonal.geburtsdatum) {
        return dayjs().diff(
          dayjs(this.basicsForPersonal.geburtsdatum, "DD.MM.YYYY"),
          "year"
        );
      }
    } catch (birthdateCalculationError) {
      console.log("birthdateCalculationError", birthdateCalculationError);
    }
    return "";
  }

  berufserfahrungArrSubscription: Subscription | undefined;

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.berufserfahrungArrSubscription) {
      this.berufserfahrungArrSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.berufserfahrungArrSubscription =
      this.berufserfahrungArr.valueChanges.subscribe(
        (value: Berufserfahrung[]) => {
          const tmp: number[] = [];
          for (let i = 0; i < value.length; i++) {
            if (
              this.berufserfahrungOriginal.filter((beo) => {
                return (
                  beo.veranstaltung == value[i].veranstaltung &&
                  beo.taetigkeit == value[i].taetigkeit &&
                  beo.markenname == value[i].markenname
                );
              }).length == 0
            ) {
              tmp.push(i);
            }
          }
          this.berufserfahrungChangedAtIndex = tmp;
          this.sedForm.controls.berufserfahrung.setValue(
            this.berufserfahrungArr.value
          );
        }
      );
    console.log("sedForm", this.sedForm);
    console.log("originalSedForm", this.originalSedForm);
    console.log("this.basicsForPersonal", this.basicsForPersonal);
    // if (this.user.main_sed_card) {
    if (this.originalSedForm.value.berufserfahrung) {
      this.berufserfahrungOriginal = this.originalSedForm.value.berufserfahrung;
    }
    if (this.sedForm.value.berufserfahrung) {
      // this.berufserfahrungArr = this.sedForm.value.berufserfahrung;
      for (const be of this.sedForm.value.berufserfahrung) {
        this.addBerufserfahrung(be);
      }
    }

    // check if user has specific stati selected in application
    if (this.basicsForPersonal?.arbeitsbeginn_status) {
      Object.keys(this.basicsForPersonal.arbeitsbeginn_status).forEach(
        (key) => {
          const value = this.basicsForPersonal!.arbeitsbeginn_status![key];
          // check if value of status is true to show it in sed card
          if (value === true) {
            // add to shown stati, clean up Umlaute
            this.personStati.push(
              key.replace("ae", "ä").replace("oe", "ö").replace("ue", "ü")
            );
            // check if user has specific professions selected in application
            if (
              this.basicsForPersonal?.arbeitsbeginn_status_additional![
                `${key}_fach`
              ]
            ) {
              this.personProfession.push(
                this.basicsForPersonal?.arbeitsbeginn_status_additional![
                  `${key}_fach`
                ]
              );
            }
          }
        }
      );
    }

    const languages = this.sedForm.get("sprachen");

    if (!languages) {
      return;
    }

    const additional = languages.get("weitere");
    if (!additional) {
      return;
    }

    const additionalLanguages: { label: string; level: string }[] =
      additional.value;

    const asGroup = languages.get("weitereAsGroup") as FormGroup;
    if (!asGroup) {
      return;
    }

    asGroup.controls = this.toLangsFormGroup(
      additionalLanguages,
      languages.disabled
    );

    const originalGroup = this.originalSedForm
      .get("sprachen")
      ?.get("weitereAsGroup") as FormGroup;

    if (!originalGroup) {
      return;
    }

    originalGroup.controls = this.toLangsFormGroup(
      additionalLanguages,
      languages.disabled
    );
  }

  resetBerufserfahrung() {
    // eslint-disable-next-line prefer-object-spread
    // this.berufserfahrung.reset();
    this.berufserfahrungArr.clear();
    for (const be of this.berufserfahrungOriginal) {
      this.addBerufserfahrung(be as Berufserfahrung);
    }
  }

  addBerufserfahrung(be: Berufserfahrung | undefined = undefined) {
    (<FormArray>this.berufserfahrungArr).push(
      new FormGroup({
        veranstaltung: new FormControl(be?.veranstaltung || "", [
          Validators.required,
        ]),
        taetigkeit: new FormControl(be?.taetigkeit || "", [
          Validators.required,
        ]),
        markenname: new FormControl(be?.markenname || "", [
          Validators.required,
        ]),
      })
    );
  }

  removeBerufserfahrungAtIndex(index: number) {
    this.berufserfahrungArr.removeAt(index);
  }

  /**
   * check if a form control has been changed
   * @param key
   * @param subFormGroup name of the sub form group
   */
  isChanged(key: string, ...subFormGroup: string[]): boolean {
    const { current } = this.getNewAndOriginalControl(key, ...subFormGroup);

    return current.dirty;
  }

  /**
   * reset a form control to its original value
   * @param key
   * @param subFormGroup name of the sub form group
   */
  reset(key: string, ...subFormGroup: string[]) {
    const { current, original } = this.getNewAndOriginalControl(
      key,
      ...subFormGroup
    );

    current.reset(original.value);
  }

  /**
   * get a form control from a group
   * @param group
   * @param key
   * @returns
   */
  getNewAndOriginalControl(key: string, ...subFormGroup: string[]) {
    const groups = [...subFormGroup, key];

    const current = groups.reduce(this.getControl, this.sedForm);
    const original = groups.reduce(this.getControl, this.originalSedForm);

    if (!current || !original) {
      throw new Error(`Fields do not match ${groups.join(".")}`);
    }

    return { current, original };
  }

  private getControl(
    group: FormGroup | AbstractControl | undefined,
    key: string
  ) {
    if (!group) {
      return undefined;
    }

    if (group instanceof FormGroup) {
      return group.controls[key];
    }

    return undefined;
  }

  toLangsFormGroup(
    values: { label: string; level: string }[],
    isDisabled: boolean
  ) {
    for (const lang of values) {
      const key = lang.label;
      const label = this.capitalizeFirstLetter(lang.label);

      if (this.weiterSprachenKeys.some((wsk) => wsk.key === key)) {
        // eslint-disable-next-line no-continue
        continue;
      }

      this.weiterSprachenKeys.push({ label, key, level: lang.level });
    }

    return values.reduce((acc, { label, level }) => {
      const form = new FormControl(level);

      if (isDisabled) {
        form.disable();
      } else {
        form.addValidators((control) => {
          this.syncAdditionalLanguages(label, control);
          return null;
        });
      }

      acc[label] = form;

      return acc;
    }, {} as { [key: string]: FormControl });
  }

  capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  syncAdditionalLanguages(key: string, control: AbstractControl) {
    const languages = this.sedForm.get("sprachen");

    if (!languages) {
      return;
    }

    const additional = languages.get("weitere");
    if (!additional) {
      return;
    }

    const values = additional.value;

    const index = values.findIndex((v: { label: string }) => v.label === key);

    if (index === -1) {
      return;
    }

    values[index].level = control.value;
    additional.setValue(values);
  }

  get hatWeitereErfahrungen() {
    return this.sedForm.get("weitere_erfahrungen")?.value;
  }
}
