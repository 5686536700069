import { Router } from "@angular/router";
/* global google */
import { Notify, Confirm } from "notiflix";
import { DataService } from "src/app/data.service";
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as dayjs from "dayjs";
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Loader } from "@googlemaps/js-api-loader";
import { debounceTime, Subscription } from "rxjs";

import {
  haarfarben,
  shirtToggles,
  jacke_sacko_groessen,
  hose_rock_groessen,
  jeansweite,
  jeanslaenge,
  schuhgroesse,
  shirtArr,
  sprachNiveau,
  geschlecht,
  guertellaenge,
  kragenweite,
} from "@ttc_types/datasets";
import {
  Projekt,
  User,
  AllUsersResult,
  Toggle,
  ShowArbeitskraefte,
  sortDirectionKeys,
  sortDirections,
  sortDirection,
  Geschlecht,
} from "@ttc_types/types";

// import { AllUsersResult, User, Toggle } from './../../types-datasets/types';

@Component({
  selector: "page-arbeitskraefte",
  templateUrl: "./arbeitskraefte.component.html",
  styleUrls: ["./arbeitskraefte.component.scss"],
  host: { class: "host-full-width" },
})
export class ArbeitskraefteComponent implements OnInit {
  @ViewChild("search")
  public searchElementRef!: ElementRef;

  public event_location: google.maps.places.PlaceResult | undefined;

  public event_drive_distance: google.maps.Distance | undefined;

  public event_drive_duration: google.maps.Duration | undefined;

  private geoCoder: any;

  public haarfarben = haarfarben;

  shirtToggles = shirtToggles;

  shirtArr = shirtArr;

  jacke_sacko_groessen = jacke_sacko_groessen;

  hose_rock_groessen = hose_rock_groessen;

  jeansweite = jeansweite;

  jeanslaenge = jeanslaenge;

  schuhgroesse = schuhgroesse;

  refreshToggles = 0;

  math = Math;

  sortDirections = sortDirections;

  oldStatusFilter: any;

  selectedUsers: {
    [user_id: number]: {
      name: string;
      email: string;
      activated: boolean;
      deleted: boolean;
    };
  } = {};

  get selectedUserNameString() {
    return Object.values(this.selectedUsers)
      .map((user) => user.name)
      .join(", ");
  }

  get selectedUsersLength() {
    return Object.keys(this.selectedUsers).length;
  }

  // setSortDirections: { field: string; order: null | "asc" | "desc" } = {};
  sortDirection: sortDirection = { field: null, order: 0 };

  async toggleSortDirection(key: sortDirectionKeys) {
    if (this.sortDirection.field == key) {
      this.sortDirection.order = (this.sortDirection.order + 1) % 3;
    } else {
      this.sortDirection.field = key;
      this.sortDirection.order = 1;
    }
    await this.refreshResults(1);
  }

  justEnteredMobileScrollfix: boolean = true;

  pagination = {
    page: 1,
    perPage: 25,
    pagesTotal: 2,
    total: 0,
  };

  paginationKeys: { page: number; from: number; to: number }[] = [];

  berufserfahrung: string[] = [];

  teilgenommeneEvents: { label: string; id: number }[] = [];

  suchergebnisseCount: number = 0;

  allSuchergebnisseCount: number = 0;

  arbeitskreafteFiltered: ShowArbeitskraefte[] = [];

  showArbeitskraefteFilterModal: boolean = false;

  stammdatenModal: boolean = false;

  sprachen: { [index: string]: string } = {
    deutsch: "Deutsch",
    englisch: "Englisch",
    spanisch: "Spanisch",
    franzoesisch: "Französisch",
    italienisch: "Italienisch",
  };

  get_sprache_fg(): FormGroup {
    return new FormGroup({
      sprache: new FormControl("", []), // Validators.required
      sprache_level_min: new FormControl("3", []), // Validators.required
    });
  }

  push_sprache_fg() {
    this.sprachen_fa.push(this.get_sprache_fg());
  }

  get_minmax_fg(type: string) {
    const obj = {
      [`${type}_min`]: new FormControl("", []),
      [`${type}_max`]: new FormControl("", []),
    };
    return new FormGroup(obj);
  }

  push_minmax_fa(type: string) {
    this.fa_array[type].push(this.get_minmax_fg(type));
  }

  control_to_form_group(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  konfektionsgroessen_objects: {
    label: string;
    key: string;
    dataset: any[];
  }[] = [
    {
      label: "Jacke / Sacko",
      key: "jacke_sacko",
      dataset: jacke_sacko_groessen,
    },
    {
      label: "Hose / Rock",
      key: "hose_rock",
      dataset: hose_rock_groessen,
    },
    {
      label: "Jeansweite",
      key: "jeansweite",
      dataset: jeansweite,
    },
    {
      label: "Jeanslänge",
      key: "jeanslaenge",
      dataset: jeanslaenge,
    },
    {
      label: "Schuhgröße",
      key: "schuhgroesse",
      dataset: schuhgroesse,
    },
    {
      label: "Gürtellänge",
      key: "guertellaenge",
      dataset: guertellaenge,
    },
    {
      label: "Kragenweite",
      key: "kragenweite",
      // eslint-disable-next-line dot-notation
      dataset: kragenweite,
    },
  ];

  other_min_max_objects: {
    label: string;
    key: string;
    einheit: string;
  }[] = [
    {
      label: "Körpergröße",
      key: "koerpergroesse",
      einheit: "cm",
    },
    {
      label: "Gewicht",
      key: "gewicht",
      einheit: "kg",
    },
    {
      label: "Shirtgröße",
      key: "shirtgroesse",
      einheit: "",
    },
  ];

  fa_array: { [index: string]: FormArray } = {
    koerpergroesse: new FormArray([this.get_minmax_fg("koerpergroesse")]),
    gewicht: new FormArray([this.get_minmax_fg("gewicht")]),
    shirtgroesse: new FormArray([this.get_minmax_fg("shirtgroesse")]),

    jacke_sacko: new FormArray([this.get_minmax_fg("jacke_sacko")]),
    hose_rock: new FormArray([this.get_minmax_fg("hose_rock")]),
    jeansweite: new FormArray([this.get_minmax_fg("jeansweite")]),
    jeanslaenge: new FormArray([this.get_minmax_fg("jeanslaenge")]),
    schuhgroesse: new FormArray([this.get_minmax_fg("schuhgroesse")]),
    guertellaenge: new FormArray([this.get_minmax_fg("guertellaenge")]),
    kragenweite: new FormArray([this.get_minmax_fg("kragenweite")]),
  };

  sprachen_fa: FormArray = new FormArray([this.get_sprache_fg()]);

  arbeitskreefteFilterObject: any = {
    freitextsuche: new FormControl("", []),
    // distanz
    lat: new FormControl(null, []),
    lng: new FormControl(null, []),
    distance: new FormControl("", []),

    // geschlecht
    geschlecht: new FormControl([], []),

    // sprachen
    sprachen: this.sprachen_fa,

    // äusseres
    haarfarbe: new FormControl([], []),

    tattoo_oder_piercings: new FormControl("", []),

    // Mobilität
    fuehrerschein: new FormControl("", []),
    vorhandene_mobilitaet: new FormGroup({
      PKW: new FormControl("", []),
      Bahncard: new FormControl("", []),
    }),

    // berufserfahrung
    berufserfahrung: new FormControl("", []),
    teilgenommene_events: new FormControl("", []),
    status: new FormGroup({
      deaktiviert: new FormControl("", []),
      unvollstaendiges_profil: new FormControl("", []),
      active_profile: new FormControl("", []),
    }),
  };

  arbeitskraefteFilter: FormGroup = new FormGroup(
    Object.assign(this.arbeitskreefteFilterObject, this.fa_array)
  );

  getTeilgenommeneEvents(ids: number[]) {
    return this.teilgenommeneEvents
      .filter((te) => ids.includes(te.id))
      .map((te) => te.label)
      .join(", ");
  }

  public vorhandene_mobilitaet_fg: FormGroup = this.arbeitskraefteFilter
    .controls.vorhandene_mobilitaet as FormGroup;

  public status_fg: FormGroup = this.arbeitskraefteFilter.controls
    .status as FormGroup;

  leftRightMinMax = [
    { class: "left", type: "min" },
    { class: "right", type: "max" },
  ];

  arbeitskraefteFilterSubscription: Subscription | undefined;

  plz_filter_value: string = "";

  allSelected: boolean = false;

  sprachToggles: Toggle[] = sprachNiveau.slice(1).map((el) => {
    return { label: el };
  });

  geschlecht = geschlecht;

  Geschlecht = Geschlecht;

  mitarbeiterToEditForm: FormGroup = new FormGroup({
    vorname: new FormControl("", [Validators.required]),
    nachname: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.email, Validators.required]),
    role: new FormControl("regular", [Validators.required]),
  });

  submitAttempt: boolean = false;

  mitarbeiterToEditProjekte: Projekt[] = [];

  public dayjs = dayjs;

  public onlyAdminsAndPMs: boolean = false;

  userToEditFull: User | undefined;

  public tableHeaders: {
    label: string;
    sortDirectionKey: sortDirectionKeys;
  }[] = [{ label: "Name", sortDirectionKey: "vorname" }];

  constructor(
    private data: DataService,
    // public mapsAPILoader: Loader,
    public ngZone: NgZone,
    public router: Router
  ) {
    console.log(`this.router.url`, this.router.url);
    this.onlyAdminsAndPMs = this.router.url === "/projektleitung";

    if (this.onlyAdminsAndPMs) {
      this.tableHeaders.push(
        { label: "Rolle", sortDirectionKey: "role" },
        { label: "E-Mail", sortDirectionKey: "email" }
      );
    } else {
      this.tableHeaders.push(
        { label: "Letzter Einsatz", sortDirectionKey: "letzter_einsatz" },
        { label: "Alter", sortDirectionKey: "alter" },
        { label: "PLZ", sortDirectionKey: "plz" },
        {
          label: "Erfahrungen bei Topteam",
          sortDirectionKey: "anzahl_erfahrungen_bei_topteam",
        }
      );
    }

    this.tableHeaders.push(
      { label: "Letzte Aktivität", sortDirectionKey: "letzte_aktivitaet" },
      { label: "Topteamer", sortDirectionKey: "is_topteamer" }
    );

    this.mitarbeiterToEditForm.disable();

    const mapsAPILoader = new Loader({
      apiKey: "AIzaSyDPmu832hKonWni7zClZK7J6rMPxwZWQeI",
      libraries: ["places", "geometry"],
    });
    mapsAPILoader.load().then(() => {
      setTimeout(() => {
        this.geoCoder = new google.maps.Geocoder();

        // <!-- <input ngx-google-places-autocomplete [options]="{
        //   language: 'de',
        //   types: [],
        //   componentRestrictions: { country: 'CH' }
        //   }" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" placeholder="Veranstaltungsort"/> -->

        const autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement,
          {
            // types: ["address"]
            // componentRestrictions: { country: 'DE' }
          }
        );
        this.ngZone.run(() => {
          autocomplete.addListener("place_changed", () => {
            // get the place result
            const place: google.maps.places.PlaceResult =
              autocomplete.getPlace();
            console.log(`place`, place);
            // verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            this.event_location = place;

            // console.log('place', place)
            // let event_lat = place.geometry.location.lat();
            // let event_long = place.geometry.location.lng();
            const event_lat = place.geometry.location?.lat();
            const event_lng = place.geometry.location?.lng();

            console.log(`event_lat`, event_lat);
            console.log(`event_long`, event_lng);
            this.arbeitskraefteFilter.controls.lat.setValue(event_lat);
            this.arbeitskraefteFilter.controls.lng.setValue(event_lng);

            // let locationCustomer = new google.maps.LatLng(event_lat, event_long)
            // let locationDanko = new google.maps.LatLng(this.danko_lat, this.danko_lng)

            // let request: google.maps.DirectionsRequest = {
            //   origin: locationDanko,
            //   destination: locationCustomer,
            //   travelMode: google.maps.TravelMode.DRIVING
            // };
            // let directionsService = new google.maps.DirectionsService();
            // directionsService.route(request, this.ngZone.run(() => (response, status) => {
            //   if (status == 'OK') {
            //     this.data.event_drive_distance = response.routes[0].legs[0].distance
            //     this.data.event_drive_duration = response.routes[0].legs[0].duration
            //   }
            //   // console.log('direction distance',this.event_drive_distance)
            //   // console.log('directions duration', this.event_drive_duration)
            // }))
          });
        });
      }, 500);
      // this.setCurrentLocation();
    });
  }

  currentUserToEdit: User | undefined;

  emailExistValidator: AsyncValidatorFn = (
    control: AbstractControl
  ): Promise<ValidationErrors | null> => {
    return new Promise<ValidationErrors | null>((res) => {
      console.log(`control.value`, control.value);
      if (
        this.currentUserToEdit &&
        this.currentUserToEdit.email == control.value
      ) {
        res(null);
      }
      this.data.checkIfEmailExists(control.value).then((emailExists) => {
        console.log(`emailExists`, emailExists);
        this.user_for_email_is_deleted = false;
        if (!emailExists) {
          res(null);
        }
        if (emailExists == 1) {
          this.user_for_email_is_deleted = true;
          res(null);
        }
        if (emailExists == 2)
          res({ user_exists: "Diese E-Mail-Adresse existiert bereits." });

        res(null);
      });
    });
  };

  user_for_email_is_deleted: boolean = false;

  openEditForm(user: User) {
    // this.mitarbeiterToEditForm.controls.email.removeAsyncValidators(
    //   this.emailExistValidator
    // );
    // TODO remov e debug
    if (user && user.role == "regular" && user.main_sed_card) {
      this.openUserToEditFullForm(user);
      return;
    }

    this.mitarbeiterToEditForm.controls.email.removeAsyncValidators(
      this.emailExistValidator
    );

    console.log(`user`, user);
    this.currentUserToEdit = user;
    this.user_for_email_is_deleted = false;
    this.mitarbeiterToEditForm.patchValue({
      vorname: user.profile?.vorname,
      nachname: user.profile?.nachname,
      email: user.email,
      role: user.role,
    });
    if (user.applications && user.applications.length) {
      this.mitarbeiterToEditProjekte = user.applications!.map(
        (el) => el.project
      );
    }
    this.mitarbeiterToEditForm.enable();
    this.mitarbeiterToEditForm.controls.email.addAsyncValidators(
      this.emailExistValidator
    );
  }

  async openUserToEditFullForm(user: User) {
    this.userToEditFull = user;
    // const firstUser = await this.data.getUser(user.id!);
    // this.userToEditFull = firstUser;
  }

  createNewUser() {
    this.user_for_email_is_deleted = false;
    this.mitarbeiterToEditProjekte = [];
    this.submitAttempt = false;
    this.currentUserToEdit = undefined;
    this.mitarbeiterToEditForm.reset();
    this.mitarbeiterToEditForm.patchValue({
      role: this.onlyAdminsAndPMs ? "pm" : "regular",
    });
    this.mitarbeiterToEditForm.enable();
    this.mitarbeiterToEditForm.controls.email.addAsyncValidators(
      this.emailExistValidator
    );
  }

  deleteUser() {
    console.log(`deleteProfile user`, this.currentUserToEdit);
    Confirm.show(
      "Bist du sicher?",
      "Bist du sicher, dass du das Profil löschen möchten?",
      "Nein",
      "Ja",
      () => {
        // nein
      },
      async () => {
        try {
          if (this.currentUserToEdit) {
            await this.data.deleteUser(this.currentUserToEdit.id!);
            Notify.success("Der User wurde erfolgreich gelöscht!");
            this.currentUserToEdit = undefined;
            this.mitarbeiterToEditForm.reset();
            this.mitarbeiterToEditForm.disable();
            this.refreshResults(this.pagination.page);
          }
        } catch (error) {
          console.error(`deleteUser error`, error);
          Notify.failure(
            "Etwas ist schief gelaufen. Der User konnte nicht gelöscht werden."
          );
        }
        // ja
      }
    );
  }

  userIsUpdating: boolean = false;

  async updateUser() {
    try {
      this.userIsUpdating = true;
      this.submitAttempt = false;
      if (this.mitarbeiterToEditForm.invalid) {
        this.submitAttempt = true;
        this.userIsUpdating = false;
        return;
      }
      const { email, role, vorname, nachname } =
        this.mitarbeiterToEditForm.value;

      await this.data.updateUser(
        this.currentUserToEdit?.id,
        { email, role },
        { vorname, nachname }
      );

      if (this.currentUserToEdit) {
        Notify.success("Der User wurde erfolgreich geändert!");
      } else {
        Notify.success("Der User wurde erfolgreich angelegt!");
      }
      this.mitarbeiterToEditForm.reset();
      this.mitarbeiterToEditForm.disable();
      this.currentUserToEdit = undefined;
      await this.refreshResults(this.pagination.page);
      this.userIsUpdating = false;
    } catch (error) {
      this.userIsUpdating = false;
      console.error(`updateUser error`, error);
      Notify.failure(
        "Etwas ist schief gelaufen. Der User konnte nicht geändert werden."
      );
    }
    this.user_for_email_is_deleted = false;
  }

  resetLatLng(clearTextInput = false) {
    this.arbeitskraefteFilter.controls.lat.reset();
    this.arbeitskraefteFilter.controls.lng.reset();
    this.event_location = undefined;
    if (clearTextInput) {
      this.searchElementRef.nativeElement.value = "";
      this.plz_filter_value = "";
    }
  }

  clearPlzOrtFilter() {
    if (!this.arbeitskraefteFilter.value.lat) {
      console.log(`clearPlzOrtFilter`);
      this.plz_filter_value = "";
    }
  }

  selectAll(event: Event) {
    const { checked } = event.target as HTMLInputElement;
    if (checked) {
      this.allSelected = true;
      this.arbeitskreafteFiltered.forEach((el) => {
        // this.arbeitskreafteFiltered[index].selected = true;
        this.selectedUsers[el.id!] = {
          name: `${el.profile?.vorname} ${el.profile?.nachname}`,
          email: el.email,
          activated: !!el.activated,
          deleted: !!el.deleted,
        };
      });
    } else {
      this.allSelected = false;
      this.arbeitskreafteFiltered.forEach((el) => {
        delete this.selectedUsers[el.id!];
      });
      // this.selectedUsers = {};
    }
    this.checkSelectAll();
  }

  deselectAll() {
    this.allSelected = false;
    this.selectedUsers = {};
    this.checkSelectAll();
  }

  get selectedUsersCount() {
    return Object.keys(this.selectedUsers).length;
  }

  selectDeselect($event: Event, user: User) {
    if (($event.target as HTMLInputElement).checked) {
      this.selectedUsers[user.id!] = {
        name: `${user.profile?.vorname} ${user.profile?.nachname}`,
        email: user.email,
        activated: !!user.activated,
        deleted: !!user.deleted,
      };
    } else {
      delete this.selectedUsers[user.id!];
    }

    this.checkSelectAll();
  }

  checkSelectAll() {
    let allSelected = true;
    const selectedKeys = this.selectedUserIds;
    for (const a of this.arbeitskreafteFiltered) {
      if (!selectedKeys.includes(a.id!)) {
        allSelected = false;
        a.selected = false;
      } else {
        a.selected = true;
      }
    }
    this.allSelected = allSelected;
  }

  get selectedArbeitskraefte() {
    return this.arbeitskreafteFiltered.filter((el) => el.selected);
  }

  selectedMails: string[] = [];

  aktionenDropdown: boolean = false;

  arbeitszeitenModal: boolean = false;

  export_start: string = dayjs().subtract(1, "month").format("YYYY-MM-DD");

  export_end: string = dayjs().format("YYYY-MM-DD");

  openArbeitszeitenExportModal() {
    this.selectedMails = this.arbeitskreafteFiltered
      .filter((el) => el.selected)
      .map((el) => el.email);
    if (!this.selectedMails.length) {
      Notify.warning("Du musst erst einen oder mehrere User auswählen.");
      return;
    }
    this.arbeitszeitenModal = true;
  }

  exportLoading: boolean = false;

  async exportArbeitszeiten() {
    if (this.exportLoading) {
      return;
    }
    this.exportLoading = true;
    try {
      // await this.data.postRequest("/exportArbeitszeiten", {
      //   export_start: this.export_start,
      //   export_end: this.export_end,
      //   emails: this.selectedMails,
      // });
      await this.data.exportTimeslots({
        user_ids: this.selectedUserIds,
        time_from: this.export_start,
        time_to: this.export_end,
        document_id_not_null: true,
      });
      // Notify.success("Die Arbeitszeiten wurden erfolgreich exportiert.");
    } catch (error) {
      console.error(`error`, error);
      Notify.failure(
        "Etwas ist schief gelaufen. Die Arbeitszeiten konnten nicht exportiert werden."
      );
    }
    this.closeArbeitszeitenExportModal();
    this.exportLoading = false;
  }

  mailModal: boolean = false;

  mailtoAuswahl() {
    this.selectedMails = Object.values(this.selectedUsers)
      .filter((el) => !el.deleted && el.activated)
      .map((el) => el.email);
    if (!this.selectedMails.length) {
      Notify.warning("Du musst erst einen oder mehrere User auswählen.");
      return;
    }
    this.mailModal = true;
  }

  copyToProjectModal: boolean = false;

  get selectedUserIds() {
    return Object.keys(this.selectedUsers).map((el) => parseInt(el, 10));
  }

  closeArbeitszeitenExportModal() {
    this.arbeitszeitenModal = false;
    this.selectedMails = [];
  }

  closeMailModal() {
    this.mailModal = false;
    this.selectedMails = [];
    this.deselectAll();
  }

  async refreshResults(page: number | undefined = undefined) {
    console.log("refreshResults");
    if (page) {
      this.pagination.page = page;
    }
    const result = <AllUsersResult>(
      await this.data.getAllUsers(
        false,
        this.onlyAdminsAndPMs,
        this.arbeitskraefteFilter.value,
        this.pagination,
        this.sortDirection
      )
    );
    this.arbeitskreafteFiltered = result.data.map((user) => {
      const tmp: ShowArbeitskraefte = { ...user };
      tmp.alter = "";
      tmp.selected = false;

      // if (user.applications && user.applications.length) {
      //   user.applications.sort(
      //     (a, b) =>
      //       new Date(b.project!.zeitraum_von).getTime() -
      //       new Date(a.project!.zeitraum_von).getTime()
      //   );
      //   tmp.letzter_einsatz = dayjs(
      //     user.applications[0].project!.zeitraum_von
      //   ).format("DD.MM.YYYY");

      // }

      if (user.profile && user.profile.geburtsdatum) {
        tmp.alter = dayjs()
          .diff(dayjs(user.profile.geburtsdatum, "DD.MM.YYYY"), "years")
          .toFixed();
        // tmp.alter = ;
      }

      return tmp;
    });

    if (result.meta) {
      this.pagination.page = result.meta.current_page;
      this.pagination.pagesTotal = Math.ceil(
        result.meta.total / result.meta.per_page
      );
      this.pagination.total = result.meta.total;
      this.pagination.perPage = result.meta.per_page;
      console.log(`this.pagination.pagesTotal`, this.pagination.pagesTotal);
    }

    this.showArbeitskraefteFilterModal = false;

    this.checkSelectAll();
  }

  async ngOnInit() {
    console.log("this.arbeitskraefteFilter", this.arbeitskraefteFilter);
    await this.refreshResults(1);
    // eslint-disable-next-line no-constant-condition
    if (this.data.debug && false) {
      console.log(
        `this.arbeitskreafteFiltered[0]`,
        this.arbeitskreafteFiltered[0]
      );
      const firstUser = await this.data.getUser(
        this.arbeitskreafteFiltered.filter((el) => el.main_sed_card)[0].id!
      );
      console.log(`firstUser`, firstUser);
      this.openEditForm(firstUser);
    }
    if (this.data.debug && false) {
      this.showArbeitskraefteFilterModal = true;
    }
    if (this.data.debug && false) {
      this.arbeitskraefteFilter.controls.freitextsuche.setValue("Frintrop");
      await this.refreshResults(1);
      this.arbeitskreafteFiltered[0].selected = true;
      this.arbeitszeitenModal = true;
      this.export_start = "2021-01-01";
    }
    const jsonFilterOptions = await this.data.getAllUsersJSONFilterOptions();
    console.log(`jsonFilterOptions`, jsonFilterOptions);
    this.suchergebnisseCount = <number>(
      await this.data.getAllUsers(
        true,
        this.onlyAdminsAndPMs,
        undefined,
        undefined,
        this.sortDirection
      )
    );
    this.allSuchergebnisseCount = this.suchergebnisseCount;

    this.berufserfahrung = jsonFilterOptions.berufserfahrung;

    this.teilgenommeneEvents = jsonFilterOptions.teilgenommeneEvents;

    const existingEventsIds: any[] = [];
    for (let i = this.teilgenommeneEvents.length - 1; i >= 0; i--) {
      if (existingEventsIds.includes(this.teilgenommeneEvents[i].id)) {
        this.teilgenommeneEvents.splice(i, 1);
      } else {
        existingEventsIds.push(this.teilgenommeneEvents[i].id);
      }
    }

    console.log(`this.teilgenommeneEvents`, this.teilgenommeneEvents);
    console.log(`this.berufserfahrung`, this.berufserfahrung);

    this.arbeitskraefteFilter.controls.status.valueChanges
      .pipe(debounceTime(50))
      .subscribe((newStatus) => {
        if (!this.oldStatusFilter) {
          this.oldStatusFilter = newStatus;
          return;
        }
        const activeProfileGotActivated =
          this.oldStatusFilter.active_profile !== true &&
          newStatus.active_profile === true;
        const deactivatedFilterGotActivated =
          this.oldStatusFilter.deaktiviert !== true &&
          newStatus.deaktiviert === true;
        const unvollstaendigesProfilGotActivated =
          this.oldStatusFilter.unvollstaendiges_profil !== true &&
          newStatus.unvollstaendiges_profil === true;
        const deactivatedOrUnvollstaendigesProfilGotActivated =
          deactivatedFilterGotActivated || unvollstaendigesProfilGotActivated;
        if (activeProfileGotActivated) {
          this.arbeitskraefteFilter.patchValue({
            status: {
              deaktiviert: "",
              unvollstaendiges_profil: "",
              active_profile: true,
            },
          });
        } else if (deactivatedOrUnvollstaendigesProfilGotActivated) {
          this.arbeitskraefteFilter.patchValue({
            status: {
              deaktiviert: newStatus.deaktiviert,
              unvollstaendiges_profil: newStatus.unvollstaendiges_profil,
              active_profile: "",
            },
          });
        }
        this.oldStatusFilter = newStatus;
      });
    if (!this.arbeitskraefteFilterSubscription) {
      this.arbeitskraefteFilterSubscription =
        this.arbeitskraefteFilter.valueChanges
          .pipe(debounceTime(500))
          .subscribe(async (value) => {
            console.log("arbeitskraefteFilterSub scription value", value);

            if (value.berufserfahrung && !value.berufserfahrung.length) {
              this.arbeitskraefteFilter.controls.berufserfahrung.reset();
            }
            if (
              value.teilgenommene_events &&
              !value.teilgenommene_events.length
            ) {
              this.arbeitskraefteFilter.controls.teilgenommene_events.reset();
            }
            if (this.showArbeitskraefteFilterModal) {
              this.suchergebnisseCount = <number>(
                await this.data.getAllUsers(
                  true,
                  this.onlyAdminsAndPMs,
                  value,
                  undefined,
                  this.sortDirection
                )
              );
              console.log(`this.suchergebnisseCount`, this.suchergebnisseCount);
            } else {
              await this.refreshResults(1);
            }
          });
    }
    this.createNewUser();
    setTimeout(() => {
      this.mitarbeiterToEditForm.disable();
      this.justEnteredMobileScrollfix = false;
    }, 100);
  }

  ngOnDestroy(): void {
    if (this.arbeitskraefteFilterSubscription)
      this.arbeitskraefteFilterSubscription.unsubscribe();
  }

  async downloadAllStammdaten() {
    if (this.exportLoading) return;
    this.exportLoading = true;
    try {
      await this.data.downloadAllStammdaten(
        {
          time_from: this.export_start,
          time_to: this.export_end,
          document_id_not_null: true,
        },
        false
      );
    } catch (error) {
      console.log(`error`, error);
      Notify.failure("Etwas ist schief gelaufen");
    }
    this.exportLoading = false;
  }

  /**
   * Get the first name of a full name, e.g. "Max Otto" -> "Max".
   * Also removes any leading or trailing whitespaces and handles special cases like " - " -> "-"
   *
   * @param firstNames all first names
   * @returns
   */
  public toFirstNameOnly(firstNames?: string): string {
    if (!firstNames) {
      return "";
    }

    return firstNames.trim().replace(" - ", "-").split(" ")[0];
  }
}
