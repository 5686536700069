import { Notify } from "notiflix";
import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import {
  protokollWordingsFn,
  // sedProtokollWordings,
} from "@ttc_types/datasets";
import {
  protokollModelTranslations,
  TransactionLogType,
  transactionModelsArr,
  User,
} from "@ttc_types/types";
import * as dayjs from "dayjs";
import { debounceTime, Subject, Subscription } from "rxjs";
import * as XLSX from "xlsx";
import { DataService } from "../../../data.service";

export interface TransactionLogShowType {
  transaction_log: TransactionLogType;
  bereich_top: string;
  bereich_bottom: string;
  aenderung_top: string;
  aenderung_bottom: string;
  durchgefuehrt_top: string;
  durchgefuehrt_bottom: string;
}

@Component({
  selector: "app-protokoll",
  templateUrl: "./protokoll.component.html",
  styleUrls: ["./protokoll.component.scss"],
})
export class ProtokollComponent implements OnInit {
  @Input()
  user!: User;

  transactionModelsArr = transactionModelsArr;

  isUploading: boolean = false;

  public filter_models: { [key: string]: boolean } = {
    Application: false,
    Time: false,
    EmploymentContract: false,
    SedCard: false,
    Profile: false,
  };

  protokollModelTranslations = protokollModelTranslations;

  public filter_dates = {
    from: "2023-01-01",
    to: dayjs().format("YYYY-MM-DD"),
  };

  // refreshData Subscription with debounceTime
  refreshDataSubj: Subject<boolean> = new Subject();

  refreshData$: Subscription = this.refreshDataSubj
    .pipe(debounceTime(500))
    .subscribe(async () => {
      await this.refreshData();
    });

  async refreshData() {
    this.data.spinner(true);
    this.isUploading = true;

    try {
      const body = {
        user_id: this.user.id,
        filter_dates: this.filter_dates,
        filter_models: Object.keys(this.filter_models).filter((key: string) => {
          return this.filter_models[key];
        }),
      };
      console.log(`body`, body);
      try {
        this.transaction_logs = await this.data.postRequest(
          `/users/transactionLogs/`,
          body
        );
      } catch (error) {
        Notify.failure("Fehler beim Laden der Daten");
      }
      console.log(`this.transaction_logs`, this.transaction_logs);
      this.show_transaction_logs = this.transaction_logs.map((log) => {
        const show_log: Partial<TransactionLogShowType> = {};
        show_log.durchgefuehrt_top =
          this.datePipe.transform(
            (log.created_at as Date) || new Date(),
            "dd.MM.yyyy HH:mm"
          ) || undefined;
        show_log.durchgefuehrt_bottom =
          log.actor_id == this.user.id || log.actor_id == null
            ? "User"
            : `${log.actor?.profile?.vorname} ${log.actor?.profile?.nachname}`;

        if (log.project) {
          show_log.bereich_top = "Projekt";
          const project_year = dayjs(log.project?.zeitraum_von).format("YYYY");
          show_log.bereich_bottom = `${log.project.titel} ${project_year}`;
        }

        if (log.model === "Application") {
          if (log.field === "role") {
            show_log.aenderung_top = log.value_from
              ? `Alte Rolle: ${this.splitToUpper(log.value_from)}`
              : "";
            show_log.aenderung_bottom = `Neue Rolle: ${this.splitToUpper(
              log.value_to as string
            )}`;
          }
          if (log.field === "is_admin") {
            show_log.aenderung_top = `Ist Admin: ${
              log.value_from ? "Ja" : "Nein"
            }`;
            show_log.aenderung_bottom = `Ist Admin: ${
              log.value_to ? "Ja" : "Nein"
            }`;
          }
          if (log.field === "status") {
            if (!log.value_from && log.value_to == "neu") {
              show_log.aenderung_bottom = `Hat sich beworben`;
            }
            if (!log.value_from && log.value_to == "kopiert") {
              show_log.aenderung_bottom = `Wurde manuell ins Projekt kopiert`;
            }
            if (log.value_to == "warten_auf_daten") {
              show_log.aenderung_bottom = `Daten wurden (zur Vervollständigung) angefordert`;
            }

            if (
              log.value_from == "warten_auf_daten" &&
              log.value_to == "daten_pruefen"
            ) {
              show_log.aenderung_bottom = `Daten für Bewerbung wurden ergänzt`;
            }
            if (log.value_to == "projekt_pool") {
              show_log.aenderung_bottom = `Daten wurden von PM bestätigt. User wurde in den Projektpool verschoben.`;
            }
            if (log.value_to == "auswahl_auftraggeber_invisible") {
              show_log.aenderung_bottom = `Topteamer ist in der Auswahl, aber noch nicht sichtbar für den Arbeitgeber.`;
            }
            if (log.value_to == "auswahl_auftraggeber_visible") {
              show_log.aenderung_bottom = `Topteamer ist in der Auswahl und sichtbar für den Arbeitgeber.`;
            }
            if (log.value_to == "auftraggeber_akzeptiert") {
              show_log.aenderung_bottom = `Topteamer wurde vom Arbeitgeber akzeptiert.`;
            }
            if (log.value_to == "auftraggeber_abgesagt") {
              show_log.aenderung_bottom = `Topteamer wurde vom Arbeitgeber abgelehnt.`;
            }
            if (log.value_from == "vertrag_geloescht") {
              show_log.aenderung_bottom = `Vertrag wurde neu erstellt und die Vertragsunterschrift wurde erneut angefordert.`;
            } else if (log.value_to == "warten_auf_vertrag") {
              show_log.aenderung_bottom = `Vertragsunterschrift wurde angefordert`;
            }
            if (log.value_to == "vertrag_geloescht") {
              show_log.aenderung_bottom = `Vertrag wurde gelöscht.`;
            }
            if (log.value_to == "vertrag_gegenzeichnen") {
              show_log.aenderung_bottom = `Topteamer hat unterzeichnet.`;
            }
            if (log.value_to == "erledigt_verbindlich") {
              show_log.aenderung_bottom = `PM hat gegengezeichnet.`;
            }
            if (log.value_to == "abgesagt") {
              show_log.aenderung_bottom = `Die Bewerbung für das Projekt wurde nicht angenommen.`;
            }
            if (log.value_to == "gekuendigt") {
              show_log.aenderung_bottom = `Dem Topteamer wurde gekündigt.`;
            }
          }
        }
        if (log.model === "User") {
          if (log.field == "registration_status") {
            show_log.bereich_top = "Registrierung";
            if (log.value_to == "neu") {
              show_log.aenderung_bottom = `User hat sich neu registriert`;
            }
            if (log.value_to == "importiert") {
              show_log.aenderung_bottom = `User wurde importiert`;
            }
            if (log.value_to == "warten_auf_daten") {
              show_log.aenderung_bottom = `Daten wurden im Zuge der Registrierung angefordert`;
            }
            if (log.value_to == "erledigt") {
              show_log.aenderung_bottom = `Die Userdaten wurden geprüft und von einem PM bestätigt.`;
            }
          } else {
            show_log.bereich_top = "User";
            if (log.field == "is_topteamer" && log.value_to == true) {
              show_log.aenderung_bottom = `User wurde als Topteamer angenommen.`;
            }
            if (log.field == "personalnummer") {
              show_log.aenderung_bottom = `Personalnummer wurde von ${log.value_from} auf ${log.value_to} geändert.`;
            }
            if (log.field == "email" && log.value_from != null) {
              show_log.aenderung_bottom = `E-Mail wurde von ${log.value_from} auf ${log.value_to} geändert.`;
            }
            if (log.field == "deleted") {
              show_log.aenderung_bottom = log.value_to
                ? `Nutzer wurde gelöscht.`
                : `Nutzer wurde wiederhergestellt.`;
            }
            if (log.field == "activated") {
              show_log.aenderung_bottom = log.value_to
                ? `Nutzer wurde aktivert.`
                : `Nutzer wurde deaktiviert.`;
            }
            if (log.field == "email_confirmed") {
              show_log.aenderung_bottom = `Nutzer hat seine E-Mail bestätigt.`;
            }
            if (log.field == "imported") {
              show_log.aenderung_bottom = `Nutzer wurde importiert.`;
            }
            if (log.field == "role") {
              show_log.aenderung_bottom = `Nutzer hat die Rolle ${
                // eslint-disable-next-line no-nested-ternary
                log.value_to == "regular"
                  ? "Topteamer"
                  : log.value_to == "pm"
                  ? "Projektleitung"
                  : "Admin"
              } bekommen.`;
            }
          }
        }
        if (log.model === "SedCard") {
          show_log.bereich_top = "Sedcard";
          if (log.field == "status") {
            if (log.value_to == "neu") {
              show_log.aenderung_bottom = `Sedcard wurde neu erstellt.`;
            }
          } else {
            // show_log.bereich_bottom = sedProtokollWordings[log.field].label;
            // show_log.aenderung_top = sedProtokollWordings[
            //   log.field
            // ].translationFn(log.value_from);
            // show_log.aenderung_bottom = sedProtokollWordings[
            //   log.field
            // ].translationFn(log.value_to);
            const value_top = protokollWordingsFn(log.field, log.value_from);
            const value_bottom = protokollWordingsFn(log.field, log.value_to);
            show_log.bereich_bottom = value_top.label;
            show_log.aenderung_top = value_top.translation;
            show_log.aenderung_bottom = value_bottom.translation;
            // show_log.bereich_bottom = protokollWordingsFn(
            //   log.field,
            //   undefined
            // ;
          }
        }
        if (log.model === "Profile") {
          show_log.bereich_top = "Daten";
          if (log.field == "status") {
            if (log.value_to == "neu") {
              show_log.aenderung_bottom = `Daten wurden neu angelegt.`;
            }
          } else {
            const value_top = protokollWordingsFn(log.field, log.value_from);
            const value_bottom = protokollWordingsFn(log.field, log.value_to);
            show_log.bereich_bottom = value_top.label;
            show_log.aenderung_top = value_top.translation;
            show_log.aenderung_bottom = value_bottom.translation;
          }
        }
        if (log.model === "Time") {
          const timeMillisToHHMM = (millis: number) => {
            const hours = Math.floor(millis / 3600000);
            const minutes = Math.floor((millis % 3600000) / 60000);
            return `${hours}:${`00${minutes}`.slice(-2)} h`;
          };

          const millisToTimeString = (millis: number) => {
            const dateOfMillis = new Date(millis);
            const hours = dateOfMillis.getHours();
            const minutes = dateOfMillis.getMinutes();
            return `${hours}:${`00${minutes}`.slice(-2)} Uhr`;
          };

          const date_string = log.time_day
            ? `- ${dayjs(new Date(log.time_day)).format("DD.MM.YYYY")}`
            : "";

          show_log.bereich_bottom += date_string;
          const isFake = log.field == "time_fake";
          const changedAfterCustomerReport =
            log.field === "time_original_after_customer_report" ||
            log.field === "time_fake_after_customer_report";
          if (log.field === "time_deleted") {
            show_log.aenderung_bottom = `Zeiterfassungeintrag wurde gelöscht.`;
          } else if (log.field === "time_checkin") {
            show_log.aenderung_bottom = `${millisToTimeString(
              log.value_to
            )} eingecheckt.`;
          } else if (log.field === "time_checkout") {
            show_log.aenderung_bottom = `${millisToTimeString(
              log.value_from
            )} ausgecheckt. ${timeMillisToHHMM(log.value_to)} erfasst.`;
          } else if (!log.value_from) {
            show_log.aenderung_bottom = `${timeMillisToHHMM(log.value_to)} ${
              isFake ? "Kunden-" : ""
            }Zeit erfasst.${
              changedAfterCustomerReport ? " (nach Kundenbericht)" : ""
            }`;
          } else {
            show_log.aenderung_top = `${
              isFake ? "Kunden-" : ""
            }Zeit von ${timeMillisToHHMM(log.value_from)}`;
            show_log.aenderung_bottom = `auf ${timeMillisToHHMM(
              log.value_to
            )} geändert.${
              changedAfterCustomerReport ? " (nach Kundenbericht)" : ""
            }`;
          }
        }
        return show_log as TransactionLogShowType;
      });
    } catch (error) {
      Notify.failure("Fehler beim Laden der Protokolle");
      console.error(error);
    }

    console.log(`this.show_transaction_logs`, this.show_transaction_logs);
    this.isUploading = false;
    this.data.spinner(false);
  }

  exportWithXLSX() {
    console.log(this.show_transaction_logs);
    const header = [
      "kategorie",
      "feld_oder_projekt",
      "wert_alt",
      "wert_neu",
      "durchgefuehrt_von",
      "durchgefuehrt_am",
    ];
    // const widths = [20, 50, 50, 50, 50, 20];
    const data_to_export = this.show_transaction_logs.map((log) => ({
      kategorie: log.bereich_top,
      feld_oder_projekt: log.bereich_bottom,
      wert_alt: log.aenderung_top,
      wert_neu: log.aenderung_bottom,
      durchgefuehrt_von: log.durchgefuehrt_bottom,
      durchgefuehrt_am: log.durchgefuehrt_top,
    }));

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data_to_export, {
      header,
    });

    const widths = header.map((h) => h.length);

    const heights = [12];
    for (const data of data_to_export) {
      let height = 0;
      // eslint-disable-next-line guard-for-in
      const vals = Object.values(data);
      for (let i = 0; i < vals.length; i++) {
        if (typeof vals[i] == "string") {
          const splits = vals[i].split("\n");
          // width
          const h_count = splits.reduce((acc, val) => {
            if (val.length > acc) {
              return val.length;
            }
            return acc;
          }, 0);
          if (h_count > widths[i]) {
            widths[i] = h_count;
          }

          // height
          const n_count = splits.length;
          if (n_count * 12 > height) {
            height = n_count * 12;
          }
        }
      }
      heights.push(height);
    }
    ws["!cols"] = widths.map((str) => ({ width: str }));
    ws["!rows"] = heights.map((h) => ({ hpt: h }));

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const user_name = `${this.user?.profile?.vorname}_${this.user?.profile?.nachname}`;
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      `${user_name}`
        .slice(0, 30 /* max 31 chars */)
        .replace(/ /g, "_")
        // replace all not allowed chars / ? * [ ]
        .replace(/[/?*[\]]/g, "-")
    );

    const fileName = `export_${dayjs().format(
      "YYYY-MM-DD_HH-mm"
    )}_export_logs_${user_name}`.replace(/ /g, "_");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  transaction_logs: TransactionLogType[] = [];

  show_transaction_logs: TransactionLogShowType[] = [];

  constructor(private data: DataService, private datePipe: DatePipe) {}

  splitToUpper(str: string) {
    return str
      .split("_")
      .map((word: string) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
  }

  async ngOnInit() {
    console.log(this.user);
    console.log(`filter_dates`, this.filter_dates);
    await this.refreshData();
  }

  ngOnDestroy() {
    if (this.refreshData$) this.refreshData$.unsubscribe();
  }
}
