<div class="unterlagen-page">
  <h4>Vertrag</h4>
  <div class="contract-wrapper">
    <app-svgs type="page" color="primary"></app-svgs>
    <div
      *ngIf="employmentContract && employmentContract.time_employee_signature"
      class="two-lines"
    >
      <p class="top-line">Unterzeichnet</p>
      <p class="bottom-line">
        {{
          employmentContract.time_employee_signature
            | date : "dd.MM.YYYY, HH:mm"
        }}
        Uhr
      </p>
    </div>
    <div
      *ngIf="
        !employmentContract ||
        (employmentContract && !employmentContract.time_employee_signature)
      "
      class="two-lines"
    >
      <p class="top-line">Warten auf Unterschrift von Mitarbeiter</p>
      <p class="bottom-line"></p>
    </div>
    <div
      class="two-lines"
      *ngIf="employmentContract && employmentContract.time_employee_signature"
    >
      <p *ngIf="!employmentContract.time_employer_signature" class="top-line">
        Gegenzeichnen
      </p>
      <p
        *ngIf="!employmentContract.time_employer_signature && canSignContract"
        (click)="signContractWithSignature()"
        class="bottom-line"
      >
        <strong class="sign-contract">Vertrag unterzeichnen</strong>
      </p>
      <p
        *ngIf="!employmentContract.time_employer_signature && !canSignContract"
        class="bottom-line"
      >
        <strong>Warte auf Admin</strong>
      </p>
      <p *ngIf="employmentContract.time_employer_signature" class="top-line">
        Gegengezeichnet
      </p>
      <p *ngIf="employmentContract.time_employer_signature" class="bottom-line">
        {{
          employmentContract?.time_employer_signature
            | date : "dd.MM.YYYY, HH:mm"
        }}
        Uhr
      </p>
    </div>
    <div
      *ngIf="
        employmentContract?.time_employer_signature &&
        !employmentContract?.dismissal_date &&
        canSignContract
      "
      (click)="kuendigen.emit()"
    >
      <p class="alert cursor-pointer bold">Kündigen</p>
    </div>
    <div *ngIf="this.employmentContract && this.employmentContract.id">
      <p class="secondary clickable" (click)="getContractPDFClicked()">
        <strong>PDF</strong>
      </p>
    </div>
  </div>

  <div
    class="contract-wrapper margin-top-10"
    *ngIf="employmentContract?.dismissal_date"
  >
    <app-svgs type="page" color="primary"></app-svgs>
    <div class="two-lines">
      <p class="top-line">Gekündigt</p>
      <p class="bottom-line">
        {{ employmentContract!.dismissal_date! | date : "dd.MM.YYYY, HH:mm" }}
        Uhr
      </p>
    </div>
    <div *ngIf="this.employmentContract && this.employmentContract.id">
      <p class="secondary clickable" (click)="getDismissalPDFClicked()">
        <strong>PDF</strong>
      </p>
    </div>
  </div>
</div>

<app-modal
  *ngIf="showSignatureModal"
  class="sign-contract-modal"
  (close)="showSignatureModal = false"
>
  <h5>Vertrag Gegenzeichnen</h5>
  <div class="signature-row">
    <div class="text-with-icon icon-left">
      <app-svgs type="page-edit" color="primary40"></app-svgs>
      <p>Unterschrift</p>
    </div>
    <div class="signature-wrapper">
      <app-svgs
        type="refresh"
        color="secondary"
        (click)="signatureClear()"
      ></app-svgs>
      <signature-pad
        [options]="signaturePadOpts"
        id="signatureCanvas"
        (onBeginEvent)="signatureDrawStart()"
        (onEndEvent)="signatureDrawFinish()"
      ></signature-pad>
      <div class="line"></div>
    </div>
  </div>
  <app-button
    (click)="signContractWithSignature()"
    color="secondary"
    background="secondary"
    >Unterschrift setzen</app-button
  >
</app-modal>
