import { FormControl, AbstractControl } from "@angular/forms";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Toggle } from "@ttc_types/types";
import { Subscription } from "rxjs";

@Component({
  selector: "app-toggle",
  // templateUrl: './toggle.component.html',
  styleUrls: ["./toggle.component.scss"],
  templateUrl: "./toggle.component.html",
})
export class ToggleComponent {
  /**
   * What style should the component have?
   */
  @Input()
  mode: "select" | "menu" = "select";

  /**
   * What style should the component have?
   */
  @Input()
  color:
    | "gold-grey"
    | "white-gold"
    | "darkblue-blue"
    | "darkblue-white"
    | "darkblue-primary10" = "darkblue-white";

  /**
   * Toggles
   *
   */
  @Input()
  toggles: Toggle[] = [];

  /**
   * Vertical. Ex. as Menu
   */
  @Input()
  vertical: boolean = false;

  /**
   *  Click event
   */
  @Input()
  indexSelected: number = -1;

  @Output()
  indexSelectedChange = new EventEmitter<number>();

  @Input()
  formContrl: AbstractControl | FormControl | undefined = undefined;

  classes: string[] = [];

  show: boolean = true;

  emitIndex(i: number, toggle: Toggle) {
    this.indexSelectedChange.emit(i);
    this.indexSelected = i;
    if (this.formContrl) {
      this.formContrl.setValue(
        toggle.value || toggle.value === 0 ? toggle.value : toggle.label
      );
    }
  }

  constructor() {}

  formControlSubscription: Subscription | undefined = undefined;

  ngOnInit(): void {
    const classes = [];
    classes.push(this.color);
    classes.push(this.mode);
    if (this.vertical) classes.push("vertical");
    this.classes = classes;
    if (this.formContrl) {
      const index = this.toggles.findIndex(
        (el) =>
          (el.value || el.value === 0 ? el.value : el.label) ===
          this.formContrl?.value
      );
      this.indexSelected = index;

      this.formControlSubscription = this.formContrl?.valueChanges.subscribe(
        (value) => {
          console.log(`formControlSubscription value`, value);
          this.indexSelected = this.toggles.findIndex(
            (el) => (el.value || el.value === 0 ? el.value : el.label) === value
          );
          this.indexSelected = this.indexSelected > -1 ? this.indexSelected : 0;
          this.indexSelectedChange.emit(this.indexSelected);
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.formControlSubscription) {
      this.formControlSubscription.unsubscribe();
    }
  }
}
