import { Subscription } from "rxjs";
import {
  FormControl,
  FormGroup,
  AbstractControl,
  Validators,
} from "@angular/forms";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-dob-picker",
  // templateUrl: './dob-picker.component.html',
  styleUrls: ["./dob-picker.component.scss"],
  template: `
    <div class="dob-picker" *ngIf="dmyForm.disabled">
      {{ dmyForm.value.day }}.{{ dmyForm.value.month }}.{{ dmyForm.value.year }}
    </div>
    <div class="dob-picker" [formGroup]="dmyForm" *ngIf="!dmyForm.disabled">
      <select
        name="day"
        id="day"
        formControlName="day"
        autocomplete="bday-day"
        placeholder="Tag"
      >
        <!-- [disabled]="formCntrl && formCntrl.disabled" -->
        <!-- <option value></option> -->
        <option value=""></option>
        <option *ngFor="let d of days" [value]="d">
          {{ d }}<span class="dob-point" *ngIf="dmyForm.disabled">.</span>
        </option>
      </select>
      <select
        name="month"
        id="month"
        formControlName="month"
        autocomplete="bday-month"
        placeholder="Monat"
      >
        <!-- [disabled]="formCntrl && formCntrl.disabled" -->
        <!-- <option value></option> -->
        <option value=""></option>
        <option *ngFor="let m of months" [value]="m">
          {{ m }}<span class="dob-point" *ngIf="dmyForm.disabled">.</span>
        </option>
      </select>
      <select
        name="year"
        id="year"
        formControlName="year"
        autocomplete="bday-year"
        placeholder="Jahr"
      >
        <!-- [disabled]="formCntrl && formCntrl.disabled" -->
        <!-- <option value></option> -->
        <option value=""></option>
        <option *ngFor="let y of years" [value]="y">
          {{ y }}
        </option>
      </select>
    </div>
  `,
})
export class DobPickerComponent implements OnInit {
  getDaysInMonth(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
  }

  get days() {
    let maxDays = 31;
    if (this.dmyForm.value.year && this.dmyForm.value.month) {
      maxDays = this.getDaysInMonth(
        this.dmyForm.value.year,
        this.dmyForm.value.month
      );
    }
    return [...Array(maxDays).keys()].map((i) => i + 1);
  }

  months: number[] = [...Array(12).keys()].map((i) => i + 1);

  currentYear = new Date().getFullYear();

  years: number[] = [...Array(120).keys()].map((i) => this.currentYear - i);

  dmyForm = new FormGroup({
    day: new FormControl("", [Validators.required]),
    month: new FormControl("", [Validators.required]),
    year: new FormControl("", [Validators.required]),
  });

  // @Input()
  // date: string = "";

  @Input()
  formCntrl!: FormControl | AbstractControl;

  // @Output()
  // dateChange = new EventEmitter<string>();

  // months: number[] Object.keys(new Array(12)).map(i => i+1)
  constructor() {}

  dmyFormSubscription: Subscription | undefined;

  formCntrlSubscription: Subscription | undefined;

  ngOnInit(): void {
    this.dmyForm.disable();
    console.log(`this.formCntrl.value`, this.formCntrl);
    setTimeout(() => {
      this.patchValue();
    }, 1000);

    if (!this.dmyFormSubscription) {
      this.dmyFormSubscription = this.dmyForm.valueChanges.subscribe(() => {
        if (this.dmyForm.invalid || this.dmyForm.disabled) {
          return;
        }
        if (
          this.dmyForm.value.day &&
          this.dmyForm.value.month &&
          this.dmyForm.value.year
        ) {
          if (
            this.dmyForm.value.day >
            this.getDaysInMonth(
              this.dmyForm.value.year,
              this.dmyForm.value.month
            )
          ) {
            this.dmyForm.controls.day.patchValue("", { emitEvent: false });
            this.formCntrl.setValue("", { emitEvent: false });
            return;
          }
        }
        const day = `0${this.dmyForm.value.day}`.slice(-2);
        const month = `0${this.dmyForm.value.month}`.slice(-2);
        const date = `${day}.${month}.${this.dmyForm.value.year}`;
        // this.dateChange.emit(date);
        if (this.formCntrl) {
          this.formCntrl.setValue(date);
        }
      });
    }

    // if (this.formCntrl && !this.formCntrlSubscription) {
    //   this.formCntrlSubscription = this.formCntrl.valueChanges.subscribe(() => {
    //     this.patchValue();
    //   });
    // }
  }

  patchValue() {
    console.log(`patchValue`, this.formCntrl.value);
    if (
      this.formCntrl &&
      this.formCntrl.value &&
      typeof this.formCntrl.value === "string"
    ) {
      const dateSplitted = this.formCntrl.value
        .split(".")
        .map((el) => Number.parseInt(el, 10));
      if (
        dateSplitted[0] != this.dmyForm.value.day ||
        dateSplitted[1] != this.dmyForm.value.month ||
        dateSplitted[2] != this.dmyForm.value.year
      ) {
        this.dmyForm.patchValue(
          {
            day: dateSplitted[0],
            month: dateSplitted[1],
            year: dateSplitted[2],
          },
          { emitEvent: false }
        );
      }
    }
    if (!this.formCntrl || this.formCntrl.disabled) {
      this.dmyForm.disable();
    } else {
      this.dmyForm.enable();
    }
    console.log(`this.dmyForm`, this.dmyForm);
  }

  ngOnDestroy() {
    if (this.dmyFormSubscription) this.dmyFormSubscription.unsubscribe();
  }
}
