import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { BeschaeftigungLaufendesJahr } from "@ttc_types/types";
import {
  atLeastOneValidator,
  isValidBIC,
  isValidIBAN,
  sameYearValidator,
  isValidTIN,
  isValidSSN,
  // isValidPassportNumber,
} from "src/app/validators/validators";

import { formatDate } from "@angular/common";

export const laufendes_jahr: { [key: string]: BeschaeftigungLaufendesJahr } = {
  KurzfristigeBeschaeftigung: {
    label: "Kurzfristige Beschäftigung",
    formControlName: "KurzfristigeBeschaeftigung",
    arbeitgeber: "",
    zeitraum_von: new Date(),
    zeitraum_bis: new Date(),
    tage_beschaeftigt: 1,
    checked: false,
  },
  Minijob: {
    label: "Minijob",
    formControlName: "Minijob",
    arbeitgeber: "",
    zeitraum_von: new Date(),
    zeitraum_bis: new Date(),
    tage_beschaeftigt: 1,
    checked: false,
  },
  Werkstudent: {
    label: "Werkstudent",
    formControlName: "Werkstudent",
    arbeitgeber: "",
    zeitraum_von: new Date(),
    zeitraum_bis: new Date(),
    tage_beschaeftigt: 1,
    checked: false,
  },
  Praktikum: {
    label: "Praktikum",
    formControlName: "Praktikum",
    arbeitgeber: "",
    praktikum_art: "Pflicht",
    zeitraum_von: new Date(),
    zeitraum_bis: new Date(),
    tage_beschaeftigt: 1,
    checked: false,
  },

  Keine: {
    label: "Keine",
    formControlName: "Keine",
    checked: false,
  },
};

export const ab_nv: {
  [key: string]: {
    formControlName: string;
    label: string;
    validWith: string[];
    needsCert: boolean;
    needsValidUntil?: boolean;
  };
} = {
  Studentin: {
    formControlName: "Studentin",
    label: "Studierende",
    validWith: ["Arbeitnehmerin", "Minijob", "Selbststaendig"],
    needsCert: true,
    needsValidUntil: true,
  },
  Schuelerin: {
    formControlName: "Schuelerin",
    label: "Schüler:Innen",
    validWith: ["Minijob", "Selbststaendig"],
    needsCert: true,
  },
  Azubi: {
    formControlName: "Azubi",
    label: "Azubi",
    validWith: ["Minijob", "Selbststaendig"],
    needsCert: true,
  },
  FSJ: {
    formControlName: "FSJ",
    label: "FSJ",
    validWith: ["Minijob", "Selbststaendig"],
    needsCert: true,
  },
  // Arbeitslos: {
  //   formControlName: "Arbeitslos",
  //   label: "Arbeitslos",
  //   validWith: ["Minijob"],
  //   needsCert: false,
  // },
  Arbeitnehmerin: {
    formControlName: "Arbeitnehmerin",
    label: "Arbeitnehmer:In",
    validWith: ["Studentin", "Minijob", "Hausfrau"],
    needsCert: true,
  },
  Minijob: {
    formControlName: "Minijob",
    label: "Minijob",
    validWith: [
      "Studentin",
      "Schuelerin",
      "Azubi",
      "FSJ",
      // "Arbeitslos",
      "Arbeitnehmerin",
      "Hausfrau",
      "Selbststaendig",
    ],
    needsCert: true,
  },
  Selbststaendig: {
    label: "Selbstständig",
    formControlName: "Selbststaendig",
    validWith: [
      "Studentin",
      "Schuelerin",
      "Azubi",
      "FSJ",
      "Hausfrau",
      "Minijob",
    ],
    // arbeitgeber: "",
    needsCert: true,
  },
  Hausfrau: {
    formControlName: "Hausfrau",
    label: "Hausfrau/Mann",
    validWith: ["Minijob", "Arbeitnehmerin", "Selbststaendig"],
    needsCert: false,
  },
};

export const getPersForm = (
  grosse_bewerbung: boolean = false,
  laufendesJahrCheckBoxes: FormGroup | undefined = undefined,
  disable_email_and_password: boolean = false,
  disable_reviewed_fields_for_topteamer = false,
  admin_edit: boolean = false
): FormGroup => {
  const validatorRequired: ValidatorFn[] = [];
  if (!admin_edit) {
    validatorRequired.push(Validators.required);
  }
  const persForm = new FormGroup({
    // zur person
    geschlecht: new FormControl("", validatorRequired),
    vorname: new FormControl(
      { value: "", disabled: disable_reviewed_fields_for_topteamer },
      validatorRequired
    ),
    weitere_vornamen: new FormControl(
      {
        value: "",
        disabled: !grosse_bewerbung || disable_reviewed_fields_for_topteamer,
      },
      []
    ),
    nachname: new FormControl(
      { value: "", disabled: disable_reviewed_fields_for_topteamer },
      validatorRequired
    ),
    email: new FormControl(
      { value: "", disabled: grosse_bewerbung || disable_email_and_password },
      validatorRequired.concat(Validators.email)
    ),
    tel: new FormControl("", validatorRequired.concat(Validators.minLength(4))),
    password: new FormControl(
      { value: "", disabled: grosse_bewerbung || disable_email_and_password },
      validatorRequired
    ),
    // bestehend
    geburtsname: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      []
    ),
    geburtsdatum: new FormControl({ value: "" }, validatorRequired),
    geburtsland: new FormControl(
      {
        value: null,
        disabled: !grosse_bewerbung || disable_reviewed_fields_for_topteamer,
      },
      validatorRequired
    ),
    geburtsstadt: new FormControl(
      {
        value: "",
        disabled: !grosse_bewerbung || disable_reviewed_fields_for_topteamer,
      },
      validatorRequired
    ),
    // staatsangehoerigkeit: new FormControl(
    //   { value: null, disabled: !grosse_bewerbung },
    //   validatorRequired
    // ),
    staatsangehoerigkeit_arr: new FormControl(
      { value: [], disabled: !grosse_bewerbung },
      validatorRequired
    ),
    ausweisnummer: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired.concat(Validators.minLength(4))
    ),
    ausweis_vorne: new FormControl(
      { value: [], disabled: !grosse_bewerbung },
      validatorRequired
    ),
    ausweis_hinten: new FormControl(
      { value: [], disabled: !grosse_bewerbung },
      validatorRequired
    ),

    // kontakt
    // bestehend
    strasse: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    hausnr: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    plz: new FormControl(
      { value: null, disabled: false },
      validatorRequired.concat(Validators.minLength(3))
    ),
    ort: new FormControl({ value: "", disabled: false }, validatorRequired),
    wohnland: new FormControl({ value: "DE", disabled: !grosse_bewerbung }, []),
    addresszusatz: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      []
    ),
    // email von oben
    // tel von oben

    // abschluesse
    // bestehend
    hoechster_allgemeiner_schulabschluss: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    hoechster_beruflicher_abschluss: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),

    // status bei arbeitsbeginn
    arbeitsbeginn_status: new FormGroup(
      {},
      { validators: [atLeastOneValidator()] }
    ),
    arbeitsbeginn_status_additional: new FormGroup({}),

    existiert_hauptarbeitgeber: new FormControl("", validatorRequired),

    aufenthaltstitel: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    aufenthaltstitel_nachweis: new FormControl(
      { value: [], disabled: true },
      validatorRequired
    ),

    gewerbeschein: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    gewerbeschein_nachweis: new FormControl(
      { value: [], disabled: true },
      validatorRequired
    ),

    gesundheitszeugnis: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    gesundheitszeugnis_nachweis: new FormControl(
      { value: [], disabled: true },
      validatorRequired
    ),

    // laufendes jahr jobs
    // bestehend
    // laufendes_jahr: new FormControl({value: [], disabled: !grosse_bewerbung}, validatorRequired),

    laufendes_jahr: new FormGroup({}),

    // Rechtliches
    // bestehend
    steueridentifikationsnummer: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired.concat(Validators.minLength(5), isValidTIN())
    ),
    steuerklasse: new FormControl(
      { value: 1, disabled: !grosse_bewerbung },
      validatorRequired
    ),
    sozialversicherungsnummer: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired.concat(Validators.minLength(5), isValidSSN())
    ),
    krankenkasse_typ: new FormControl(
      { value: "gesetzlich", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    krankenkasse_name: new FormControl(
      { value: null, disabled: !grosse_bewerbung },
      validatorRequired
    ),
    familienstand: new FormControl(
      { value: "ledig", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    religionszugehoerigkeit: new FormControl(
      { value: null, disabled: !grosse_bewerbung },
      validatorRequired
    ),
    kinderfreibetrag: new FormControl(
      { value: false, disabled: !grosse_bewerbung },
      validatorRequired
    ),

    // bankverbindung
    // bestehend
    iban: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired.concat(isValidIBAN())
    ),
    bic: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired.concat(isValidBIC())
    ),
  });

  if (!grosse_bewerbung) {
    persForm.controls.laufendes_jahr.disable();
  }

  // fill in values

  const arbeitsBeginnStatus = persForm.controls
    .arbeitsbeginn_status as FormGroup;

  const arbeitsBeginnAdditional = persForm.controls
    .arbeitsbeginn_status_additional as FormGroup;

  const laufendesJahrFG = persForm.controls.laufendes_jahr as FormGroup;

  // fill in arbeits beginn FormControls
  Object.values(ab_nv).forEach((el) => {
    arbeitsBeginnStatus.addControl(
      el.formControlName,
      new FormControl("", []),
      { emitEvent: false }
    );

    if (el.needsCert) {
      arbeitsBeginnAdditional.addControl(
        `${el.formControlName}_nachweis`,
        new FormControl({ value: [], disabled: true }, validatorRequired),
        { emitEvent: false }
      );

      arbeitsBeginnAdditional.addControl(
        `${el.formControlName}_fach`,
        new FormControl({ value: "", disabled: true }, validatorRequired),
        { emitEvent: false }
      );
    }
    if (el.needsValidUntil) {
      arbeitsBeginnAdditional.addControl(
        `${el.formControlName}_gueltig_bis`,
        new FormControl({ value: [], disabled: true }, validatorRequired),
        { emitEvent: false }
      );
    }
  });

  // fill in laufendes jahr FormControls
  Object.values(laufendes_jahr).forEach((el) => {
    laufendesJahrFG.addControl(
      el.formControlName,
      new FormControl({ value: [], disabled: true }, []),
      { emitEvent: false }
    );
    if (laufendesJahrCheckBoxes) {
      laufendesJahrCheckBoxes.addControl(
        el.formControlName,
        new FormControl(false, []),
        { emitEvent: false }
      );
    }
  });

  return persForm;
};

export const getSedForm = (
  grosse_bewerbung: boolean = false,
  admin_edit: boolean = false
): FormGroup => {
  const validatorRequired: ValidatorFn[] = [];
  if (!admin_edit) {
    validatorRequired.push(Validators.required);
  }
  const fg = new FormGroup({
    // äusseres
    haarfarbe: new FormControl("", validatorRequired),
    koerpergroesse: new FormControl("", validatorRequired),
    gewicht: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    // shirtgröße hier if !topTeamer
    tattoo_oder_piercings: new FormControl("", validatorRequired),

    // Konfektionsgrößen
    // einmal oben einmal unten
    shirtgroesse: new FormControl("", validatorRequired),

    jacke_sacko: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    hose_rock: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    jeansweite: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    jeanslaenge: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    schuhgroesse: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    guertellaenge: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    kragenweite: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),

    // Fotos
    fotos: new FormControl([], validatorRequired),

    // Mobilität
    fuehrerschein: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      validatorRequired
    ),
    vorhandene_mobilitaet: new FormGroup({
      PKW: new FormControl({ value: false, disabled: !grosse_bewerbung }, []),
      Bahncard: new FormControl(
        { value: false, disabled: !grosse_bewerbung },
        []
      ),
    }),

    // Sprachen
    sprachen: new FormGroup({
      deutsch: new FormControl(
        { value: "Nein", disabled: !grosse_bewerbung },
        []
      ),
      englisch: new FormControl(
        { value: "Nein", disabled: !grosse_bewerbung },
        []
      ),
      spanisch: new FormControl(
        { value: "Nein", disabled: !grosse_bewerbung },
        []
      ),
      franzoesisch: new FormControl(
        { value: "Nein", disabled: !grosse_bewerbung },
        []
      ),
      italienisch: new FormControl(
        { value: "Nein", disabled: !grosse_bewerbung },
        []
      ),
      weitere: new FormControl({ value: [], disabled: !grosse_bewerbung }, []),
      weitereAsGroup: new FormGroup({}),
    }),

    // Erfahrungen
    berufserfahrung: new FormControl([], []),

    weitere_erfahrungen: new FormControl(
      { value: "", disabled: !grosse_bewerbung },
      []
    ),
  });

  return fg;
};

export const getAgbForm = (grosse_bewerbung: boolean = false): FormGroup => {
  const fg = new FormGroup({
    agb: new FormControl({ value: false, disabled: grosse_bewerbung }, [
      Validators.requiredTrue,
    ]),
    newsletter: new FormControl(
      { value: false, disabled: grosse_bewerbung },
      []
    ),
    angaben_korrekt: new FormControl(
      { value: false, disabled: !grosse_bewerbung },
      [Validators.requiredTrue]
    ),
  });
  return fg;
};

export const getCurrentBeschaeftigungsModalData = (
  admin_edit: boolean = false
) => {
  const validatorRequired: ValidatorFn[] = [];
  if (!admin_edit) {
    validatorRequired.push(Validators.required);
  }
  return new FormGroup(
    {
      formControlName: new FormControl("", []),
      label: new FormControl("", []),
      arbeitgeber: new FormControl("", validatorRequired),
      praktikum_art: new FormControl("Pflicht", validatorRequired),
      zeitraum_von: new FormControl(
        formatDate(new Date(), "yyyy-MM-dd", "en"),
        validatorRequired
      ),
      zeitraum_bis: new FormControl(
        formatDate(new Date(), "yyyy-MM-dd", "en"),
        validatorRequired
      ),
      tage_beschaeftigt: new FormControl(
        1,
        validatorRequired.concat(Validators.min(1))
      ),
    },
    sameYearValidator()
  );
};
