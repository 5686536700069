import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";

@Component({
  selector: "app-searchbar",
  styleUrls: ["./searchbar.component.scss"],
  host: { class: "width-100" },
  template: `
    <div class="searchbar" [ngClass]="color">
      <div class="search-wrapper">
        <div class="input-wrapper">
          <input
            type="text"
            [(ngModel)]="searchString"
            (keyup)="emitInputString()"
            placeholder="Suche"
          />
          <!-- <img src="assets/svgs/menu_search.svg" alt="searchicon"> -->
          <app-svgs
            type="search"
            [color]="color"
            *ngIf="!searchString"
          ></app-svgs>
          <app-svgs
            type="close"
            [color]="color"
            *ngIf="searchString"
            (click)="searchString = ''; emitInputString()"
          ></app-svgs>
        </div>
        <div class="filter" *ngIf="filterButtonRight">
          <img src="assets/svgs/filter.svg" alt="Filter" />
        </div>
      </div>
    </div>
  `,
})
export class SearchbarComponent implements OnInit {
  searchString: string = "";
  /* TODO:
  - filters?
  */

  constructor() {}

  ngOnInit(): void {}

  emitInputString() {
    if (this.formContrl) {
      this.formContrl.setValue(this.searchString);
    }
    this.searchStringChange.emit(this.searchString);
  }

  @Input()
  filterButtonRight: boolean = false;

  @Input()
  color: "primary" | "primary20" = "primary";

  /**
   * searchString on Change Emitter
   *  */
  @Output()
  searchStringChange = new EventEmitter<string>();

  @Input()
  formContrl: AbstractControl | FormControl | undefined = undefined;
}
