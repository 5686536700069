<div class="job-detail">
  <div class="main">
    <!-- <div class="main-head">
    </div> -->
    <div class="main-head logo-with-back-button">
      <div class="left">
        <div class="top with-back-btn">
          <app-svgs
            type="chevron-left"
            color="white"
            (click)="goBackClicked()"
          ></app-svgs>
          <div class="flex-center">
            <img src="assets/svgs/mobile-top-logo.svg" alt="" />
          </div>
        </div>
        <div class="bottom">
          <h1 class="white">{{ application?.project?.titel }}</h1>
        </div>
      </div>
    </div>
    <div *ngIf="!showContract" class="content">
      <div
        class="toggle-wrapper"
        *ngIf="application?.status != 'daten_pruefen'"
      >
        <app-toggle
          [toggles]="toggles"
          [indexSelected]="activeToggle"
          color="darkblue-blue"
        ></app-toggle>
      </div>
      <div
        class="white-spacer"
        *ngIf="application?.status != 'daten_pruefen'"
      ></div>
      <app-mehr-infos
        [ngStyle]="{
          top: application?.status == 'daten_pruefen' ? '0px' : '-40px'
        }"
        class="mehr-infos-modal"
        *ngIf="application?.project && mehrInfos"
        [status]="application?.status"
        [project]="application!.project!"
        [closeBtn]="false"
        [whiteBg]="true"
        [showTitleAndAnforderung]="false"
        [showBewerbenButton]="false"
        [paddingTop]="
          ['daten_pruefen', 'abgesagt'].includes(application?.status || '')
            ? '68px'
            : '36px'
        "
      ></app-mehr-infos>
      <app-timetracker
        *ngIf="
          application && application.id && application.project && timetracker
        "
        [inReview]="application.status != 'daten_pruefen'"
        [application]="application"
        [projectId]="application.project_id"
      ></app-timetracker>
      <div *ngIf="unterlagen && employmentContract" class="white-bg unterlagen">
        <app-job-unterlagen
          *ngIf="application && unterlagen && employmentContract"
          [contractIsSigned]="contractIsSigned"
          [employmentContract]="employmentContract"
          (openContractPage)="showContract = !showContract"
          [application]="application"
        ></app-job-unterlagen>
        <div class="contact-list-container" *ngIf="application">
          <h4 class="margin-bottom-10">Kontakte</h4>
          <app-kontakt-liste [application]="application!"> </app-kontakt-liste>
        </div>
      </div>
      <!-- (close)="currentProjectDetail = undefined" -->
    </div>
    <div *ngIf="showContract" class="contract-content" id="contract-content">
      <div
        class="contract-content-container"
        [innerHtml]="employmentContract.final_contract_text | safeHtml"
      ></div>
      <div
        *ngIf="!employmentContract.time_employee_signature"
        class="signature-row"
      >
        <p>Bitte hier unterschreiben</p>
        <div class="signature-wrapper">
          <app-svgs
            type="refresh"
            color="secondary"
            (click)="signatureClear()"
          ></app-svgs>
          <signature-pad
            [options]="signaturePadOpts"
            id="signatureCanvas"
            (onBeginEvent)="signatureDrawStart()"
            (onEndEvent)="signatureDrawFinish()"
          ></signature-pad>
          <div class="line"></div>
        </div>
        <p *ngIf="profile" class="signature-name">
          {{ profile.vorname }} {{ profile.nachname }}
        </p>
        <app-button
          color="secondary-gradient"
          background="secondary"
          (click)="signContractClicked()"
          >Vertrag abschicken</app-button
        >
      </div>
    </div>
  </div>
  <div class="detail">
    <div class="logo-container">
      <img src="assets/svgs/logo_big_claim.svg" alt="Logo Claim" class="logo" />
    </div>
  </div>
</div>
